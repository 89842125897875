import React, { useEffect } from 'react';
import { getBezierPath} from 'reactflow';
import { useLocation, Link } from 'react-router-dom';
import '../../../Styles/Journeys/PebbleEdges.css';
import { getEquallyDistributedLocationsAlongPath } from './BezierCurveApproximations';

export default function PebbleEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data
}) {
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const location = useLocation();

  const foreignObjects = []

  if (data){
    if (data.posts.length > 0){
      const loci = getEquallyDistributedLocationsAlongPath(edgePath, data.posts.length);
      for (let i = 0; i < data.posts.length; i++)
      {
        const postLink = location.pathname + location.search.split("&")[0] + "&postID=" + data.posts[i];
        foreignObjects.push(
          <foreignObject 
          key = {i}
          //button width is 40 so the 20 is subtraced to center the buttons.
            x={loci[i][0] - 20}
            y={loci[i][1] - 20}
            className="edgebutton-foreignobject"
            style={{pointerEvents: data.clickable ? "all" : "none"}}
          >
            <Link to={postLink}>
              <button className="edgebutton">
                {i + 1}
              </button >
            </Link>
          </foreignObject>
        )
      }
    }
  }

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path thin-path"
        d={edgePath[0]}
      />
      {/* A transparent path to extend the path width*/}
      <path
        className="react-flow__edge-path thick-path"
        style={{stroke: "initial"}}
        d={edgePath[0]}
      />
      {foreignObjects}
    </>
  );
}