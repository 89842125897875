import React, { useRef, useEffect} from 'react';
import "../../Styles/Login.css"
import  { fetchAuthSession, signIn } from'aws-amplify/auth';
import { connect } from 'react-redux'
import * as actions from '../../actions';
import { Device } from '@capacitor/device';
import Axios from 'axios';
import LogInToggle from './LogInToggle';
import { Link } from 'react-router-dom';


function Login(props)
{
  const isMounted = useRef();

  useEffect(() => {
        isMounted.current = true;
        return () => {
        isMounted.current = false;
        };
    }, []);

    const LogInSubmit = async (source) =>
    {
        props.isLoading(true);
        var username = document.getElementById('Username').value;
        var password = document.getElementById('Password').value;

        try {
            await signIn({username, password});
            if(isMounted.current){
              ResetLogInWindow();
            }
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
            const body = await Device.getId();
            let config = {headers: {"Authorization": idToken.toString()}};
            try{
                await Axios.post(process.env.REACT_APP_APIURL + '/AddDeviceToUser', body, config);
            }
            catch(e){
                console.log(e);
            }
            await props.fetchUser();
            //window.location.href = "/";
        } catch (error) {
            ;
            if (error.code === 'UserNotConfirmedException')
            {
              document.getElementById('LoginError').value = 'Account Email Not Confirmed.';
            }
            else
            {
              document.getElementById('LoginError').value = 'Incorrect Email or Password';
            }
             document.getElementById('LoginError').removeAttribute('hidden');
        }
        finally{
          props.isLoading(false);
        }
    }

    const ResetLogInWindow = () =>
    {
      var password = document.getElementById('Password');
      var eyeIcon = document.getElementById('eyeIcon');
      document.getElementById('Username').value = null;
      document.getElementById('LoginError').setAttribute('hidden', 'true');
      password.type = "password";
      password.value = null;
      eyeIcon.classList.remove('fa-eye');
      eyeIcon.classList.add('fa-eye-slash');
    }

    const TogglePasswordVisibility = () =>
    {
      var password = document.getElementById('Password');
      var eyeIcon = document.getElementById('eyeIcon');

      if(password.type === "password")
      {
        password.type = "text";
        eyeIcon.classList.remove('fa-eye-slash');
        eyeIcon.classList.add('fa-eye');
      }

      else
      {
        password.type = "password";
        eyeIcon.classList.remove('fa-eye');
        eyeIcon.classList.add('fa-eye-slash');
      }
    }

    return (
        <div style={{width: "60%"}}>
            <LogInToggle IsLogIn={true}></LogInToggle>
            <div>
                  <input className="u-center text-danger input-error" enabled="false" value="Incorrect Username or Password" readOnly="readonly" hidden={true} id="LoginError"/>
                  <div className="login-label">
                    <b>Email</b>
                  </div>
                  <div className="input-control">
                    <input className="input-contains-icon-left login-input" id="Username" type="text" placeholder="Email"/>
                    <span className="icon icon-left"><i className="fas fa-envelope"></i></span>
                  </div>
                  <div className="login-label">
                    <b>Password</b>
                  </div>
                  <div className="input-control">
                    <input id="Password" className="input-contains-icon-left login-input" type="password" placeholder="Password" />
                    <span className="icon icon-left"><i className="fas fa-lock"></i></span>
                    <span className="icon icon-right"><i className="fa fa-wrapper fa-eye-slash"id="eyeIcon" aria-hidden="true" style={{pointerEvents: "auto"}} onClick={() => TogglePasswordVisibility()}></i></span>
                  </div>
                  <div className="u-flex row" style={{justifyContent:"flex-end"}}>
                    <span className="HoverUnderline" style={{paddingLeft:"1%"}}><Link to={"/auth/ForgotPassword"} style={{display:"contents"}}><p><small>Forgot Password?</small></p></Link></span>
                  </div>
                  <div>
                    <div className="log-in-button" onClick={() => LogInSubmit(props.source)}>Log in</div>
                  </div>
            </div>
        </div>
    );
}

function mapStateToProps (state)
{
  return {user: state.user};
}

export default connect(mapStateToProps, actions)(Login)
