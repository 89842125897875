import React, { useEffect, useState }  from 'react';
import ExampleJourney from '../../Images/ExampleJourney.png'
import ExamplePosts from '../../Images/ExamplePosts.png'

import 'rc-tooltip/assets/bootstrap.css';

function HowToGuide(props)
{

    return ( <div>
    <div className='card'>
        <div className='card__header'>
            <h2 className="u-center" style={{marginTop:"1%", marginBottom: "1%", textAlign:"center"}}>Journey Guide</h2>
        </div>
        <div className='content'>
            <h4>What is a Journey?</h4>
            <span>
            Use a Journey to show your progress towards reaching your goals!
            </span>
        </div>
        <div className='content'>
            <h4>Journey Structure</h4>
            <p>Each journey is made of Milestones, Posts, and Paths. Drag any element to move it.</p>
            <div className='frame'>
                <img src={ExampleJourney} alt="Example journey"></img>
            </div>
            <div style={{marginTop:"2rem",marginBottom:"2rem"}}>
                <h6>Milestones</h6>
                <p style={{marginBottom:"0.5rem"}}>Use Milestones to plan your steps to achieving your goal!</p>
                <p style={{marginBottom:"1rem"}}>
                    <b>Adding Milestones</b><br/>
                    Double click anywhere to create a Milestone.<br/>
                </p>
                <p>
                    <b>Editing Milestones</b><br/>
                    Click on a Milestone to edit it's title and description. <br/>Note: you can only delete a Milestone with no paths connecting to it.
                </p>
            </div>
            
            <div style={{marginBottom:"2rem"}}>
                <h6>Paths</h6>
                <p style={{marginBottom:"0.5rem"}}>Paths contain your Posts and connect your Milestones!</p>
                <p>
                    <b>Adding Paths</b><br/>
                    Click on the Add Path button on the bottom right menu. Select a starting Milestone for your new path, then select an ending Milestone.
                </p>
                <p>
                    <b>Editing Paths</b><br/>
                    Click on a Path and click the Edit button. Select a starting Milestone, then select an ending Milestone. <br/>
                    Note: you can only delete Paths with no Posts.
                </p>
                
            </div>
            <div className='frame'>
                <img src={ExamplePosts} alt="Example posts"></img>
            </div>
            <div style={{marginTop:"2rem"}}>
                <h6>Posts</h6>
                <p style={{marginBottom:"0.5rem"}}>Use Posts to share your progress as you work towards your Milestones! Posts of public Journeys will appear in other users’ feeds.</p>
                <p>
                    <b>Adding Posts</b><br/>
                    Select a Path and click the Add Post button, which is a blue plus sign. Write your description of your exciting progress, attach any images or videos you desire, and hit post!
                </p>
                <p>
                    <b>Milestone Posts</b><br/>
                    Use Milestone Posts to celebrate acheiving your Milestone! <br/>
                    These posts will also show up in others' feeds if your Journey is public. 
                    To add a Milestone Post, select a Milestone, then click the Add Milestone Post button.
                </p>
            </div>
        </div>
        

    </div>
</div>
)
}

export default HowToGuide;
