import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import "rc-tooltip/assets/bootstrap.css";
import Axios from "axios";
import { useLocation, Link } from "react-router-dom";
import ViewPost from "../ViewPost";
import { v4 } from 'uuid';

function JourneyPostWrapper(props)
{
  const isMounted = useRef();

  useEffect(() => {
        isMounted.current = true;
        return () => {
        isMounted.current = false;
        };
    }, []);

    const [postDate, setPostDate] = useState("");
    const[postText, setPostText] = useState('');  
    const [id, setId] = useState(" ")
    const [username, setUserName] = useState("")
    const [colour, setColour] = useState("")
    const [profilePictureS3Path, setProfilePictureS3Path] = useState("")
    const [closeLink, setCloseLink] = useState("#!")
    const [attachmentPath, setAttachmentPath] = useState("");
    const location = useLocation();

  useEffect(() => {
    setPostText("");
    setPostDate("");
    const values = new URLSearchParams(location.search);
    const postID = values.get("postID");
    setCloseLink(location.pathname + location.search.split("&")[0]);

    const retrievePost = async (postID) => {
      
      props.isLoading(true)
      const response = await Axios.get(
        process.env.REACT_APP_APIURL + "/GetPost?postID=" + postID + "&v=" + v4()
      );
      
      if (!isEmptyObject(response.data) && isMounted.current) {
        //if id is null, the page breaks due to the first indexing of the no profile pic message
        
        setAttachmentPath(response.data.ImagePath);
        
        setId(response.data.id);
        setColour(response.data.Colour);
        setProfilePictureS3Path(response.data.ProfilePictureS3Path);
        setUserName(response.data.DisplayName);
        setPostText(response.data.PostText);
        const postDate = new Date(response.data.PostedDate);
        setPostDate(postDate);
      } else{
        // send error to lambda
        
      }
      props.isLoading(false)
    };

    if(postID !== null)
    {
      retrievePost(postID);
    }
  }, [location.search]);

  const isEmptyObject = (object) =>{
    return JSON.stringify(object) === '{}'
  }
  
  
    const closeModal = () => {
      setAttachmentPath(null)
      setPostText(null)
      setPostDate(null)
      document.getElementById("ViewPost").hidden = true;
    };

    return (
          <div
          className="modal modal-animated--zoom-in"
          id="ViewPost"
          style={{ backgroundColor: "rgb(0,0,0,0.5)", zIndex: 2000 }}
        >
          <div
            className="modal-content"
            role="document"
            style={{ width: "-webkit-fill-available", backgroundColor: "rgba(0,0,0,0)", boxShadow:"none"}}
          >
            <div className="card">
              <Link to={closeLink} aria-label="Close" onClick={() => closeModal()}>
                <span className="icon u-position-relative u-pull-right" style={{margin:"3px"}}>
                  <i className="fa-wrapper fa fa-times" />
                </span>
              </Link>
              <ViewPost
                id={id}
                displayName={username}
                colour={colour}
                path={profilePictureS3Path}
                attachmentPath={attachmentPath}
                postText={postText}
                postDate={postDate}
                topCorners={true}
                noBoxShadow={true}
                isLoading={props.isLoading}
              ></ViewPost>
            </div>
          </div>
        </div>
  );
}

function mapStateToProps(state) {
  return { user: state.user };
}

export default connect(mapStateToProps, actions)(JourneyPostWrapper);
