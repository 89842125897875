import React from 'react';
import { Link } from 'react-router-dom';

function EmailPending(props)
{
  var message;

  switch(props.source)
  {
    case "SignUp":
      message = 'Please click on the link inside to verify your new account.';
    break;

    case "ForgotPassword":
      message = 'Please click on the link inside to reset your password';
    break;

    default:
      break;
  }

  if(props.hidden !== "hidden")
  {
    return (
          <div className="modal-body">
                <div className="u-center">
                  <p style={{textAlign: "center"}}> <b>Welcome {props.source === "ForgotPassword" && "back "}to SkyReachers!</b> <br/> An email has been sent to you. <br/> {message} </p>
                </div>
                <div className="row" style={{display:"block"}}>
                        <Link to={"/auth/login"}>
                        <button className="log-in-button log-in-button-2"> Return to Log In </button>
                        </Link>
                </div>
          </div>
    );
  }
  else {
      return null;
  }
}

export default EmailPending;
