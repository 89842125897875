import React, { useEffect, useState, useRef } from 'react';
import { useLocation, Link } from 'react-router-dom'
import  { confirmSignUp } from'aws-amplify/auth';
import { connect } from 'react-redux';
import * as actions from '../../actions';

function EmailConfirmation(props)
{
  const search = useLocation().search;
  const { hidden, fetchUser } = props;
  const [errorBanner, setErrorBanner] = useState('');

  const isMounted = useRef();

  useEffect(() => {
        isMounted.current = true;
        return () => {
        isMounted.current = false;
        };
    }, []);

  useEffect(() => {
    if(hidden !== "hidden")
    {
      const confirmEmailCall = async (search) => {
        const values = new URLSearchParams(search);
        try {
          await confirmSignUp({username: values.get('username'), confirmationCode: values.get('code')});
          } catch (error) {
            console.log(error);
            if(isMounted.current){
              setErrorBanner("Something went wrong whilst confirming your account. Please contact support@skyreachers.com.")
            }
          }
      }

      confirmEmailCall(search);
    }
  }, [hidden, search, fetchUser])

  if(props.hidden !== "hidden")
  {


    return (
          <div className="modal-body">
              {errorBanner ? 
                <div className="u-center input-error" enabled="false" readOnly="readonly" id="LoginError">{errorBanner}</div> : 
                  <div className="u-center">
                    <p><b>Your email has successfully been confirmed!</b></p>
                  </div>
                }
                <div className="row" style={{display:"block"}}>
                <Link to={"/auth/login"}>
                  <button className="log-in-button log-in-button-2"> Return to Log In </button>
                </Link>
            </div>
                  
          </div>
    );
  }
  else {
      return null;
  }
}

function mapStateToProps(state)
{
  return {user: state.user};
}

export default connect(mapStateToProps, actions)(EmailConfirmation);
