import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import '../../Styles/Skye.css';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import '../../Styles/LogInToggle.css';
import { fetchAuthSession } from '@aws-amplify/auth';
import Axios from 'axios';
import NewPostModal from '../UserContent/NewPostModal';
import { v4 } from 'uuid';
import TouchDeviceHeader from '../TouchDeviceHeader';
import SkyeTextChat from './SkyeTextChat';
import SkyeVideoChat from './SkyeVideoChat';
import Sentiment from 'sentiment';


const Skye = (props) => {

    const isMounted = useRef()

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);


    var location = useLocation()
    const [messages, setMessages] = useState([{ message: "{\"reply\": \"Hello " + props.user.First_Name + ", are you ready for your daily check in?\", \"action\": {\"type\": \"none\", \"parameters\": {}}, \"stage\": 0}", author: "Skye" }])
    const [textLoading, setTextLoading] = useState(false);
    const [responseState, setResponseState] = useState("text");
    const [postText, setPostText] = useState(null);
    const [endChat, setEndChat] = useState(false);
    const [videoSourceUrl, setVideoSourceUrl] = useState(null);
    const [nextStarter, setNextStarter] = useState("");
    const [ws, setWs] = useState(null);
    const [partialText, setPartialText] = useState("");
    const [preloaded, setPreloaded] = useState(false);

    const videoQueueRef = useRef();
    const videoSequenceRef = useRef();
    const videoSequenceTrackerRef = useRef();
    const calendarEventsRef = useRef();
    const calendarBusyRef = useRef();
    const currentDayTasksRef = useRef();
    const nextDayTasksRef = useRef();
    const activeJourneysRef = useRef();
    const userTimeZoneRef = useRef();
    const partialTextRef = useRef();

    const skyeProfile = process.env.REACT_APP_CLOUDFRONT + "/images/Skye_still_edited.png";

    useEffect(() => {
        if (props.user.subscription !== "Dreamer" || !props.user.isPaymentCurrent && isMounted.current) {
            window.location.href = "?context=dreamer#CloudPopup"
        }
    }, [location.pathname, props.user])

    const preloadedVideoRef = useRef(null);

    useEffect(() => {
        const setPreLoadedVideo = async () => {
            if (isMounted.current) {
                preloadedVideoRef.current = preloadVideo(process.env.REACT_APP_CLOUDFRONT + "/video/Skye_Blinking_2.mp4");
                setPreloaded(true);
            }
        }

        setPreLoadedVideo();
    }, [])

    const preloadVideo = (source) => {
        const video = document.createElement('video');
        video.src = source;
        video.preload = 'auto';
        return video;
    };

    const getCurrentDateFormatted = () => {
        const currentDate = new Date();
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        };
        return currentDate.toLocaleDateString(undefined, options);
    }

    useEffect(() => {
        partialTextRef.current = "";
        videoQueueRef.current = [];
        videoSequenceRef.current = {};
        videoSequenceTrackerRef.current = 0;

        function formatDateDifference(isoDateString) {
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            const targetDate = new Date(isoDateString);

            // Check if the date is valid
            if (isNaN(targetDate.getTime())) {
                return 'Invalid date';
            }

            const millisecondsInADay = 24 * 60 * 60 * 1000;
            const dateDifference = Math.floor((targetDate - currentDate) / millisecondsInADay);

            if (dateDifference === 0) {
                return `Today at ${formatTime(targetDate)}`;
            } else if (dateDifference === 1) {
                return `Tomorrow at ${formatTime(targetDate)}`;
            }
            else if (dateDifference === -1) {
                return `Yesterday at ${formatTime(targetDate)}`;
            } else if (dateDifference < -1) {
                return `${dateDifference * -1} days ago at ${formatTime(targetDate)}`;
            } else {
                return `${dateDifference} days from now at ${formatTime(targetDate)}`;
            }
        }

        function formatTime(date) {
            const options = {
                hour: 'numeric',
                minute: 'numeric',
            };
            return date.toLocaleTimeString(undefined, options);
        }

        const GetSkyeContextData = async () => {
            try {
                props.isLoading(true)
                const { idToken } = (await fetchAuthSession()).tokens ?? {};
                let config = { headers: { Authorization: idToken.toString() } };
                const body = {
                    date: getCurrentDateFormatted()
                }
                const response = await Axios.post(process.env.REACT_APP_APIURL + "/GetSkyeContextData", body, config)
                if (response.data) {

                    var taskString = "";
                    var nextTaskString = "";
                    var goalString = "";
                    for (const task of response.data.currentDayTasks) {
                        taskString += task.description + " which should take " + task.expectedTime +
                            ". This task has TaskID: " + task.id + " and was " +
                            (task.scheduled ? "scheudled at " + task.scheduled : "not scheduled") + ". This task is marked by the user as "
                            + (task.achieved ? "" : " not ") + "completed. \n";
                    }
                    for (const task of response.data.nextDayTasks) {
                        nextTaskString += task.description + " which should take " + task.expectedTime +
                            ". This task has TaskID: " + task.id + " and was " +
                            (task.scheduled ? "scheudled at " + task.scheduled : "not scheduled") + "\n";
                    }
                    for (const goal of response.data.activeJourneys) {
                        goalString += goal.Description + " with id: " + goal.id + "\n";
                    }
                    currentDayTasksRef.current = taskString;
                    nextDayTasksRef.current = nextTaskString;
                    activeJourneysRef.current = goalString;
                }
            }
            catch (error) {
                console.log(error)
            }
            finally {
                props.isLoading(false)
            }

        }

        const GetGoogleCalendarEvents = async () => {
            try {
                props.isLoading(true)

                const { idToken } = (await fetchAuthSession()).tokens ?? {};
                let config = { headers: { Authorization: idToken.toString() } };
                const response = await Axios.get(process.env.REACT_APP_APIURL + "/GetGoogleCalendarEvents?v=" + v4(), config)
                if (response.data) {

                    var eventString = "";
                    var busyString = "";
                    for (const event of response.data.events) {
                        eventString += event.description + " at " + formatDateDifference(event.start) + " until " + formatDateDifference(event.end) + " which has CalendarID: " + event.id + "\n"
                    }
                    for (const time of response.data.busy) {
                        busyString += "From " + formatDateDifference(time.start) + " until " + formatDateDifference(time.end) + "\n"
                    }
                    calendarEventsRef.current = eventString;
                    calendarBusyRef.current = busyString;
                    userTimeZoneRef.current = response.data.timeZone;
                }
            }
            catch (error) {
                console.log(error)
                calendarEventsRef.current = false;
            }
            finally {
                props.isLoading(false)
            }
        }

        var webSocket;

        const setUpWebSocket = async () => {

            const { idToken } = (await fetchAuthSession()).tokens ?? {};
            const url = process.env.REACT_APP_SKYE_WEBSOCKET_URL + '?token=' + idToken.toString();
            webSocket = new WebSocket(url);

            console.log("setting web socket")

            webSocket.onopen = () => {
                console.log('WebSocket Connected');
                console.log(ws);
            };

            webSocket.onerror = (event) => {
                // Handle errors
                console.error('WebSocket Error:', event);
            };

            webSocket.onclose = () => {
                console.log('WebSocket Disconnected');
                // Optionally: Reconnect or handle the disconnection
            };

            setWs(webSocket);
        }

        const setSkyeIntroComment = async () => {
            const nameKey = `video/SkyeChats/openers/names/${props.user.First_Name ? props.user.First_Name.toLowerCase() : ""}.mp4`;
            videoQueueRef.current.push(nameKey);
            videoQueueRef.current.push("video/SkyeChats/openers/check_in.mp4");
        }

        GetGoogleCalendarEvents();
        GetSkyeContextData();
        setUpWebSocket();
        setSkyeIntroComment();

        // Clean up function
        return () => {
            if (webSocket) {
                webSocket.close();
            }
        };
    }, [])

    useEffect(() => {
        if (ws) {
            ws.onmessage = (event) => {
                // Handle incoming messages
                console.log('Message from WebSocket:', event.data);

                const res = JSON.parse(event.data);

                switch (res.type) {
                    case "partialText":
                        partialTextRef.current = partialTextRef.current + res.message;
                        setPartialText(partialTextRef.current)
                        break;

                    case "textResponse":
                        if (res.message && isMounted.current) {
                            if(responseState == "text"){
                                setTextLoading(false);
                            }

                            setMessages([...messages, {
                                message: res.message,
                                author: "Skye"
                            }]);

                            const actionJSON = JSON.parse(res.message);

                            partialTextRef.current = "";
                            setPartialText(partialTextRef.current)

                            if (actionJSON.action.type === "draftPost") {
                                draftPost(actionJSON.action.parameters.content)
                            }
                            else if (actionJSON.action.type === "endChat") {
                                setEndChat(true)
                            }
                        }
                        break;

                    case "videoMessage":
                        videoSequenceRef.current[res.sequenceIndex] = res.message; 
                        while(videoSequenceRef.current[videoSequenceTrackerRef.current] !== undefined){
                            videoQueueRef.current.push(videoSequenceRef.current[videoSequenceTrackerRef.current]); 
                            videoSequenceTrackerRef.current = videoSequenceTrackerRef.current + 1
                        }
                        break;

                    case "triggerVideo":
                        setVideoSourceUrl(process.env.REACT_APP_CLOUDFRONT + "/" + res.message);
                        break;
                }
            };
        }
    }, [messages, ws])


    const calculateAge = (birthdate) => {
        const birthDate = new Date(birthdate);
        const currentDate = new Date();

        // Check if the birthdate is valid
        if (isNaN(birthDate.getTime())) {
            return 'Invalid date';
        }

        // Calculate the age
        const age = currentDate.getFullYear() - birthDate.getFullYear();

        // Check if the birthday has occurred this year
        if (
            currentDate.getMonth() < birthDate.getMonth() ||
            (currentDate.getMonth() === birthDate.getMonth() &&
                currentDate.getDate() < birthDate.getDate())
        ) {
            return age - 1; // Subtract 1 if the birthday hasn't occurred yet this year
        }

        return age;
    }

    const draftPost = (content) => {
        setPostText(content)
    }

    useEffect(() => {
        if (postText !== null) {

            window.location.href = "#newPost";
        }
    }, [postText])


    const sendMessage = useCallback(async (message, isAudio = false) => {
        if (isMounted.current) {
            setTextLoading(true)

            videoSequenceTrackerRef.current = 1;
            videoSequenceRef.current = {};

            try {
                const pastMessages = [...messages, {
                    message: message,
                    author: props.user.First_Name
                }];

                if (isMounted.current) {
                    setMessages(pastMessages)
                }

                const customScores = {
                    "did" : 1,
                    "have": 1,
                    "could": 1,
                    "was able to": 1,
                    "didn't": -2,
                    "did not": -2,
                    "haven't": -2,
                    "no, I didn't": -4,
                    "don't know": -1,
                    "not yet": -1,
                    "failed to": -2,
                    "was unable to": -2,
                    "couldn't": -2
                };
                
                // Use these custom scores in sentiment analysis
                const options = { extras: customScores };
                

                var sentiment = new Sentiment();
                var result = sentiment.analyze(message, options);

                console.log(result)


                var content = {
                    pastMessages: pastMessages,
                    currentTasks: currentDayTasksRef.current,
                    upcomingTasks: nextDayTasksRef.current,
                    goals: activeJourneysRef.current,
                    busy: calendarBusyRef.current,
                    events: calendarEventsRef.current,
                    userGender: props.user.Gender,
                    userAge: calculateAge(props.user.BirthDate),
                    userFirstName: props.user.First_Name,
                    userTimeZone: userTimeZoneRef.current,
                    currentDate: getCurrentDateFormatted(),
                    username: props.user.id,
                    isAudio: isAudio,
                    sentiment: result.score,
                    openAI: true,
                    claude: false
                };

                if (ws && ws.readyState == WebSocket.OPEN) {
                    ws.send(JSON.stringify(content));
                }
            }
            catch (e) {
                console.log(e)
            }
        }
    }, [messages, nextStarter, ws])

    return (
        <div>
            <TouchDeviceHeader setShow={props.setShow}></TouchDeviceHeader>
            <NewPostModal postText={postText} />
            <div style={{ visibility: "hidden" }}>
                {/*sourceUrl && (
                    <audio ref={audioRef} key={sourceUrl} controls>
                        <source src={sourceUrl} type="audio/mpeg" />
                    </audio>
                )*/}
            </div>
            <div className="skye-title-wrapper">
                <div className="skye-title">
                    Chat with Skye
                </div>
            </div>
            {responseState === "text" ?
                <SkyeTextChat
                    endChat={endChat}
                    messages={messages}
                    textLoading={textLoading}
                    sendMessage={sendMessage}
                    skyeProfile={skyeProfile}
                    partialText={partialText} /> :
                <SkyeVideoChat
                    endChat={endChat}
                    textLoading={textLoading}
                    setTextLoading={setTextLoading}
                    sendMessage={sendMessage}
                    videoSourceUrl={videoSourceUrl}
                    setVideoSourceUrl={setVideoSourceUrl}
                    skyeProfile={skyeProfile}
                    videoQueue={videoQueueRef.current}
                    isLoading={props.isLoading}
                    preloadedVideoRef = {preloadedVideoRef.current}
                    preloaded={preloaded}
                />}

            <div className='u-center toggle-button-wrapper'>
                <div className='u-center' style={{ width: "200px" }}>
                    <div onClick={() => { setResponseState('text') }} className={`toggle-button log-in-toggle ${responseState === "text" ? "active-toggle-button" : ""}`}>
                        Text
                    </div>
                    <div onClick={() => { if(preloaded) setResponseState('call') }} className={`toggle-button sign-up-toggle ${responseState === "call" ? "active-toggle-button" : ""}`}>
                        Call
                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return { user: state.user }
}

export default connect(mapStateToProps, actions)(Skye);