import React from 'react';
import UserLevel from './UserLevel';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import '../../Styles/UserGreeting.css'

const UserGreeting = (props) => {
  return (
    <div className="user-greeting-container">
        <UserLevel scale={0.45} level={props.user.level ? props.user.level : 1}/>
        <div className="greeting-wrapper">
            <p className="greeting-intro">Hello,</p>
            <p className="greeting-name"> <b>{props.user.DisplayName}</b></p>
        </div>
    </div>
  )}

function mapStateToProps(state)
{
  return {user: state.user};
}

export default connect(mapStateToProps, actions)(UserGreeting);