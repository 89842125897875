import React, { useState, useRef, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom'
import  { confirmResetPassword } from'aws-amplify/auth';
import { connect } from 'react-redux';
import * as actions from '../../actions';

function ResetPassword(props)
{
  const isMounted = useRef();

  useEffect(() => {
        isMounted.current = true;
        return () => {
        isMounted.current = false;
        };
    }, []);

  const search = useLocation().search;
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordToolTipVisible, setPasswordToolTipVisible] = useState(false);
  const [passwordCheckValid, setPasswordCheckValid] = useState(false);
  const [passwordCheckToolTipVisible, setPasswordCheckToolTipVisible] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const [errorBanner, setErrorBanner] = useState('');

  const values = new URLSearchParams(search);

  const TogglePasswordVisibility = () =>
  {
    var password = document.getElementById('ResetPassword');
    var eyeIcon = document.getElementById('ResetEyeIcon');

    if(password.type === "password")
    {
      password.type = "text";
      eyeIcon.classList.remove('fa-eye-slash');
      eyeIcon.classList.add('fa-eye');
    }

    else
    {
      password.type = "password";
      eyeIcon.classList.remove('fa-eye');
      eyeIcon.classList.add('fa-eye-slash');
    }
  }


  const TogglePasswordCheckVisibility = () =>
  {
    var password = document.getElementById('ResetPasswordCheck');
    var eyeIcon = document.getElementById('ResetEyeCheckIcon');

    if(password.type === "password")
    {
      password.type = "text";
      eyeIcon.classList.remove('fa-eye-slash');
      eyeIcon.classList.add('fa-eye');
    }

    else
    {
      password.type = "password";
      eyeIcon.classList.remove('fa-eye');
      eyeIcon.classList.add('fa-eye-slash');
    }
  }

  const CheckPasswordValid = () =>
    {
        const password = document.getElementById('ResetPassword').value;
        const passwordCheck = document.getElementById('ResetPasswordCheck').value;


        if(password.length >= 10)
        {
          setPasswordValid(true)
        }
        else
        {
          document.getElementById('ResetPassword').style.borderColor = 'red';
          setPasswordToolTipVisible(true);
          setPasswordValid(false);
        }

        if (passwordCheck !== ''){
          CheckPasswordCheckValid()
        }
    }


    const CheckPasswordCheckValid = () =>
    {
        const password = document.getElementById('ResetPassword').value;
        const passwordCheck = document.getElementById('ResetPasswordCheck').value;

        if(password === passwordCheck)
        {
          setPasswordCheckValid(true)
        }
        else
        {
          document.getElementById('ResetPasswordCheck').style.borderColor = 'red';
          setPasswordCheckToolTipVisible(true);
          setPasswordCheckValid(false);
        }
    }

  const ClearPasswordFieldError = () =>
  {
    document.getElementById('ResetPassword').style.borderColor = '#dee2e6';
    document.getElementById('ResetEyeIcon').style.marginRight = '0';
    setPasswordToolTipVisible(false);
  }

  const ClearPasswordCheckFieldError = () =>
  {
    document.getElementById('ResetPasswordCheck').style.borderColor = '#dee2e6';
    document.getElementById('ResetEyeCheckIcon').style.marginRight = '0';
    setPasswordCheckToolTipVisible(false);
  }

  const ResetPasswordSubmit = async () =>
  {
      props.isLoading(true);
      var password = document.getElementById('ResetPassword').value;

      try {
          await confirmResetPassword({username: values.get('username'), confirmationCode: values.get('code'), newPassword: password});
          if(isMounted.current){
            setPasswordReset(true);
          }
      }
      catch (error) {
          console.log(error)
          if(isMounted.current){
            if (error.toString().includes("LimitExceededException")){
              setErrorBanner("Password was reset recently. Please wait before trying again.")
            }
            else if (error.toString().includes("ExpiredCodeException")){
              setErrorBanner("This password reset link has expired.");
            }
            else
            {
              setErrorBanner("Something went wrong. Please contact support@skyreachers.com") 
            }
          }
          //setErrorBanner("Something went wrong. Please contact support.")
      }
      finally{
        props.isLoading(false);
      }
  }

  if(props.hidden !== "hidden")
  {
    if (passwordReset){
      return(
      <div>
          <div className="u-center">
          <p><b>Your password has successfully been updated.</b></p>
          </div>
          <div className="row" style={{display:"block"}}>
                <Link to={"/auth/login"}>
                  <button className="log-in-button log-in-button-2"> Return to Log In </button>
                </Link>
          </div>
      </div>)
    }
    else{
        return (
          <div className="modal-body" id="modal-body">
          <div>
          <p style={{textAlign:"center"}}>Please input a new password for the account {values.get('username')} below: </p>
          </div>
          <div className="login-label">
                      <b>Create a new password</b>
              </div>
              <div className="input-control">
                <input id="ResetPassword" className="input-contains-icon-right login-input" type="password" placeholder="New password" onBlur={() => CheckPasswordValid()} onChange={() => ClearPasswordFieldError()}/>
                <span className="icon icon-right"><i className="fa fa-wrapper fa-eye-slash"id="ResetEyeIcon" aria-hidden="true" style={{pointerEvents: "auto"}} onClick={() => TogglePasswordVisibility()}></i></span>
              </div>
              <div className="field-error" style={{display: passwordToolTipVisible ? 'flex' : 'none'}}>
                  <div style={{color:"red", display: passwordToolTipVisible ? 'block' : 'none'}} className="fa fa-wrapper fa-exclamation-triangle" id="PasswordIcon" aria-hidden="true"></div>
                  <div style={{ zIndex: 1000}}>{passwordToolTipVisible && "Your password must be at least 10 characters long."}</div>
                </div>
              <div className="login-label">
                      <b>Confirm new password</b>
              </div>
              <div className="input-control">
                <input id="ResetPasswordCheck" className="input-contains-icon-right login-input" type="password" placeholder="Confirm new password" onBlur={() => CheckPasswordCheckValid()} onChange={() => ClearPasswordCheckFieldError()}/>
                <span className="icon icon-right"><i className="fa fa-wrapper fa-eye-slash"id="ResetEyeCheckIcon" aria-hidden="true" style={{pointerEvents: "auto"}} onClick={() => TogglePasswordCheckVisibility()}></i></span>
              </div>
              <div className="field-error" style={{display: passwordCheckToolTipVisible ? 'flex' : 'none', marginBottom:"16px"}}>
                  <div style={{color:"red", display: passwordCheckToolTipVisible ? 'block' : 'none'}} className="fa fa-wrapper fa-exclamation-triangle" id="PasswordIcon" aria-hidden="true"></div>
                  <div style={{ zIndex: 1000}}>{passwordCheckToolTipVisible && "Passwords don't match"}</div>
              </div>
              {errorBanner ? 
              <div className="u-center input-error" enabled="false" readOnly="readonly" id="LoginError">{errorBanner}</div> : ""}
              <div className="row" style={{display:"block"}}>
                <button className="log-in-button" disabled={!passwordCheckValid || !passwordValid} onClick={() => ResetPasswordSubmit()}>Reset Password</button>
              </div>
              <div className="row" style={{display:"block"}}>
                <Link to={"/auth/login"}>
                  <button className="log-in-button log-in-button-2"> Return to Log In </button>
                </Link>
            </div>
        </div>
      )
    };
  }
  else {
      return null;
  }
}

export default connect(() => {return {}}, actions)(ResetPassword);
