import React, {useState, useRef, useEffect} from 'react';
import  { resetPassword } from'aws-amplify/auth';
import Tooltip from 'rc-tooltip';
import Axios from 'axios';
import 'rc-tooltip/assets/bootstrap.css';
import EmailPending from './EmailPending';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { Link } from 'react-router-dom';

function ForgotPassword(props)
{
    const [email, setEmail] = useState('');

    const isMounted = useRef();

    useEffect(() => {
          isMounted.current = true;
          return () => {
          isMounted.current = false;
          };
      }, []);

    const ForgotPasswordSubmit = async () =>
    {
        props.isLoading(true);
        var inputEmail = document.getElementById('ForgotPasswordEmail').value;
        console.log(inputEmail)
        if(true)
        {
          try {
              const result = await resetPassword({username: inputEmail});

              console.log(result);

              if(isMounted.current)
              {
                setEmail(inputEmail);
              }

              } catch (error) {
              console.log(error);
          }
        }
        props.isLoading(false);
    }

    if(props.hidden !== "hidden")
    {
      if(email !== '')
      {
        return (<EmailPending source="ForgotPassword" />)
      }
      else
        {
          return (
                <div className="modal-body">
                  <div>
                  <p style={{textAlign:"center"}}>Please input your email below and a link to reset your password will be emailed to you.</p>
                  </div>
                  <div className="input-control">
                    <input id="ForgotPasswordEmail" type="text" className="input-contains-icon-right login-input" placeholder="Email" style={{borderColor: "#dee2e6"}} />
                  </div>
                      <div className="row" style={{display:"block"}}>
                        <button className="log-in-button" onClick={() => ForgotPasswordSubmit()}> Submit </button>
                      </div>
                      <div className="row" style={{display:"block"}}>
                        <Link to={"/auth/login"}>
                        <button className="log-in-button log-in-button-2"> Return to Log In </button>
                        </Link>
                      </div>
                </div>
              );
        }
    }
    else {
        return null;
    }
}

export default connect(() => {return {}}, actions)(ForgotPassword);
