import React, { useEffect, useState, useRef } from 'react';
import { useLocation} from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import Axios from 'axios';
import  { fetchAuthSession } from"aws-amplify/auth";
import '../../Styles/Profile.css'
import ErrorMessage from '../ErrorMessage';
import logError from '../LogError';
import ProfilePhotoUpload from './ProfilePhotoUpload';
import JourneysList from './Journeys/JourneysList';
import ProfileAvatar from './ProfileAvatar';
import { v4 } from 'uuid';

function Profile(props) {
    const isMounted = useRef();
  
    useEffect(() => {
        isMounted.current = true;
        return () => {
        isMounted.current = false;
        };
    }, []);

    const location = useLocation();

    const [username, setUsername] = useState(' ');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [profilePicturePath, setProfilePicturePath] = useState(null);
    const [DOB, setDOB] = useState('');
    const [gender, setGender] = useState('');
    const [colour, setColour] = useState('#f03d4d');
    const [pronouns, setPronouns] = useState('');
    const [aboutMe, setAboutMe] = useState('');
    const [privateInfo, setPrivateInfo] = useState({ firstName: false, lastName: false, birthDate: false, gender: false });
    const [id, setId] = useState(' ');
    const [userProfile, setUserProfile] = useState(false);
    const [userEditing, setUserEditing] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [activeJourneyCount, setActiveJourneyCount] = useState(null);
    const [frozenJourneyCount, setFrozenJourneyCount] = useState(null);
    const [completedJourneyCount, setCompletedJourneyCount] = useState(null);
    const [followingJourneyCount, setFollowingJourneyCount] = useState(null);

    const isLoading = props.isLoading;

    useEffect(() => {
        setServerError(false);
        isLoading(true);
        if (location.pathname.toLowerCase() === '/profile/' + props.user.id) {
            setUserProfile(true);
            setColour(props.user.Colour ? props.user.Colour : '#f03d4d');
            setUsername(props.user.DisplayName);
            setFirstName(props.user.First_Name);
            setLastName(props.user.Last_Name);
            setDOB(props.user.BirthDate);
            setGender(props.user.Gender);
            setProfilePicturePath(props.user.ProfilePictureS3Path ? props.user.ProfilePictureS3Path : '');
            setPronouns(props.user.Pronouns ? props.user.Pronouns : ((props.user.Gender === "Female") ? "She/Her" : ((props.user.Gender === "Male") ? "He/Him" : "They/Them")));
            setAboutMe(props.user.About_Me ? props.user.About_Me : '');
            setPrivateInfo(props.user.PrivateInfo ? JSON.parse(props.user.PrivateInfo) : { firstName: true, lastName: true, birthDate: true, gender: true });
            setId(props.user.id);
            isLoading(false);
        }
        else {
            const getUserProfile = async () => {
                var username = location.pathname.toLowerCase().split('/').pop();
                const response = await Axios.get(process.env.REACT_APP_APIURL + "/GetUserProfile?username=" + username + "&v=" + v4());
                
                if(isMounted.current){
                    setUserProfile(false);
                    setColour(response.data.Colour ? response.data.Colour : '#f03d4d');
                    setUsername(response.data.DisplayName);
                    setFirstName(response.data.First_Name);
                    setLastName(response.data.Last_Name);
                    setDOB(response.data.BirthDate);
                    setGender(response.data.Gender);
                    setProfilePicturePath(response.data.ProfilePictureS3Path ? response.data.ProfilePictureS3Path : '');
                    setPronouns(response.data.Pronouns ? response.data.Pronouns : ((response.data.Gender === "Female") ? "She/Her" : ((response.data.Gender === "Male") ? "He/Him" : "They/Them")));
                    setAboutMe(response.data.About_Me ? response.data.About_Me : '');
                    setPrivateInfo(response.data.PrivateInfo ? JSON.parse(response.data.PrivateInfo) : { firstName: true, lastName: true, birthDate: true, gender: true });
                    setId(response.data.id);
                    isLoading(false);
                }
            }
            getUserProfile();
        }
        setUserEditing(false);
    }, [location.pathname, props.user.About_Me, props.user.Colour, props.user.First_Name, props.user.Last_Name, props.user.Gender, props.user.PrivateInfo, props.user.ProfilePictureS3Path, props.user.Pronouns, isLoading])

    var LastModified = new Date(props.user.Last_Modified);

    const saveProfile = async () => {
        props.isLoading(true);
       const { idToken } = (await fetchAuthSession()).tokens ?? {};
        const body = {
            "colour": colour,
            "pronouns": pronouns,
            "firstName": firstName,
            "lastName": lastName,
            "gender": gender,
            "aboutMe": aboutMe,
            "privateInfo": JSON.stringify(privateInfo),
        }
        let config = {headers: {"Authorization": idToken.toString()}};
        try {
            await Axios.post(process.env.REACT_APP_APIURL + '/SaveProfile', body, config);
            await props.fetchUser();
        }
        catch (e) {
            console.log(e);
            if(isMounted.current){
                logError(e);
                setServerError(true);
            }
        }
        finally {
            props.isLoading(false);
        }
    }

    return (
        <div className='u-center'>
            <div style={{ width: "95%", marginTop: "16px" }}>
                {serverError ? <ErrorMessage serverError={true}></ErrorMessage>
                    : <div style={{paddingBottom:"24px"}}>
                        <ProfilePhotoUpload profileType={"user"} profileID={id}></ProfilePhotoUpload>
                        <div className="card">
                            <div className="card__header">
                                <h2 className="u-center" style={{ marginTop: "1%", marginBottom: "1%" }}>{username}</h2>
                                {userProfile ?
                                    !userEditing ?
                                        <button className='btn-transparent btn-small header-button' onClick={() => setUserEditing(true)}>
                                            <span className="icon profile-icon"><i className="fas fa-edit"></i></span><b>Edit</b>
                                        </button>
                                        : <button className='btn-transparent btn-small header-button' style={{ color: "red" }} onClick={() => setUserEditing(false)}>
                                            <span className="icon profile-icon"><i className="fa fa-times" aria-hidden="true"></i></span>
                                        </button>
                                    : ""
                                }
                            </div>
                            <div style={{ margin: "2%" }}>
                                <div>
                                    <div className="u-flex row">
                                        <div className="col-2 u-center" style={{ marginBottom: "1rem" }}>
                                            <ProfileAvatar size={12} path={profilePicturePath} colour={colour}></ProfileAvatar>

                                            {userEditing ? <div className="u-center" style={{ position: "relative" }}>
                                                <a href="#uploadProfilePic"><button className="btn-transparent outline"><p className="text-md"></p>Change Profile Picture</button></a>
                                            </div> : ""}
                                        </div>
                                        <div className="input-control col-10">
                                            <div className="u-flex u-center" style={{ width: "100%" }}>
                                                <h5><b>About Me</b></h5>
                                            </div>
                                            {userEditing ?
                                                <textarea className='aboutMeText' placeholder='Who are you? What are you dreams? Share with the world.' value={aboutMe} id="aboutMe" onChange={(event) => setAboutMe(event.target.value)}></textarea>
                                                : <div className="aboutMeText">
                                                    <span className="aboutMe">{aboutMe}</span>
                                                </div>}
                                        </div>
                                    </div>
                                    <div className="u-flex row" style={{ position: "relative" }}>
                                        {(!userEditing && privateInfo.firstName) ? <div></div>
                                            : <div className="col-3">
                                                <div className="input-control">
                                                    <p><b>First Name:</b></p>
                                                    {userEditing ?
                                                        <div className="checkbox">
                                                            <input className="check-input" type="checkbox" id="hideFirstName" checked={privateInfo.firstName} onChange={(event) => setPrivateInfo({ firstName: event.target.checked, lastName: privateInfo.lastName, birthDate: privateInfo.birthDate, gender: privateInfo.gender })}></input>
                                                            <label className="check-label" htmlFor="hideFistName">Hide</label>
                                                            <Tooltip placement="right" animation="zoom" trigger={['hover']} id="hideFirstNameToolTip" overlayStyle={{ zIndex: 1000, width: "auto" }} overlay={<span>Hides First Name from other users.</span>}>
                                                                <span className="icon"><i className="fa fa-wrapper fa-question-circle" id="hideFirstNameQIcon" aria-hidden="true"></i></span>
                                                            </Tooltip>
                                                        </div>
                                                        : ""}
                                                </div>
                                            </div>}
                                        {(!userEditing && privateInfo.firstName) ? <div></div>
                                            : <div className="col-3">
                                                <div className="input-control">
                                                    {userEditing ?
                                                        <input id="firstName" type="text" value={firstName} onChange={(event) => setFirstName(event.target.value)} style={{ width: "100%" }} />
                                                        : <p>{firstName}</p>}
                                                </div>
                                            </div>}
                                        {(!userEditing && privateInfo.lastName) ? <div></div>
                                            : <div className="col-3">
                                                <div className="input-control">
                                                    <p><b>Last Name:</b></p>
                                                    {userEditing ?
                                                        <div className="checkbox">
                                                            <input className="check-input" type="checkbox" id="hideLastName" checked={privateInfo.lastName} onChange={(event) => setPrivateInfo({ firstName: privateInfo.firstName, lastName: event.target.checked, birthDate: privateInfo.birthDate, gender: privateInfo.gender })}></input>
                                                            <label className="check-label" htmlFor="hideLastName">Hide</label>
                                                            <Tooltip placement="right" animation="zoom" trigger={['hover']} id="hideLastNameToolTip" overlayStyle={{ zIndex: 1000, width: "auto" }} overlay={<span>Hides Last Name from other users.</span>}>
                                                                <span className="icon"><i className="fa fa-wrapper fa-question-circle" id="hideLastNameQIcon" aria-hidden="true"></i></span>
                                                            </Tooltip>
                                                        </div>
                                                        : ""}
                                                </div>
                                            </div>}
                                        {(!userEditing && privateInfo.lastName) ? <div></div>
                                            : <div className="col-3">
                                                <div className="input-control">
                                                    {userEditing ?
                                                        <input id="lastName" type="text" value={lastName} onChange={(event) => setLastName(event.target.value)} style={{ width: "100%" }} />
                                                        : <p>{lastName}</p>
                                                    }
                                                </div>
                                            </div>}
                                    </div>
                                    <div className="u-flex row">
                                        {(!userEditing && privateInfo.birthDate) ? <div></div>
                                            : <div className="col-3">
                                                <div className="input-control">
                                                    <p><b>Birth Date:</b></p>
                                                    {userEditing ?
                                                        <div className="checkbox">
                                                            <input className="check-input" type="checkbox" id="hideDOB" checked={privateInfo.birthDate} onChange={(event) => setPrivateInfo({ firstName: privateInfo.firstName, lastName: privateInfo.lastName, birthDate: event.target.checked, gender: privateInfo.gender })} ></input>
                                                            <label className="check-label" htmlFor="hideDOB">Hide</label>
                                                            <Tooltip placement="right" animation="zoom" trigger={['hover']} id="hideDOBToolTip" overlayStyle={{ zIndex: 1000, width: "auto" }} overlay={<span>Hides Birth Date from other users.</span>}>
                                                                <span className="icon"><i className="fa fa-wrapper fa-question-circle" id="hideDOBQIcon" aria-hidden="true"></i></span>
                                                            </Tooltip>
                                                        </div>
                                                        : ""}
                                                </div>
                                            </div>}
                                        {(!userEditing && privateInfo.birthDate) ? <div></div>
                                            : <div className="col-3">
                                                <div className="input-control">
                                                    {userEditing ?
                                                        <input className="u-pull-left input-contains-icon-right" readOnly={true} value={DOB} style={{ paddingRight: "4%" }} type="date" disabled={true} />
                                                        : <p>{DOB}</p>
                                                    }
                                                </div>
                                            </div>}
                                        {(!userEditing && privateInfo.gender) ? <div></div>
                                            : <div className="col-3">
                                                <div className="input-control">
                                                    <p><b>Gender:</b>
                                                        {userEditing ?
                                                            <Tooltip placement="right" animation="zoom" trigger={['hover']} id="GenderToolTip" overlayStyle={{ zIndex: 1000, width: "20%" }} overlay={<span>Customisation will be in a future update.</span>}>
                                                                <span className="icon"><i className="fa fa-wrapper fa-question-circle" id="genderQIcon" aria-hidden="true"></i></span>
                                                            </Tooltip>
                                                            : ''}</p>
                                                    {userEditing ?
                                                        <div className="checkbox">
                                                            <input className="check-input" type="checkbox" id="hideGender" checked={privateInfo.gender} onChange={(event) => setPrivateInfo({ firstName: privateInfo.firstName, lastName: privateInfo.lastName, birthDate: privateInfo.birthDate, gender: event.target.checked })}></input>
                                                            <label className="check-label" htmlFor="hideGender">Hide</label>
                                                            <Tooltip placement="right" animation="zoom" trigger={['hover']} id="hideGenderToolTip" overlayStyle={{ zIndex: 1000, width: "auto" }} overlay={<span>Hides Gender from other users.</span>}>
                                                                <span className="icon"><i className="fa fa-wrapper fa-question-circle" id="hideGenderQIcon" aria-hidden="true"></i></span>
                                                            </Tooltip>
                                                        </div>
                                                        : ""}
                                                </div>
                                            </div>}
                                        {(!userEditing && privateInfo.gender) ? <div></div>
                                            : <div className="col-3">
                                                <div className="input-control">
                                                    {userEditing ?
                                                        <select className="u-pull-right" id="gender" type="text" value={gender} onChange={(event) => setGender(event.target.value)} style={{ width: "100%" }}>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                            <option value="Non-Binary">Non-Binary</option>
                                                            <option value="Other">Other</option>
                                                            <option value="Private">Prefer not to say</option>
                                                        </select>
                                                        : <p>{gender}</p>}
                                                </div>
                                            </div>}
                                    </div>
                                    <div className="u-flex row">
                                        <div className="col-3">
                                            <div className="input-control">
                                                <p><b>Favourite Colour:</b>
                                                    {userEditing ?
                                                        <Tooltip placement="right" animation="zoom" trigger={['hover']} id="ColourToolTip" overlayStyle={{ zIndex: 1000, width: "20%" }} overlay={<span>Favourite Colour will determine default settings (like the default profile picture background).</span>}>
                                                            <span className="icon"><i className="fa fa-wrapper fa-question-circle" id="ColourQIcon" aria-hidden="true"></i></span>
                                                        </Tooltip> : ''}</p>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="input-control">
                                                {userEditing ?
                                                    <input className="u-pull-right" id="colour" value={colour} onChange={(event) => changeColour(event.target.value)} style={{ paddingRight: "4%" }} type="color" />
                                                    : <div style={{ lineHeight: "2", width: "80%", height: "90%", backgroundColor: colour, color: colour }}>{colour}</div>}
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="input-control">
                                                <p><b>Pronouns:</b>
                                                    {userEditing ?
                                                        <Tooltip placement="right" animation="zoom" trigger={['hover']} id="PronounToolTip" overlayStyle={{ zIndex: 1000, width: "20%" }} overlay={<span>Pronouns are public by default. Customisation will be in a future update.</span>}>
                                                            <span className="icon"><i className="fa fa-wrapper fa-question-circle" id="PronounsQIcon" aria-hidden="true"></i></span>
                                                        </Tooltip> : ""}</p>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="input-control">
                                                {userEditing ?
                                                    <select className="u-pull-right" value={pronouns} onChange={(event) => setPronouns(event.target.value)} id="pronouns" type="text" style={{ width: "100%" }}>
                                                        <option value="He/Him">He/Him</option>
                                                        <option value="She/Her">She/Her</option>
                                                        <option value="They/Them">They/Them</option>
                                                    </select>
                                                    : <p>{pronouns}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {userEditing ?
                                    <div className="card__footer level content">
                                        Updated on: {LastModified.toString()}
                                        <button className="log-in-button" style={{ float: "right", width:"unset" }} onClick={() => saveProfile()}>Save</button>
                                    </div>
                                    : ""}
                            </div>
                        </div>
                        <div className="card">
                            <div className="card__header">
                                <div className="u-center" style={{ marginTop: "1%", position: "relative", textAlign: "center" }}>
                                    <h2>Active Journeys {activeJourneyCount != null ? `(${activeJourneyCount})` : ""}</h2>
                                </div>
                            </div>
                            <div>
                                <JourneysList key={id} ownerID={id} ownerType="user" status="active" showNewJourney={userProfile} setJourneyCount={setActiveJourneyCount}></JourneysList>
                            </div>
                        </div>
                        {userProfile ?
                            <div className="card">
                                <div className="card__header">
                                    <div className="u-center" style={{ marginTop: "1%", position: "relative", textAlign: "center" }}>
                                        <h2>Frozen Journeys {frozenJourneyCount != null ? `(${frozenJourneyCount})` : ""}</h2>
                                    </div>
                                </div>
                                <div>
                                    <JourneysList key={id} ownerID={id} ownerType="user" status="frozen" setJourneyCount={setFrozenJourneyCount}></JourneysList>
                                </div>
                            </div> : ""}
                        <div className="card">
                            <div className="card__header">
                                <div className="u-center" style={{ marginTop: "1%", position: "relative", textAlign: "center" }}>
                                    <h2>Completed Journeys {completedJourneyCount != null ? `(${completedJourneyCount})` : ""}</h2>
                                </div>
                            </div>
                            <div>
                                <JourneysList key={id} ownerID={id} ownerType="user" status="complete" setJourneyCount={setCompletedJourneyCount}></JourneysList>
                            </div>
                        </div>

                        {/* bring communities list back when the communities are live again
            <div className="card">
            <div className="card__header">
                <div className="u-center" style={{marginTop: "1%", position:"relative", textAlign:"center"}}>
                    <h2>Communities {communityCount != null ? `(${communityCount})` : ""}</h2>
                </div>
                {userProfile ? 
                <Link to="/NewCommunity">
                            <button className="btn-transparent btn-small header-button" style={{color:"blue"}}>
                                <span className="icon" style={{fontSize:"x-large"}}>
                                    <i className="fas fa-plus"></i>
                                </span>
                            </button>
                        </Link> : ""}
            </div>
                <div>
                <CommunityList key={id} member={id} profile={true} showButtons={false} setCommunityCount={setCommunityCount}></CommunityList>
                </div>
            </div>
            */}
                        {userProfile ?
                            <div className="card">
                                <div className="card__header">
                                    <div className="u-center" style={{ marginTop: "1%", position: "relative", textAlign: "center" }}>
                                        <h2>Following {followingJourneyCount != null ? `(${followingJourneyCount})` : ""}</h2>
                                    </div>
                                </div>
                                <div>
                                    <JourneysList ownerID={id} ownerType="user" following={true} setJourneyCount={setFollowingJourneyCount}></JourneysList>
                                </div>
                            </div> : ""}
                    </div>}
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return { user: state.user };
}

export default connect(mapStateToProps, actions)(Profile);
