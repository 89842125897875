import React, { memo } from 'react';
import '../../../Styles/Journeys/JourneyNodes.css';
import { Handle } from 'reactflow';

export default memo(({ data, isConnectable }) => {

  

  const formatDate = (epochTime) => {
    const date = new Date(epochTime);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    let suffix = 'th';
    if (day === 1 || day === 21 || day === 31) suffix = 'st';
    else if (day === 2 || day === 22) suffix = 'nd';
    else if (day === 3 || day === 23) suffix = 'rd';
    return `${day}${suffix} ${month} ${year}`;
  };

  return (
    <>
      <Handle
        type="target"
        position="left"
        style={{ background: '#555'}}
        isConnectable={isConnectable}
      />
      <div className='nodeWrapper'>
        <div>
          <span id='nodeLabel'>{data.label}</span>
        </div>
        <hr/>
        <div style={{fontSize:"small"}}>
          <b>{formatDate(data.targetDate ? data.targetDate : 4102419600000)}</b>
        </div>
      </div>
      <Handle
        type="source"
        position="right"
        style={{ background: '#555'}}
        isConnectable={isConnectable}
      />
    </>
  );
});
