import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import "../Styles/TouchDeviceHeader.css";
import ProfileAvatar from './UserContent/ProfileAvatar';
import * as actions from '../actions';

function TouchDeviceHeader(props) {
  const [showEditButton, setShowEditButton] = useState(false);

  useEffect(() => {
    if (props.showEditButton){
      setShowEditButton(true)
    }
  },[props.showEditButton])

  if(props.user === null || !props.user){return null} 

  return (
    <div className='notification-banner'>
      <div className='page-header'>
          {props.title}
      </div>
      <div style={{display: showEditButton ? "block" : "none"}}>
      <a href="#newPost" style={{padding:"0"}}>
            <span className="far fa-edit edit-button">
            </span>
      </a>
      </div>
      <div>
      <Link to={"/Invites"} style={{padding:"0"}}>
            <span className="far fa-bell notif-button">
              <span className="notification-count"></span>
            </span>
      </Link>
      </div>
      <div className="header-profile" onClick={() => props.setShow(true)}>
        <ProfileAvatar path={props.user.ProfilePictureS3Path} colour={props.user.Colour} size={2.2}/>
      </div>
    </div>
  );
}

function mapStateToProps(state)
{
  return {user: state.user};
}

export default connect(mapStateToProps, actions)(TouchDeviceHeader);