import {PROTO_ACCESS} from '../actions/types';

export default function protoAccess(state = null, action)
{
  switch (action.type)
  {
    case PROTO_ACCESS:
      return action.payload || false;

    default:
      return state;
  }
}
