import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import ViewPost from "./ViewPost";

function Feed(props) {
  const isMounted = useRef();

    useEffect(() => {
        isMounted.current = true;
        return () => {
        isMounted.current = false;
        };
    }, []);

  const [posts, setPosts] = useState([]);
  const [postsRetreived, setPostsRetrieved] = useState(false);

  const isLoading = props.isLoading;
  const getFeed = props.getFeed;
  const feed = props.feed;

  var postsLeft = props.preview ? posts.slice(0, 2) : posts.slice(0, 7);
  var postsRight = props.preview ? posts.slice(2, 4) : posts.slice(7, 14); 

  useEffect(
    () => {
      const renderContent = () => {
        isLoading(true);
        
        const getAllPosts = async () => {
          //if users first visit or reset cache
          if (feed == null || !feed || !feed.content){
            await getFeed();
          }
          else if (determineRefresh(Date.now() - feed.retrievalTime)){
            await getFeed();
          }
          else{
            
            if(isMounted.current){
              setPosts(feed.content);
              setPostsRetrieved(true);
            }
          }
          isLoading(false);
        };

        if (!postsRetreived) {
          getAllPosts();
        } else isLoading(false);
      };

      renderContent();
    },
    [isLoading, feed, getFeed, postsRetreived]
  );

  const determineRefresh = (timePassed) =>
  {
    //3 hours. 
    //Use the box mullers transform to get a random variate normally distriubted around 3 hours
    //with a standard deviation of 20 minute. 
    //This means users' feeds will usually refresh sometime within around 2-4 hours, but very likely closer to 3.
    let u = 1 - Math.random()
    let v = Math.random();
    let z = Math.sqrt( -2.0 * Math.log( u ) ) * Math.cos( 2.0 * Math.PI * v );
    let threshold = z * 1200000 + 10800000;
    // set threshold to zero for testing 
    // threshold = 10000;

    return timePassed > threshold
  }

  return (
    <div className="u-flex" style={{padding:"0px", flexWrap:"wrap", width:"100%"}}>
      <div className="col-6" style={{padding:"0px"}}>
      {posts ? postsLeft.map((x) => (
        <div key={x.ID}>
              <ViewPost
                id={x.PosterID}
                displayName={x.DisplayName}
                colour={x.Colour}
                path={x.PosterProfilePictureS3Path}
                attachmentPath={x.ImagePath}
                postText={x.PostText}
                postDate={x.PostedDate}
                topCorners={false}
                left={!props.isMobile && true}
                right={!props.isMobile && false}
                journeyTitle={x.JourneyTitle}
                journeyID={x.JourneyID}
                milestone={true}
              ></ViewPost>
          </div>
      )):""}
      </div>
      <div className="col-6" style={{padding:"0px"}}>
      {posts? postsRight.map((x) => (
        <div key={x.ID}>
              <ViewPost
                id={x.PosterID}
                displayName={x.DisplayName}
                colour={x.Colour}
                path={x.PosterProfilePictureS3Path}
                attachmentPath={x.ImagePath}
                postText={x.PostText}
                postDate={x.PostedDate}
                topCorners={false}
                right={!props.isMobile && true}
                left={!props.isMobile && false}
                journeyTitle={x.JourneyTitle}
                journeyID={x.JourneyID}
                milestone={false}
              ></ViewPost>
          </div>
      )):""}
      </div>
      {/* postsRetreived ? 
      <div className="card">
        <div className=''>
          <div className="u-flex row">
            <div className="col-3" style={{display: "flex", alignItems: "center", justifyContent:"center", height:"25vh"}}>
              <span className="icon subtitle" style={{fontSize:"X-large", transform: "scale(5)"}}><i className="fa fa-solid fa-clock"></i></span>
            </div>
            <div className="col-9">
              <p style={{margin: "2%", textAlign:"justify"}}>You've reached the end of your feed! To avoid infinte scrolling, 
              SkyReachers only shows 15 posts at a time. Check back in 2-4 hours if you'd like to see some different content.
              In the meantime, we'd encourage you to dig deeper into the journeys showcased above. See how the small posts fit into 
              bigger pictures. Otherwise, take a break from technology. Go chase your dreams, then come back to report how far you went!</p>
            </div>
          </div>
        </div>
      </div> : <div></div>*/}
    </div>
  );
}

function mapStateToProps(state) {
  return { user: state.user, feed: state.feed };
}

export default connect(mapStateToProps, actions)(Feed);
