import 'rc-tooltip/assets/bootstrap.css';
import '../Styles/App.css';
import NavBar from './NavBar';
import { Link } from 'react-router-dom';

function ErrorMessage(props)
{
    return (
        <div>
            {!props.serverError && !props.pageNotFound? 
            <div>
                <NavBar/>
            </div>
            :""
            }
            <div className='centralDiv' style={{marginTop:"10vh"}}>
                <div className="card u-center">
                    <div className="content u-text-center pt-3">
                        <span className="icon subtitle" style={{fontSize:"X-large", transform: "scale(5)", marginTop:"4rem"}}><i className="fa fa-solid fa-sad-tear"></i></span>
                        <h4 id="projectname" className="title" style={{paddingTop:"1rem"}}>Oops!</h4>
                        <p>{!props.pageNotFound ? "Something went wrong on this page.": "Page not found."}</p>
                    </div>
                    <div className='u-center' style={{marginBottom:"24px"}}>
                        <div style={{marginRight:"3rem"}}>
                            <Link to="/Contact" rel="noreferrer"><button className="log-in-button">Contact SkyReachers Support 
                            <br/>
                            (We'd appreciate it!)</button></Link>
                        </div>
                        <Link to="/" onClick={props.resetErrorBoundary}><button className="log-in-button">Return to Home</button></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ErrorMessage;
