import React from 'react';
import "../Styles/Footer.css";
import { useLocation } from 'react-router-dom';


function Footer(props) {

  const Location = useLocation();

  if(Location.pathname.toLowerCase() === "/subscription" || Location.pathname.toLowerCase().includes("/auth/") || props.hideDisplay)
  {return null}

  return (
    <div className="custom-footer">
          <div className='content-line' style={{paddingTop:"16px"}}>
            <div>
              <a href={"#ToS"}><b style={{textDecoration:"underline", color:"black"}}>Terms & Conditions</b></a>
            </div>
            <div>
              <a href={"#privacyPolicy"}><b style={{textDecoration:"underline", color:"black"}}>Privacy Policy</b></a>
            </div>
          </div>
          <div className="content-line">
            <div>
              <p className="copyright-line">Copyright © 2023 SkyReachers Inc. All rights reserved</p>
            </div>
          </div>
    </div>
  );
}

export default Footer;
