import React from 'react';

const UserLevel = (props) => {
  return (
    //User level display set to hidden until we are ready to implement the point system
    <div style={{width: "200px", height:"200px", transform:`rotate(22.5deg) scale(${props.scale})`, marginLeft:"-50px", visibility:"hidden"}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200">
        <path d="M150 100
                Q170 128.91, 135.355 135.355, 
                Q128.91 170, 100 150
                Q71.09 170, 64.645 135.355
                Q30 128.91, 50, 100
                Q30 70.09, 64.645 64.645
                Q71.09 30, 100 50
                Q128.91 30, 135.355 64.645
                Q170 70.09, 150 100Z
                "
                fill="none" stroke="#F18B81" strokeWidth="5"/>
                <text x={85} y={110} fontSize={50} fill='#F18B81' transform="rotate(-22.5 100 100)">{props.level}</text>
        </svg>
    </div>
  )}

  export default UserLevel;