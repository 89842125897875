import React, { useEffect, useState, useRef, useCallback }  from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import 'rc-tooltip/assets/bootstrap.css';
import { useLocation, Link } from 'react-router-dom';
import '../../../Styles/Journeys/JourneyNodes.css';
import Tooltip from 'rc-tooltip';
import Axios from 'axios';
import  { fetchAuthSession } from"aws-amplify/auth";
import useAutosizeTextArea from '../useAutosizeTextArea';
import '../../../Styles/EditMileStone.css';


function EditMileStone(props)
{
    const isMounted = useRef();

    useEffect(() => {
        isMounted.current = true;
        return () => {
        isMounted.current = false;
        };
    }, []);

    const [label, setLabel] = useState('');
    const[description, setDescription] = useState('');   
    const [editing, setEditing] = useState(true);
    const [hasPost, setHasPost] = useState(false);
    const [targetDate, setTargetDate ] = useState(null);
    
    const [postLink, setPostLink] = useState(window.location.href);
    const isLoading = props.isLoading;
    const descriptionTextAreaRef = useRef(null);
    const location = useLocation();


    useAutosizeTextArea(descriptionTextAreaRef.current, description)


    const deleteNode = useCallback(
        () => {
            props.setNoRestore(true);
            props.setNodes((nds) => nds.filter(nds => (nds.id !== props.node.id)));
            window.location.href = '#!';
            props.unsavedChanges(true)
        }, [props.setNodes, props.node]
    )

    const changeMileStoneLabel = useCallback(
        (id, newLabel, newDescription, newDate) => {
            props.setNodes((nds) =>
                nds.map((node) => {
                    if (node.id !== id) {
                        return node;
                    }
                    const label = newLabel;
                    const description = newDescription;
                    const date = new Date(newDate);
                    const targetDate = date.getTime() + date.getTimezoneOffset() * 60000;

                    return {
                        ...node,
                        data: {
                            ...node.data,
                            label,
                            description,
                            targetDate
                        },
                    };
                })
            );
        },
        [props.setNodes]
    )

    const labelChange = () =>{
        const milestoneLabel = document.getElementById("milestoneLabel").textContent;
        setLabel(milestoneLabel);
        changeMileStoneLabel(props.node.id, milestoneLabel, description, targetDate);
        window.location.href = "#!";
        props.unsavedChanges(true);
    }
    
    useEffect(() => {
        if (props.node && props.node.data){
            setLabel(props.node.data.label);
            setDescription(props.node.data.description? props.node.data.description: "");
            if(props.node.type === "milestoneNode"){
                const date = new Date(props.node.data.targetDate)
                setTargetDate(date.toISOString().split('T')[0])
            }
            if (props.node.data.post){
                setPostLink(location.pathname + location.search.split("&")[0] + "&postID=" + props.node.data.post);
                setHasPost(true);
            } else{
                setHasPost(false);
            }
        }
        setEditing(props.viewerIsEditor);
    }, [props.node, location, props.viewerIsEditor]);

    const addPost = () => {
        labelChange();
        props.onSave();
        props.setAddingPostFromGraph(true);
        window.location.href="#newPost";
    }

    const [error, setError] = useState(false);

    const getMilestoneSteps = async () => 
    {
        isLoading(true);
        try
        {
       const { idToken } = (await fetchAuthSession()).tokens ?? {};
        const config = {headers: {"Authorization": idToken.toString()}};
        const body = {
            Age: props.user.DOB,
            Gender: props.user.Gender,
            Milestone: label
        }
        const response = await Axios.post(process.env.REACT_APP_APIURL + "/getChatGPTMilestoneSteps", body, config)
        if (response.data && isMounted.current){
            setDescription(response.data)
        }
        }
        catch(e){
        console.log(e)
        }
        finally{
        isLoading(false)
        }
    }


    return (
        <div className="modal modal-animated--zoom-in" id="editMileStone" style={{backgroundColor:"rgb(0,0,0,0.5)", zIndex:2000}}>
            <div className="modal-content" role="document" style={{width: "-webkit-fill-available", backgroundColor: "rgba(0,0,0,0)", boxShadow:"none"}}>
             
                <div>
                <div className="card" style={{width:"100%", boxShadow:"none", maxHeight:"80vh"}}>
                    <a href="#!" aria-label="Close">
                        <span className="icon u-position-relative u-pull-right" style={{marginRight:"3px"}}>
                            <i className="fa-wrapper fa fa-times"/>
                        </span>
                    </a>
                    <div className="card__header" style={{display:"flex", flexDirection:"column"}}>
                        <div className="u-center" style={{marginTop:"1%", marginBottom: "1%", width:"90%"}} >
                            <h3 className="u-center" style={{textAlign:"center"}}>
                                <span id="milestoneLabel" contentEditable={editing} suppressContentEditableWarning>
                                    {label}
                                </span>
                            </h3>
                        </div>
                    </div>
                    {error ? <div className="toast toast--danger">
                        <p>An Error occured and the post wasn't saved. Please contact support.</p>
                        <button className="btn-close" onClick={() => setError(false)}></button>
                    </div>: ""}
                    <div className="u-center" style={{marginTop:"2%", marginBottom:"2%", maxHeight:"55vh", overflow:"auto"}}>
                        {props.node && props.node.type==="milestoneNode" ?
                        <div className="u-center" style={{marginTop:"1%", marginBottom: "1%", width:"100%"}} >
                            <input type="date" value={targetDate} disabled={!editing} className="milestone-date" onChange={(e) => setTargetDate(e.target.value)}></input>
                        </div> : ""}
                        <div className="milestone-date">
                            <hr/>
                        </div>
                        {editing?
                            <textarea 
                                id="milestoneDescription"
                                placeholder={props.node && props.node.type==='beginningNode'? 'Describe your beginning!'
                                                : props.node && props.node.type==='goalNode'? 'Describe your goal!'
                                                :'Describe your Milestone!'}
                                value={description} 
                                ref={descriptionTextAreaRef}
                                rows={1}
                                onChange={(event) => setDescription(event.target.value)} 
                                style={{border: "none", resize: "none", width: "95%", scrollbarWidth:"none"}}>
                            </textarea>
                            :<p>{description}</p>}
                    </div>
                    {editing ? 
                        <div className="cacard__action-bar u-center">
                             {(!props.nodeHasEdges && !hasPost) ? 
                                    <Tooltip placement="bottom" animation="zoom" trigger={['hover']} id="deleteButton" overlayStyle={{ zIndex: 100000, width: "auto"}} overlay={<span>Delete</span>}>
                                    <button className="btn-small btn-danger outline" style={{marginRight: "2%"}} onClick={()=> deleteNode()}><i className="fas fa-trash-alt fa-lg"></i></button>
                                    </Tooltip>
                                :''
                            }
                            {props.node && props.node.type==="milestoneNode" ?
                                <button className="outline btn-info btn-small"  style={{marginRight: "2%"}}  onClick={()=>getMilestoneSteps()}>AI Suggested Steps <i className="fas fa-lightbulb fa-lg"></i></button>
                                :""}
                            {props.node && props.node.type==="milestoneNode" ?
                                <Tooltip placement="bottom" animation="zoom" trigger={['hover']} id="addMilestonePostButton" overlayStyle={{ zIndex: 100000, width: "auto"}} overlay={<span>Add Post automatically saves your Journey!</span>}>
                                    <button className="btn-info btn-small" onClick={()=>{addPost()}}>Add Milestone Post</button>
                                </Tooltip>
                                : props.node && props.node.type==="milestoneNodeWithPost" ? 
                                <Link to={postLink} >
                                    <button className="btn-info btn-small" style={{marginBottom: "1rem"}} >View Milestone Post</button>
                                </Link>
                                :''
                            }
                            <Tooltip placement="bottom" animation="zoom" trigger={['hover']} id="saveButton" overlayStyle={{ zIndex: 100000, width: "auto"}} overlay={<span>Save</span>}>
                                <button className="btn-info btn-small"  style={{marginLeft: "2%"}}  onClick={()=>labelChange()}><i className="far fa-save fa-lg"></i></button>
                            </Tooltip>
                        </div>
                        : <div className="card__footer level content">
                            {props.node && props.node.type==="milestoneNodeWithPost" ? 
                                <div className="u-center">
                                    <Link to={postLink}>
                                    <button className="btn-info" >View Milestone Post</button>
                                    </Link>
                                </div>
                                :''}
                        </div>}
                    </div>
                </div>
            </div>
        </div>                    
    )
}

function mapStateToProps(state)
{
  return {user: state.user};
}

export default connect(mapStateToProps, actions)(React.memo(EditMileStone));
