import React, { useEffect, useState, useRef }  from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { s3FileRetrival } from '../IOFunctions/S3Files';

function ProfileAvatar(props)
{
    const [currentProfilePicture, setCurrentProfilePicture] = useState(null);
    const [colour, setColour] = useState('');
    const [size, setSize] = useState(3.2)
    const isLoading = props.isLoading;
    const path = props.path;

    const isMounted = useRef();
  
    useEffect(() => {
        isMounted.current = true;
        return () => {
        isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (props.size){
            setSize(props.size)
        }
    }, [props.size])

    useEffect( () => {
        isLoading(true);
        const setProfile = async () => {
            setColour(props.colour ? props.colour : '#f18b81');
            if (path != null){
                const imageUrl = await s3FileRetrival(path);
                if(isMounted.current){
                    setCurrentProfilePicture(imageUrl.data);} 
                }
            else if (isMounted.current){
                setCurrentProfilePicture(null)
            }
            isLoading(false);
        }
        setProfile();
    }, [colour, isLoading, props.colour, path,])   

    return (
                <div key={currentProfilePicture} style={{width: `${size}rem`, height: `${size}rem`}} >
                            <figure className="avatar u-center" style={{float:"left", background: "white", fontSize: `${size}rem`, width: `${size}rem`, height: `${size}rem`, color: `${colour}`}}>
                            {currentProfilePicture ?         
                                <img src={currentProfilePicture} style={{display:"block"}}></img> :
                                <span className="far fa-user-circle" style={{display:"block"}}></span>
                            }
                             </figure>
                </div>  
    )
}

function mapStateToProps(state)
{
  return {user: state.user};
}

export default connect(mapStateToProps, actions)(ProfileAvatar);
