import React, { useEffect, useState }  from 'react';
import {Link} from 'react-router-dom'
import { connect } from 'react-redux';
import * as actions from '../../actions';
import '../../Styles/ProfileTag.css'
import ProfileAvatar from './ProfileAvatar';

function ProfileTag(props)
{
    const isLoading = props.isLoading;
    const path = props.path;
    const [asterix, setAsterix] = useState("");
    const [reactiveText, setReactiveText] = useState("");
    const [profileLink, setProfileLink] = useState("");
    const [defaultSymbol, setDefaultSymbol] = useState(" ");

    useEffect( () => {
        if (props.displayName){
            isLoading(true);
            if (props.displayName.length <= 10){
                setReactiveText("tile-title m0 reactive-text-short")
            }
            else if (props.displayName.length <= 20){
                setReactiveText("tile-title m0 reactive-text-medium")
            }
            else{
                setReactiveText("tile-title m0 reactive-text-long")
            }
            setProfileLink(props.type === "user" ?  `/profile/${props.id}` : (props.type === "community" ?  `/communities?id=${props.id}` : ""))
            setDefaultSymbol(props.type === "user" ?  (props.id ? props.id[0].toUpperCase() : "") : (props.type === "community" ?  (props.displayName ? props.displayName[0].toUpperCase() : "") : ""))
            if (props.asterix)
            {
                setAsterix("*")
            }
            isLoading(false);
        }
    }, [isLoading, props.asterix, props.displayName, props.id, props.type])  
    
    return (
        <div style={{paddingTop:"8px", paddingBottom:"8px", display:"flex", justifyContent:"space-between"}}>
            <div className="tile u-items-center" style={{borderRadius: "2%"}}>
                <Link to={profileLink} style={{padding:"0"}}>
                    <ProfileAvatar path={path} colour={props.colour}></ProfileAvatar>
                </Link>
                <div>
                <Link to={`/Journeys?id=${props.journeyID}`} style={{padding:"0"}}>
                <div style={{paddingLeft: "24px", fontSize:"large"}}>
                    <b>{props.journeyTitle}</b>
                </div>
                </Link>
                <Link to={profileLink} style={{padding:"0"}}>
                {props.hideName? "":
                    <div style={{paddingLeft: "24px", fontSize:"medium"}}>
                       {props.displayName}{asterix}
                    </div>}
                </Link>
                </div>
            </div>
            <div style={{marginRight:"15px"}}>
                {props.milestone ? 
                <span className="fas fa-gem" style={{color: "gold", textShadow: "0 0 2px #000"}}></span>
                : ""}
            </div>
        </div>
                        
    )
}

function mapStateToProps(state)
{
  return {user: state.user};
}

export default connect(mapStateToProps, actions)(ProfileTag);
