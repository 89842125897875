import React, { useState, useEffect, useRef } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
    PaymentElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import Logo from "../Images/Logo.png";
import Stripe from "../Images/Stripe.svg";
import { Link } from 'react-router-dom';
import Axios from 'axios';
import  { fetchAuthSession } from'aws-amplify/auth';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPEPUBLICKEY);


const CheckoutForm = (props) => {
    const isMounted = useRef()

    useEffect(() => {
        isMounted.current = true;
        return () => {
        isMounted.current = false;
        };
    }, []);

    const [message, setMessage] = useState("");
    const [showContinueButton, setShowContinueButton] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [amount, setAmount] = useState(1);

    const elements = useElements();
    const stripeClient = useStripe();

    useEffect(() => {
        if (props.props.paymentIntentClientSecret && stripeClient && isMounted.current) {
            stripeClient.retrievePaymentIntent(props.props.paymentIntentClientSecret).then(({ paymentIntent }) => {
                setPaymentStatus(paymentIntent.status)
                setAmount(paymentIntent.amount)
            })
        }
        if(props.props.value && isMounted.current){
            setAmount(props.props.value)
        }
    }, [props.props, stripeClient])

    useEffect(() => {
        if (paymentStatus !== null && isMounted.current) {
            
            switch (paymentStatus) {
                case 'succeeded':
                    setMessage('Success! Payment received. The SkyReachers team wishes you well on your journeys!');
                    setShowContinueButton(true);
                    break;

                case 'processing':
                    setMessage("Payment processing. We will update you when it is finished processing. In the meantime, you will have access to free SkyReachers resources.");
                    setShowContinueButton(true);
                    break;

                case 'requires_payment_method':
                    setMessage('Payment failed. Please try another payment method.')
                    setShowContinueButton(false)
                    break;

                default:
                    setMessage('Something went wrong proccessing your payment. Please try again')
                    setShowContinueButton(false)
                    break;
            }
        }
    }, [paymentStatus])

    const handleSubmit = async (event) => {
        try {
            props.isLoading(true)
            event.preventDefault();

            if (elements == null) {
                return;
            }

            elements.submit();

            //configure request details for back End
           const { idToken } = (await fetchAuthSession()).tokens ?? {};
            let config = {headers: {"Authorization": idToken.toString()}};
            let body = {
                StripeCustomerID: props.props.stripeCustomerID,
                PriceID: props.props.priceID
            }

            //get client intent and store payment details
            const result_1 = await Axios.post(process.env.REACT_APP_APIURL + '/CreateStripeSetUpIntent', body, config);
            if (result_1.data) {
                const setUpIntentClientSecret = result_1.data.client_secret;

                //Check the payment details and assign as primary payment method:
                const { error, setupIntent } = await stripeClient.confirmSetup(
                    {
                        elements,
                        clientSecret: setUpIntentClientSecret,
                        redirect: 'if_required',
                        confirmParams: {
                            return_url: window.location.href,
                        },
                    }
                );

                if (error && isMounted.current) {
                    setMessage(error.message);
                } else {
                    //Update the user's defualtPayment
                    body.paymentMethod = setupIntent.payment_method;

                    const result_2 = await Axios.post(process.env.REACT_APP_APIURL + '/UpdateStripeCustomerPaymentMethod', body, config);
                    if (result_2.data && !props.props.updatePayment) {
                        //Create the Subscription object;
                        const result_3 = await Axios.post(process.env.REACT_APP_APIURL + '/CreateStripeSubscription', body, config);
                        if (result_3.data) {
                            if (result_3.data.status === "requires_action" || result_3.data.status === "requires_confirmation") {
                                //Attempt to get further authentication if action is needed
                                const { error } = await stripeClient.confirmPayment({
                                    clientSecret: result_3.data.client_secret,
                                    confirmParams: {
                                        return_url: window.location.href,
                                        payment_method: setupIntent.payment_method
                                    },
                                });

                                if (error && isMounted.current) {
                                    setMessage(error.message);
                                }
                            }
                            else if(isMounted.current) {
                                setPaymentStatus(result_3.data.status)
                            }
                        }
                    }
                    else if (props.props.updatePayment && isMounted.current) {
                        setMessage("Payment Method Successfully Updated")
                        setShowContinueButton(true)
                    }
                }
            }
            else if(isMounted.current){
                setMessage("Something went wrong saving your payment details. Please try again")
            }
        }
        catch (e) {
            console.log(e)
                if(isMounted.current){
                    setMessage("Something went wrong. Please doublecheck your details and if the problem persists, contact support@skyreachers.com")
                }
            }
        finally {
            props.isLoading(false)
        }
    }

    const cancel = () => {
        if (props.props.updatePayment) {
            window.location.href = "/settings"
        }
        else if (isMounted.current){
            props.setSelectedTier(null)
        }

    }


    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <div id="#payment"></div>
            <div className="u-center" style={{ marginTop: "24px" }}>
                <button type="submit" className="tier-button" disabled={!elements} style={{ display: showContinueButton ? "none" : "block" }}>
                    <b>{props.props.updatePayment ? "Save Payment Method" : "Pay $" + amount/100 + " USD"}</b>
                </button>
                <Link to="/" style={{ display: !showContinueButton ? "none" : "block" }}>
                    <button className="tier-button">
                        <b>Continue</b>
                    </button>
                </Link>
                <button onClick={() => cancel()} className="tier-button promo-code-button" style={{ marginTop: 0, marginLeft: "10px", display: showContinueButton ? "none" : "block" }}>
                    <b>Cancel</b>
                </button>
            </div>
            <div className="u-center">
                Powered by <img src={Stripe} style={{ width: "5rem" }} alt="Stripe" />
            </div>
            {/* Show error message to your customers */}
            {message && <div className={`field-error u-center ${showContinueButton ? "field-success" : ""}`}><b>{message}</b></div>}
        </form>)
}

const CheckoutFormWrapper = (props) => {

    return (
        <div className="u-center" style={{ width: "100vw", alignItems: "flex-start", marginTop: "32px" }}>
            <div className="login-title">
                <div className="u-center">
                    <h4 className="title">
                        <img src={Logo} className="LoginScreenLogo" alt="" />
                    </h4>
                    <h1 className="skyreachers-title">SKYREACHERS</h1>
                </div>
                <div className="u-center">
                    <h5 className="skyreachers-title-line">Combining the Best of&nbsp;<div style={{ color: "#794585" }}>Humanity</div>&nbsp;and&nbsp;<div style={{ color: "#4C7E83" }}>AI</div>
                    </h5>
                </div>
                <div className="u-center" style={{ marginTop: "-16px", color: "#f18b81" }}>
                    <h5 className="skyreachers-title-line">OPEN BETA
                    </h5>
                </div>
            </div>
            <div className="stripe-container">
                {props.props?.value || props.props?.paymentIntentClientSecret ?
                    <Elements stripe={stripePromise} options={{ mode: "setup", currency: "usd", setup_future_usage: 'off_session' }}>
                        <CheckoutForm props={props.props} isLoading={props.isLoading} setSelectedTier={props.setSelectedTier}></CheckoutForm>
                    </Elements> : ""}
            </div>
        </div>
    );
};

export default CheckoutFormWrapper;