export const getBezierEquations = (pathString) =>
    {
        pathString = pathString[0];
        const XCoordinates = pathString.split(" ").map(x => parseFloat((x.split(",")[0]).replace(/[^\d.-]/g, '')));
        const YCoordinates = pathString.split(" ").map(x => parseFloat((x.split(",")[1]).replace(/[^\d.-]/g, '')));

        const XEquation = (t) =>  {
            return ((1 - t)**3)*XCoordinates[0] + 3*((1 - t)**2)*t*XCoordinates[1] + 3*((t)**2)*(1 - t)*XCoordinates[2] + (t**3)*XCoordinates[3]
        }

        const YEquation = (t) =>  {
            return ((1 - t)**3)*YCoordinates[0] + 3*((1 - t)**2)*t*YCoordinates[1] + 3*((t)**2)*(1 - t)*YCoordinates[2] + (t**3)*YCoordinates[3]
        }

        return [XEquation, YEquation]
    }

export function getArcLengths(pathString, numberOfSegments = 25)
    {
        const [XEquation, YEquation] = getBezierEquations(pathString);
        const arcLengths = new Array(numberOfSegments + 1);
        arcLengths[0] = 0;

        var xLocation = XEquation(0); 
        var yLocation = YEquation(0);
        var newXLocation;
        var newYLocation;
        var totalLength = 0;

        for (var i = 1; i <= numberOfSegments; i++)
        {
            newXLocation = XEquation(i * 1/numberOfSegments);
            newYLocation = YEquation(i * 1/numberOfSegments);
            totalLength += Math.sqrt((xLocation - newXLocation)**2 + (yLocation - newYLocation)**2)
            arcLengths[i] = totalLength;
            xLocation = newXLocation;
            yLocation = newYLocation;
        }

        return arcLengths;

    }

export function getEquallyDistributedLocationsAlongPath(edgePath, numberOfLocations, numberOfSegments = 25){
    const arcLengths = getArcLengths(edgePath, numberOfSegments);
    const maxLength = arcLengths[arcLengths.length - 1];
    const targetLengths = new Array(numberOfLocations);
    const finalTs = new Array(numberOfLocations);

    for (let i = 1; i <= numberOfLocations; i++)
    {
        targetLengths[i - 1] = (1/(numberOfLocations + 1)) * i * maxLength;
    }

    targetLengths.forEach((length, index) =>
    {    
        var start = 0; var end = arcLengths.length - 1;

        while (Math.abs(start - end) > 1){
            let mid=Math.floor((start + end)/2);
      
            if (arcLengths[mid]===length)
            {
                start = mid;
                end = mid;
            }
     
            else if (arcLengths[mid] < length)
                 start = mid;
            else
                 end = mid - 1;
        }

        const lengthBefore = arcLengths[start];
        const lengthAfter = arcLengths[start + 1];

        const interpolationLength = (length - lengthBefore)/(lengthAfter - lengthBefore);

        finalTs[index] = (1/numberOfSegments) * (start + interpolationLength); 

    });


    const [XEquation, YEquation] = getBezierEquations(edgePath);
    const finalCoordinates = finalTs.map(t => [XEquation(t), YEquation(t)]);

    return finalCoordinates;
}
