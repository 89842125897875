import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import "rc-tooltip/assets/bootstrap.css";
import ProfileTag from "./ProfileTag";
import ReactPlayer from "react-player";
import '../../Styles/ViewPost.css'

function ViewPost(props) {
  const isMounted = useRef();

    useEffect(() => {
        isMounted.current = true;
        return () => {
        isMounted.current = false;
        };
    }, []);

  const MAX_POST_HEIGHT_IN_PX = 600 
  const [selectedFileURL, setSelectedFileURL] = useState(null);
  const [longPost, setLongPost] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [hasVideo, setHasVideo] = useState(false);
  const [loved, setLoved] = useState(false)
  const [postText, setPostText] = useState(null)
  const postBodyRef = useRef(null);
  
  const expand = () => {
    setExpanded(!expanded)
  }

  useEffect(() => {
    setPostText(props.postText)
    if ((postBodyRef && postBodyRef.current ? postBodyRef.current.scrollHeight : 0) > 90){
      setLongPost(true)
    } else {
      setLongPost(false)
    }
  }, [props.id, props.attachmentPath, props.postText, postBodyRef.current])
 

  useEffect(() => {
      props.isLoading(true)
      if (props.attachmentPath && props.attachmentPath!== "") {
        ;
        setSelectedFileURL(process.env.REACT_APP_CLOUDFRONT + "/" + props.attachmentPath);
        if (props.attachmentPath.includes("video") || props.attachmentPath.includes("videos")){
          setHasVideo(true)
          if (props.postText){
            setLongPost(true)
          } else {
            setLongPost(false)
          }
        } else if (props.attachmentPath.includes("images") ){ // if the body text is longer than 300px, it is a long post
          if (((postBodyRef && postBodyRef.current) ? postBodyRef.current.scrollHeight : 0) > 300){
            setLongPost(true)
          } else {
            setLongPost(false)
          }
        }
      }
      else if(isMounted.current){
        setSelectedFileURL(null)
        setHasVideo(false)
      }
      props.isLoading(false)
  }, [props.attachmentPath]);

  const getTimeSince = (time) => {
    const now = new Date().getTime();
    const timeDifference = now - time;
    const secondsInMillisecond = 1000;
    const minutesInMillisecond = secondsInMillisecond * 60;
    const hoursInMillisecond = minutesInMillisecond * 60;
    const daysInMillisecond = hoursInMillisecond * 24;
    const weeksInMillisecond = daysInMillisecond * 7;
    const monthsInMillisecond = daysInMillisecond * 30.44; // Approximate days in a month
    const yearsInMillisecond = daysInMillisecond * 365.25; // Approximate days in a year

    if (timeDifference < minutesInMillisecond) {
      return "less than 1 minute ago";
    } else if (timeDifference < hoursInMillisecond) {
      const minutes = Math.floor(timeDifference / minutesInMillisecond);
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (timeDifference < daysInMillisecond) {
      const hours = Math.floor(timeDifference / hoursInMillisecond);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (timeDifference < weeksInMillisecond) {
      const days = Math.floor(timeDifference / daysInMillisecond);
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (timeDifference < monthsInMillisecond) {
      const weeks = Math.floor(timeDifference / weeksInMillisecond);
      return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
    } else if (timeDifference < yearsInMillisecond) {
      const months = Math.floor(timeDifference / monthsInMillisecond);
      return `${months} month${months > 1 ? 's' : ''} ago`;
    } else {
      const years = Math.floor(timeDifference / yearsInMillisecond);
      return `${years} year${years > 1 ? 's' : ''} ago`;
    }
  }

  return (
    <div>
      <div className={`post-body ${props.left ? "post-left" : ""} ${props.right ? "post-right" : ""}`} style={{maxHeight: expanded ? "100%" : (props.attachmentPath && props.attachmentPath!== "" ? "420px" : "220px")}}>
        <div className="tile__container" style={{lineHeight: "initial", marginLeft: "16px", height:"70px" }}>
          <ProfileTag type="user"
            key={props.id}
            id={props.id}
            displayName={props.displayName}
            colour={props.colour}
            path={props.path}
            journeyTitle={props.journeyTitle}
            journeyID={props.journeyID}
            link = {true}
            milestone={props.milestone}
          ></ProfileTag>
        </div>
        {selectedFileURL?.includes("images") ? (
          <div>
            <img
              src={selectedFileURL}
              style={{ display: "block", margin: "auto", maxHeight: "200px"}}
              alt=""
            />
          </div>
        ) : (
          ""
        )}
        {hasVideo ? (
          <div className="player-wrapper" style={{padding: 0, margin:0 }}>
            <div className="u-center" style={{width:"100%"}}>
            
            <ReactPlayer
              config={{
                file: {
                  attributes: { type: "video/mp4", playsInline: true}
                },
              }}
              className = "react-player"
              height="200px"
              width="90%"
              controls
              url={selectedFileURL}
            />
          </div>
          </div>
        ) : (
          ""
        )}
        <div className="u-center row" style={{ marginLeft: "2%", marginRight: "2%", marginTop: "0", overflow:"hidden", height: "100%" }}>
          <div className="input-control col-12">
            <div ref={postBodyRef} className="u-center" style={{ width: "95%"}}>
              <p id="postText">{postText}</p>
              <div className="readmore" onClick={() => expand()} style={{top: expanded ? "" : "65px", bottom: expanded ? "-10px" : "", display: longPost ? "block" : "none"}}>see {expanded ? "less" : "more"}</div>
            </div>
          </div>
        </div>

        <div style={{height:"40px", display: "flex", justifyContent:"space-between"}}>
          <div style={{fontSize:"small", textDecoration:"italic", margin:"10px"}}> Posted {props.postDate ? getTimeSince(props.postDate) : "unknown"}</div>
          {/* Add love react into posts soon
          
           <div style={{marginRight:"15px", marginTop:"10px", fontSize:"medium"}}>
            <span style={{cursor: "pointer", color:`${loved ? "#F18B81" : "black"}`}} onClick={() => setLoved(!loved)} className={`fa${loved ? "s" : "r"} fa-heart`}></span>
            </div>*/
          }
        </div>
      </div>
      
    </div>
  );
}

function mapStateToProps(state) {
  return { user: state.user };
}

export default connect(mapStateToProps, actions)(ViewPost);
