import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, {
    addEdge,
    Controls,
    Background,
    useReactFlow
} from 'reactflow';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import '../../../Styles/Journeys/JourneyNodes.css';
import milestoneNode from './MileStoneNode';
import milestoneNodeWithPost from './MileStoneNodeWithPost';
import beginningNode from './BeginningNode';
import goalNode from './GoalNode';
import PebbleEdge from './PebbleEdges';
import Axios from 'axios';
import Tooltip from 'rc-tooltip';
import { fetchAuthSession } from "aws-amplify/auth";
import { v4 as uuid } from 'uuid';
import EditEdge from './EditEdge';
import '../../../Styles/Journeys/JourneyPanel.css';
import 'reactflow/dist/style.css';


//es lint exhaustive dependencies are disabled throughout this file.
//trying to fix them resulted in infitine re-renders hence, I have elected to just trust the dependencies I intentionally added 
const nodeTypes = {
    milestoneNode: milestoneNode,
    milestoneNodeWithPost: milestoneNodeWithPost,
    beginningNode: beginningNode,
    goalNode: goalNode,
};

const edgeTypes = {
    PebbleEdge: PebbleEdge
}

function JourneyPanel(props) {
    const isMounted = useRef();

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    const [isFollowing, setIsFollowing] = useState(false);
    const connectingNodeId = useRef(null);
    const reactFlowWrapper = useRef(null);

    const reactFlowInstance = useReactFlow();
    const [addingEdge, setAddingEdge] = useState(false);
    const [editingEdge, setEditingEdge] = useState(false);
    const [newEdgeStartNode, setNewEdgeStartNode] = useState('');
    const [selectedEdgePosition, setSelectedEdgePosition] = useState({ x: '0', y: '0' });
    const isLoading = props.isLoading;
    const [edgeExists, setEdgeExists] = useState(false);
    const [selfLoopEdge, setSelfLoopEdge] = useState(false);
    const [badSelection, setBadSelection] = useState(false);
    const relativeNumFollowers = { 1: "a few", 2: "several", 3: "many", 4: "a ton of" }


    useEffect(() => {
        if (reactFlowInstance && props.journeyLoaded) {
            reactFlowInstance.fitView();
        }
    }, [reactFlowInstance, props.journeyLoaded]);

    const onConnect = useCallback((params) => props.setEdges((eds) => addEdge({ ...params, type: "PebbleEdge" }, eds)), [props.setEdges]);

    const onConnectStart = useCallback((_, { nodeId }) => {
        connectingNodeId.current = nodeId;
    }, []);

    const onConnectEnd = useCallback(
        (event) => {
            const targetIsPane = event.target.classList.contains('react-flow__pane');

            if (targetIsPane) {
                // we need to remove the wrapper bounds, in order to get the correct position
                const id = uuid();
                const { top, left } = reactFlowWrapper.current.getBoundingClientRect();
                const newNode = {
                    id: id,
                    position: reactFlowInstance.screenToFlowPosition({ x: event.clientX - left - 50, y: event.clientY - top }),
                    data: { label: "New MileStone", description: "", targetDate: 4102401600000 },
                    type: 'milestoneNode',
                };

                props.setNodes((nds) => nds.concat(newNode));
                props.setEdges((eds) =>
                    eds.concat({ id: uuid(), source: connectingNodeId.current, target: id, type: "PebbleEdge", data: { posts: [] } })
                );
                props.setUnsavedChanges(true)
            }
        },
        [reactFlowInstance.screenToFlowPosition, props.setEdges, props.setNodes]
    );

    const onPaneClick = useCallback(
        (event) => {
            if (event.detail === 2 && props.viewerIsEditor) {
                const { top, left } = reactFlowWrapper.current.getBoundingClientRect();
                const newNode = {
                    id: uuid(),
                    position: reactFlowInstance.screenToFlowPosition({ x: event.clientX - left - 40, y: event.clientY - top - 40 }),
                    data: { label: "New Milestone", description: '', targetDate: 4102401600000 },
                    type: 'milestoneNode',
                };
                props.setNodes((nds) => nds.concat(newNode));
                props.setUnsavedChanges(true)
            }
        },
        [reactFlowInstance.screenToFlowPosition, props.setNodes, props.viewerIsEditor]
    )

    const addNewEdge = () => {
        setAddingEdge(true);
        setNewEdgeStartNode("");
        removeEdgeErrorBanners();
    }

    const cancelNewEdge = () => {
        setAddingEdge(false);
        setEditingEdge(false);
        setNewEdgeStartNode("");
    }

    const removeEdgeErrorBanners = () => {
        // removes error messages from adding or editing edges
        setEdgeExists(false);
        setSelfLoopEdge(false);
    }

    const editEdge = useCallback(
        () => {
            window.location.href = '#!';
            setEditingEdge(true);
            setNewEdgeStartNode("");
            removeEdgeErrorBanners();
        },
        []
    )

    const deleteEdge = useCallback(
        () => {
            props.setNoRestore(true); // disable restore which would otherwise override edge changes
            props.setEdges(props.edges.filter(edg => (edg.id !== props.selectedEdge.id)));
            window.location.href = '#!';
            removeEdgeErrorBanners();
            props.setUnsavedChanges(true)
        }, [props.setEdges, props.edges, props.selectedEdge])


    const onNodeClick = useCallback(
        (event, node) => {

            const saveEditedEdge = (edgeId, startNode, endNode) => {
                let duplicateEdge = false;
                props.edges.forEach((edge) => {
                    if ((edge.source === newEdgeStartNode && edge.target === endNode) || (edge.source === endNode && edge.target === newEdgeStartNode)) {
                        duplicateEdge = true;
                    }
                })
                if (duplicateEdge) {
                    setEdgeExists(true);
                } else if (startNode !== "" && startNode === endNode) {
                    setSelfLoopEdge(true);
                } else {
                    setEdgeExists(false);
                    setSelfLoopEdge(false);
                    props.setEdges(props.edges.map(edge => {
                        if (edge.id === edgeId) {
                            return { ...edge, source: startNode, target: endNode };
                        } else {
                            return edge;
                        }
                    }));
                }
                setEditingEdge(false);
            }

            const saveNewEdge = (endNode) => {
                setAddingEdge(false);
                let duplicateEdge = false;
                props.edges.forEach((edge) => {
                    if ((edge.source === newEdgeStartNode && edge.target === endNode) || (edge.source === endNode && edge.target === newEdgeStartNode)) {
                        duplicateEdge = true;
                    }
                })
                if (duplicateEdge) {
                    setEdgeExists(true);
                } else if (newEdgeStartNode === endNode) {
                    setSelfLoopEdge(true);
                } else {
                    setEdgeExists(false);
                    setSelfLoopEdge(false);
                    props.setEdges((eds) =>
                        eds.concat(
                            {
                                id: uuid(),
                                source: newEdgeStartNode,
                                target: endNode,
                                type: "PebbleEdge",
                                data: { posts: [] }
                            })
                    );
                }
            }

            props.setSelectedNode(node);
            if (props.addingPost) { //adding post from journey bottom
                props.setSelectedEdge(null);
                if (node.type !== "milestoneNode") {
                    setBadSelection(true);
                }
                else {
                    props.onPost()
                }
            }
            else if (!editingEdge && !addingEdge) { //opening edit milestone
                window.location.href = "#editMileStone";
                props.setNoRestore(true);
                props.setNodeHasEdges(false);
                props.edges.every((edge) => {
                    if (edge.source === node.id || edge.target === node.id) {
                        props.setNodeHasEdges(true);
                        return false;
                    }
                    return true;
                });
            }
            else { //editing edge mode
                if (newEdgeStartNode === "") {
                    setNewEdgeStartNode(node.id);
                } else {
                    if (addingEdge) {
                        saveNewEdge(node.id);
                    } else {
                        saveEditedEdge(props.selectedEdge.id, newEdgeStartNode, node.id);
                    }
                    props.setUnsavedChanges(true)
                }
            }
        },
        [props.edges, editingEdge, addingEdge, setNewEdgeStartNode, newEdgeStartNode, props.selectedEdge, props.setEdges, props.addingPost]
    )

    useEffect(() => {
        //set the post pebbles to be clickable or not during adding post selection
        if (props.addingPost) {
            props.setEdges((nds) =>
                nds.map((edge) => {
                    return {
                        ...edge,
                        data: {
                            ...edge.data,
                            clickable: false,
                        },
                    };
                }))
        }
    }, [props.addingPost])


    const onEdgeClick = useCallback(
        (event, edge) => {
            if (props.viewerIsEditor) {
                props.setSelectedEdge(edge);
                props.setSelectedNode(null);
                if (props.addingPost) { // adding post from journey bottom
                    props.onPost()
                }
                else if (!editingEdge) {
                    setSelectedEdgePosition({ x: event.clientX, y: event.clientY });
                    window.location.href = "#editEdge";
                    props.setNoRestore(true);
                }
            }
        }, [addingEdge, editingEdge, props.viewerIsEditor, props.addingPost]
    )

    const onFollow = async (follow) => {
        if (isMounted.current) {
            setIsFollowing(follow);
        }
        try {
            const body = { journeyID: journeyID, follow: follow };
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
            let config = { headers: { "Authorization": idToken.toString() } };
            await Axios.post(process.env.REACT_APP_APIURL + '/FollowJourney', body, config);
        }
        catch (err) {
            console.log(err);
            if (isMounted.current) {
                logError(err);
                setServerError(true);
            }
        }
    }

    const onRestore = useCallback(() => {
        if (isMounted.current) {
            isLoading(true);
            removeEdgeErrorBanners();
            (false);
            const restoreFlow = async () => {
                if (props.tempStorage) {
                    const flow = JSON.parse(props.tempStorage);
                    if (flow) {
                        props.setNodes(flow.nodes || []);
                        props.setEdges(flow.edges || []);
                        flow.edges.forEach((x) => {
                            if (!props.hasPosts && x.data.posts.length !== 0) {
                                props.setHasPosts(true)
                            }
                        })
                    }
                    props.setUnsavedChanges(false)
                }
            };
            restoreFlow();
            isLoading(false);
        }
        // eslint-disable-next-line
    }, [props.setEdges, props.setNodes, props.tempStorage, isLoading]);

    //reset and restore from temp data every time the save is updated
    //This is to ensure update upon data retrieval for saved journeys. 
    useEffect(() => {
        if (!props.noRestore) {
            onRestore()
        }
    },
        [props.noRestore, onRestore, props.tempStorage]);

    return (
        <div>
            {props.viewerIsEditor ? <div>
                {edgeExists ?
                    <div className="toast toast--danger" style={{ margin: "0%" }}>
                        <p>Path not saved: This path already exists.</p>
                        <button className="btn-close" onClick={() => setEdgeExists(false)}></button>
                    </div> : ""}
                {selfLoopEdge ?
                    <div className="toast toast--danger" style={{ margin: "0%" }}>
                        <p>Path not saved: A Milestone cannot be connected to itself. </p>
                        <button className="btn-close" onClick={() => setSelfLoopEdge(false)}></button>
                    </div> : ""}
                {props.addingPost ?
                    <div className="toast toast--warning" style={{ margin: "0%" }}>
                        <p> Select a Path or Milestone to add your Post</p>
                        <button className="btn-close" onClick={() => props.setAddingPost(false)}></button>
                    </div> : ""}
                {(!editingEdge && !addingEdge) ?
                    ""
                    : <div className="toast toast--warning" style={{ margin: "0%" }}>
                        <p>{addingEdge ? "Add Path: " : "Edit Path: "}Select <b>{(newEdgeStartNode === "") ? "a STARTING" : "an ENDING"}</b> Milestone for this path.</p>
                    </div>
                } </div> : ""
            }
            {props.saveError ?
                <div className="toast toast--danger" style={{ margin: "0%" }}>
                    <p> There was an error saving your journey. All changes might not be saved. </p>
                    <button className="btn-close" onClick={() => props.setSaveError(false)}></button>
                </div> : ""
            }
            {badSelection ?
                <div className="toast toast--danger" style={{ margin: "0%" }}>
                    <p> Cannot add a post to a Milestone that has already been updated.</p>
                    <button className="btn-close" onClick={() => { props.setAddingPost(false); setBadSelection(false) }}></button>
                </div> : ""}
            <div style={{ position: "absolute", top: "5%", right: "2%", width: "inherit", display: "flex" }}>
                {(props.numFollowers > 2) ?
                    <Tooltip placement="bottom" animation="zoom" trigger={['click']} id="followersButton" overlayStyle={{ zIndex: 1000, width: "auto" }} overlay={<span>This journey has {relativeNumFollowers[props.numFollowers]} followers</span>}>
                        <i className="fas fa-users" style={{ cursor: "pointer" }}></i>
                    </Tooltip>
                    : (props.numFollowers > 0) ?
                        <Tooltip placement="bottom" animation="zoom" trigger={['click']} id="followersButton" overlayStyle={{ zIndex: 1000, width: "auto" }} overlay={<span>This journey has {relativeNumFollowers[props.numFollowers]} followers</span>}>
                            <i className="fas fa-user-friends" style={{ cursor: "pointer" }}></i>
                        </Tooltip>
                        : ""
                }
            </div>
            <div className="card wrapper journey-card" style={{ postion: "relative", zIndex: 1050, display: "block", height: props.journeyHeight }}
                ref={reactFlowWrapper}>
                <ReactFlow
                    nodes={props.nodes}
                    edges={props.edges}
                    onNodeDrag={() => { if (props.journeyLoaded && !props.unsavedChanges) { props.setUnsavedChanges(true) } }}
                    onNodesChange={props.onNodesChange}
                    onEdgesChange={props.onEdgesChange}
                    onConnect={(!props.addingPost && (editingEdge || addingEdge)) ? onConnect : () => { }}
                    onConnectStart={(editingEdge || addingEdge || props.addingPost) ? () => { } : onConnectStart}
                    onConnectEnd={(editingEdge || addingEdge || props.addingPost) ? () => { } : onConnectEnd}
                    onPaneClick={(editingEdge || addingEdge || props.addingPost) ? () => { } : onPaneClick}
                    onNodeClick={onNodeClick}
                    onEdgeClick={onEdgeClick}
                    nodesDraggable={!(editingEdge || addingEdge || props.addingPost) && props.viewerIsEditor}
                    onInit={props.setRfInstance}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                    attributionPosition="top-right"
                    zoomOnDoubleClick={false}
                    nodesConnectable={props.viewerIsEditor}
                    elementsSelectable={props.viewerIsEditor}
                    style={{ postion: "sticky" }}
                >
                    <div>
                        <Controls showInteractive={props.viewerIsEditor} />
                    </div>
                    <Background color="#aaa" gap={16} />

                    <EditEdge
                        editEdge={editEdge}
                        addPost={props.addPostFromGraph}
                        deleteEdge={deleteEdge}
                        edge={props.selectedEdge}
                        edgePosition={selectedEdgePosition}
                    />
                    {props.unsavedChanges ? <div className="unsaved-changes-div">
                        <div className="toast toast--link" style={{ margin: "0%", width: "fit-content", borderRadius: "20px" }}>
                            <p className="unsaved-changes-font">You have unsaved changes</p>
                        </div></div> : ""}
                    {props.viewerIsEditor ?
                        <div>
                            {(addingEdge || editingEdge) ?
                                <div style={{ position: "absolute", bottom: 0, right: "2%", zIndex: 1000, width: "inherit" }}>
                                    <Tooltip placement="bottom" animation="zoom" trigger={['hover']} id="cancelButton" overlayStyle={{ zIndex: 1000, width: "auto" }} overlay={<span>Cancel</span>}>
                                        <button className="btn-small btn-danger outline" style={{ float: "right" }} onClick={() => cancelNewEdge()}><i className="fa fa-times" aria-hidden="true"></i></button>
                                    </Tooltip>
                                </div>
                                : <div style={{ position: "absolute", bottom: 0, right: "2%", zIndex: 1000, width: "inherit", display: "flex" }}>
                                    <Tooltip placement="bottom" animation="zoom" trigger={['hover']} id="addEdgeButton" overlayStyle={{ zIndex: 1000, width: "auto" }} overlay={<span>Add Path</span>}>
                                        <button className="btn-info btn-small" style={{ marginLeft: "1%", float: "right" }} onClick={() => addNewEdge()}><i className="fas fa-arrows-alt-h fa-lg"></i></button>
                                    </Tooltip>
                                    <Tooltip placement="bottom" animation="zoom" trigger={['hover']} id="restoreButton" overlayStyle={{ zIndex: 1000, width: "auto" }} overlay={<span>Restore</span>}>
                                        <button className="btn-info btn-small" style={{ marginLeft: "1%", float: "right" }} onClick={() => onRestore()}><i className="fas fa-sync-alt fa-lg"></i></button>
                                    </Tooltip>
                                    <Tooltip placement="bottom" animation="zoom" trigger={['hover']} id="saveButton" overlayStyle={{ zIndex: 1000, width: "auto" }} overlay={<span>Save</span>}>
                                        <button className="btn-info btn-small" style={{ marginLeft: "1%", float: "right" }} onClick={props.onSave}><i className="far fa-save fa-lg"></i></button>
                                    </Tooltip>
                                </div>}
                        </div>
                        : <div style={{ position: "absolute", bottom: 0, right: "-2%", zIndex: 1000, width: "inherit", scale: "90%" }}>
                            {props.user ?
                                isFollowing ?
                                    <button className='outline btn-dark' style={{ float: "right", marginRight: "3%" }} onClick={() => onFollow(false)} ><i className="fas fa-check"></i>&nbsp; Following</button>
                                    : <button className='outline btn-dark' style={{ float: "right", marginRight: "3%" }} onClick={() => onFollow(true)}><i className="fas fa-plus"></i>&nbsp; Follow</button>
                                : ""}
                        </div>
                    }
                </ReactFlow>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return { user: state.user };
}

export default connect(mapStateToProps, actions)(JourneyPanel);