import React, {useState} from "react";
import DayDreamer from '../../Images/Nico.jpg';

function SpotLightPost(){
    const [loved, setLoved] = useState(false);

    return(
        <div className="spotlight-post">
            <div className="u-flex row">
                <div style={{height:"358px", width:"50%", overflow: "clip", borderRadius:"20px", padding: 0}}>
                    <img src={DayDreamer} style={{height:"360px"}}></img>
                </div>
                <div style={{width:"50%"}}>
                    <div style={{height:"50px", marginTop:"32px", marginLeft:"16px", marginRight:"16px", textAlign:"center"}}>
                        <h5>SkyReachers Spotlight: DayDreamer</h5>
                    </div>
                    <div style={{height:"200px", margin:"16px", marginTop:"32px"}}>
                        Daydreamer has been hard at work, coding this app by himself. His New Year's resolution for 2024 is to help at least 5 other people achieve a goal!
                    </div>
                    <div style={{height:"40px", display: "flex", justifyContent:"flex-end", margin:"16px"}}>
                        <span style={{cursor: "pointer", color:`${loved ? "#F18B81" : "black"}`}} onClick={() => setLoved(!loved)} className={`fa${loved ? "s" : "r"} fa-heart`}></span>
                    </div>
                </div>

            </div>
        </div>

    )
}

export default SpotLightPost;