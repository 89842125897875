import React, {useState} from 'react';
import NewPost from './NewPost';

function NewPostModal(props)
{    
    const [refreshModal, setRefreshModal] = useState(false);

    return (
        <div className="modal modal-animated--zoom-in" id="newPost" style={{backgroundColor:"rgb(0,0,0,0.5)", zIndex:2000}} >
            <div className="modal-content" style={{width: "1000px", background: "none", border:"none", boxShadow:"none"}}>
                <div style={{position: "relative", width:"100%", height: "1px"}}>
                    <button onClick={() => {
                        setRefreshModal(true); 
                        if(props.setAddingPostFromGraph){
                            props.setAddingPostFromGraph(false);
                        }
                        window.location.href = "#!"}} 
                        style={{position:"absolute", right:"5px", top:"20px", fontWeight:"bolder", border:"none", fontSize:"1.2rem"}}>X</button>
                </div>
                <NewPost
                    showCongrats={props.showCongrats}
                    triggerPost={props.triggerPost} 
                    setAddingPost={props.setAddingPost}
                    refreshJourney={props.refreshJourney} 
                    setTriggerPost={props.setTriggerPost}
                    edgeID={props.edgeID} 
                    nodeID={props.nodeID} 
                    postText={props.postText} 
                    journeyID={props.journeyID} 
                    journeyTitle={props.journeyTitle} 
                    refreshModal={refreshModal} 
                    setRefreshModal={setRefreshModal}/>
            </div>
        </div>                
    )
}

export default NewPostModal;
