import PDF from "./PDF";

function PrivacyPolicy(props){
    return (
        <div className="modal modal-animated--zoom-in" id="privacyPolicy" style={{backgroundColor:"rgb(0,0,0,0.5)", zIndex:2000}}>
        <PDF filePath={"https://skyreachers-public.s3.ap-southeast-2.amazonaws.com/PrivacyPolicy.pdf"}></PDF>
        </div>
    )
}   

export default PrivacyPolicy;