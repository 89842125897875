import React from "react";
import '../../Styles/LogInToggle.css';
import { Link } from "react-router-dom";

function LogInToggle(props){
    return (
    <div className='u-center toggle-button-wrapper'>
        <div className='u-center' style={{width:"200px"}}>
          <Link to={"/auth/login"} style={{padding:0}}>
            <div className={`toggle-button log-in-toggle ${props.IsLogIn ? "active-toggle-button": ""}`}>
                Log in 
            </div>
          </Link>
          <Link to={"/auth/signup"} style={{padding:0}}>
          <div  className={`toggle-button sign-up-toggle ${!props.IsLogIn ? "active-toggle-button": ""}`}>
            Sign Up
          </div>
          </Link>
        </div>
    </div>
    )
}

export default LogInToggle;