import React from 'react'
import '../Styles/ProgressBar.css'
  
const ProgressBar = (props) => {
             
    return (
    <div className="Parent-Div" style={{height: props.height}}>
      <div className="Child-Div" style={{width: `${props.progress}%`, backgroundColor: props.bgColor}}>
      </div>
    </div>
    )
}
  
export default ProgressBar;