import React from 'react';
import { Outlet, useLocation} from 'react-router-dom';
import mnt from '../../Images/ClimbMtn.png';
import mntTbl from '../../Images/ClimbMtnTbl.png';
import dreamer from '../../Images/Dreamer.png';
import dreamerTbl from '../../Images/DreamerTbl.png';
import Coach from  '../../Images/Coach.png';
import CoachTbl from  '../../Images/CoachTbl.png';
import Logo from "../../Images/Logo.png";
import "../../Styles/Login.css";




function AuthScreen(props){
  const { pathname } = useLocation()

  return(
  <div className="u-flex row">
    <div>
      <img className="pc-img" src={pathname.includes("SignUp") ? dreamer: (pathname.includes("Login") ? mnt : Coach)}></img>
      <img className ="tbl-img" src={pathname.includes("SignUp") ? dreamerTbl: (pathname.includes("Login") ? mntTbl : CoachTbl)}></img>
    </div>
      <div className='outer-column'>
        <div id="AuthForms" className="column-wrapper">
            <div className="login-column" style={{height : pathname.includes("SignUp") ? "unset": "100%"}}>
                <div className="login-title">
                      <div className="u-center">
                        <h4 className="title">
                          <img src={Logo} className="LoginScreenLogo" alt=""/>
                        </h4>
                        <h1 className="skyreachers-title">SKYREACHERS</h1>
                      </div>
                      <div className="u-center">
                        <h5 className="skyreachers-title-line">Combining the Best of&nbsp;<div style={{color: "#794585"}}>Humanity</div>&nbsp;and&nbsp;<div style={{color: "#4C7E83"}}>AI</div>
                        </h5>
                      </div>
                </div>
                <Outlet/>
          </div>
        </div>
    </div>
  </div>);
}

export default(AuthScreen);
