import React  from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { useLocation } from 'react-router-dom';
import JourneysList from "./JourneysList"
import '../../../Styles/Journeys/JourneyList.css';


function JourneysListWrapper(props)
{
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    var ownerID = (searchParams.get("ownerID"));
    var ownerType = (searchParams.get("ownerType"));
    
    return <div>
                <JourneysList ownerID={ownerID} ownerType={ownerType}/>
            </div>
}

function mapStateToProps(state)
{
  return {user: state.user};
}

export default connect(mapStateToProps, actions)(JourneysListWrapper);
