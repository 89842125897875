import { GET_FEED } from '../actions/types'

export default function getFeed(state = null, action)
{
  switch(action.type)
  {
    case GET_FEED:
      return action.payload || false;

    default:
      return state;
  }
}
