import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import "rc-tooltip/assets/bootstrap.css";
import Axios from "axios";
import  { fetchAuthSession } from"aws-amplify/auth";


function JourneyDelete(props) {
    const onDelete = async () => { 
        props.isLoading(true)
        try{
            const body = {
                id: props.id
            }
           const { idToken } = (await fetchAuthSession()).tokens ?? {};
            let config = {headers: {"Authorization": idToken.toString()}};

            await Axios.post(process.env.REACT_APP_APIURL + '/DeleteJourney', body, config);
            window.location.href = "/"
        }
        catch(e){
            console.log(e)
        }
        finally{
            props.isLoading(false)
        }
    }

    return (
        <div
            className="modal modal-animated--zoom-in"
            id="deleteJourney"
            style={{ backgroundColor: "rgb(0,0,0,0.5)", zIndex: 2000 }}
        >
            <div
                className="modal-content"
                role="document"
                style={{ width: "-webkit-fill-available", backgroundColor: "rgba(0,0,0,0)", boxShadow: "none" }}
            >
                <div className="card">
                    <div aria-label="Close" onClick={() => { window.location.href = "#!" }}>
                        <span className="icon u-position-relative u-pull-right" style={{ margin: "3px" }}>
                            <i className="fa-wrapper fa fa-times" />
                        </span>
                    </div>
                    <div className="u-center" style={{ marginTop: "16px" }}>
                        <h5>Delete Journey {props.title}</h5>
                    </div>
                    <hr />
                    <div style={{ textAlign: "center", padding: "12px" }}>
                        Are you sure you would like to delete this journey? This action is irreversible and will delete all attached posts as well as your milestones.
                    </div>
                    <div className="u-center" style={{ width: "100%" }}>
                        <button className="btn-info btn-small" style={{ marginLeft: "0%", textTransform: "none", backgroundColor: "red !important", marginRight:"12px" }} onClick={() => window.location.href = "#!"}>
                            <b>Cancel</b>
                        </button>
                        <button className="btn-danger btn-small" style={{ marginLeft: "0%", textTransform: "none", backgroundColor: "red !important" }} onClick={onDelete}>
                            <i className="fa fa-trash fa-lg" style={{ marginRight: "4px" }}></i>
                            <b>Confirm Deletion</b>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return { user: state.user };
}

export default connect(mapStateToProps, actions)(JourneyDelete);
