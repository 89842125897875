import React, { useEffect, useState , useRef} from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import '../../Styles/JourneyDropdown.css'
import Axios from 'axios';
import  { fetchAuthSession } from"aws-amplify/auth";
import { v4 } from 'uuid';

function JourneyDropdown(props) {

  const isMounted = useRef();

  useEffect(() => {
      isMounted.current = true;
      return () => {
      isMounted.current = false;
      };
  }, []);
    
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(-1);
  const [journeysList, setJourneysList] = useState(null);
  const [noJourneys, setNoJourneys] = useState(false);


  useEffect(() => {
    setIsOptionsOpen(false);
  },[props.location.href]);

  const getAllJourneys = async () => {
   const { idToken } = (await fetchAuthSession()).tokens ?? {};
    let config = {headers: {"Authorization": idToken.toString()}};
    const response = await Axios.get(process.env.REACT_APP_APIURL + "/GetJourney?getAllMyJourneys=true&status=active&v=" + v4(), config);
    if(isMounted.current){
      setJourneysList(response.data)
    }
    if (!(response.data && response.data.length>0 && isMounted.current)){
      setNoJourneys(true);
    }
  }
  
  useEffect(() => {
    if (journeysList && selectedOption!==-1){
      props.setSelectedJourney(journeysList[selectedOption])
    }
  }, [selectedOption, journeysList])

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen)
    if (journeysList===null){
      getAllJourneys()
    }
  }

  const select = (index) => {
    setSelectedOption(index);
    setIsOptionsOpen(false);
  }


  return (
    <div className="wrapper" style={{width:"100%"}}>
      <div className="container">
        <button
          className="postInput"
          type="button selectJourney"
          aria-haspopup="listbox"
          aria-expanded={isOptionsOpen}
          style={{width:"100%"}}
          onClick={!props.journeyTitle? toggleOptions: () => {return}}
        >
          <div style={{marginBottom:"0"}}><b>
            {props.journeyTitle? props.journeyTitle
            
            : (selectedOption===-1 || journeysList===null)? 
                "Journey "
                : journeysList[selectedOption].Title+" "} 
            {props.journeyTitle? "":
               isOptionsOpen && !noJourneys? 
                <i className="fas fa-caret-up" style={{position:"absolute", right:"15px", top:"18px"}}></i> 
                : <i className="fas fa-caret-down" style={{position:"absolute", right:"15px", top:"18px"}}></i>}
          </b></div>
        </button>
        <ul
          className={`options ${isOptionsOpen ? "show" : ""}`}
          role="listbox"
          aria-activedescendant={selectedOption===-1? noJourneys? "Create a journey":"":journeysList[selectedOption]}
          tabIndex={-1}
        >
          {journeysList? journeysList.map((journey, index) => (
            <li
              tabIndex={0}
              id={journey.Title}
              key={index.toString()}
              role="option"
              aria-selected={selectedOption === index}
              onClick={() => {
                select(index)
              }}
              style={{cursor:"pointer", display:"block", width:"100%"}}
            >
              {journey? journey.Title:""}
            </li>
          )):""}
        </ul>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return { user: state.user, feed: state.feed };
}

export default connect(mapStateToProps, actions)(JourneyDropdown);

