import React from 'react';
import '../../../Styles/Journeys/JourneyTag.css'; 
import { Link } from "react-router-dom";
import ProgressBar from '../../ProgressBar';

function JourneyTag(props){ 

  const titleLink = "/journeys?id=" + props.id;
  
  return (
        <div className="journey-link-container">
          <div className="row" style={{height:"80px"}}>
            <Link className="journey-link" to={titleLink}>
              <div style={{fontSize:"large"}}> <b>{props.title} </b></div>
              </Link>
          </div>
        <div className='row' style={{height:"30px", alignItems:"center"}}>
          Goal &nbsp; <span className='far fa-check-circle'></span>
        </div>
        <div className='row' style={{height:"10px", alignItems:"center", width: "100%",justifyContent:"center"}}>
          <ProgressBar height="10px" progress={props.progress} bgColor="#F18B81"/>
        </div>
      </div>)
}

export default JourneyTag;