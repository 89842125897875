import React, { useState, useEffect, useRef } from 'react';
import hark from 'hark';
import { SpeechRecognition } from "@capacitor-community/speech-recognition";
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { createClient, LiveTranscriptionEvents } from "@deepgram/sdk";
import Axios from 'axios';
import { v4 } from "uuid";
import { fetchAuthSession } from "aws-amplify/auth";

const AudioRecorder = (props) => {
    //This component handles everything related to parsing speech directed as Skye
    //This includes deepgram to handles speech-to-text, hark to determine when text stops and mediarecorder itself

    const isMounted = useRef();
    const [deepgram, setDeepgram] = useState(null);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        const establilshDeepGramClient = async () => {
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
            let config = { headers: { "Content-Type": "application/json", "Authorization": idToken.toString() } };
            var response = await Axios.get(process.env.REACT_APP_APIURL + "/GenerateDeepgramAPIKey?v=" + v4(), config);

            console.log(response)
            if (response.data) {
                setDeepgram(createClient([response.data.key]))
            }

        }

        establilshDeepGramClient()
    }, [])

    const [permissionError, setPermissionError] = useState(false)


    //For locking the submission of text until transcription is resolved.
    const transcriptGateRef = useRef(false);
    const sendIntervalGateRef = useRef(false);
    const transcriptRef = useRef("");


    useEffect(() => {
        var speech;
        var recorder;
        var deepgramClient;

        var recorderStartDelayInterval;
        var checkInterval;

        const errorRecording = (error) => {
            if (isMounted.current) {
                props.setListening(false)
                props.setTranscriptionError(true)
                console.log(error)
            }
        }

        const processTranscript = (data) => {
            transcriptRef.current += " " + data.channel.alternatives[0].transcript;
            if (transcriptGateRef.current) {
                transcriptGateRef.current = false;
            }
        }

        const setUpTranscripts = () => {
            deepgramClient.on(LiveTranscriptionEvents.Transcript, processTranscript)
        };

        if (deepgram) {
            const establishTranscriptionWebsocket = () => {
                deepgramClient = deepgram.listen.live({ model: "nova" })

                console.log("setting up deepgram")

                deepgramClient.on(LiveTranscriptionEvents.Metadata, errorRecording)

                deepgramClient.on(LiveTranscriptionEvents.Open, setUpTranscripts)

                deepgramClient.on(LiveTranscriptionEvents.Error, errorRecording);
            }

            const pauseListening = () => {

                if (speech) {
                    props.setListening(false)
                    speech.off("stopped_speaking")
                    speech.stop();
                }

                if (recorder) {
                    recorder.stop();
                    recorder.stream.getTracks().forEach(track => track.stop());
                }
            }


            const setRecorder = (stream) => {

                //Transcription has to be started before the mediaRecorder otherwise there is an error with no packages being returned.
                //This is due to metadata from the first audio blob needed to start the transcription. 
                establishTranscriptionWebsocket();

                var options; 

                if (MediaRecorder.isTypeSupported('audio/webm; codecs=opus')) {
                    options = { mimeType: 'audio/webm; codecs=opus' };
                     console.log("webm mimetype")
                } else if (MediaRecorder.isTypeSupported('audio/webm')) {
                    options = { mimeType: 'audio/webm' };
                     console.log("webm mimetype")
                } else if (MediaRecorder.isTypeSupported('audio/mp4')) {
                    options = { mimeType: 'audio/mp4'};
                    console.log("mp4 mimetype")
                } else {
                    console.log("no suitable mimetype found for this device");
                }

                recorder = new MediaRecorder(stream, options);

                console.log('setting recorder');

                recorder.ondataavailable = async (e) => {
                    if (isMounted.current && deepgramClient) {
                        if (deepgramClient.getReadyState() === 1) {
                            if(e.data.size > 0){
                                deepgramClient.send(e.data);
                            }
                        }
                    }
                }

                recorderStartDelayInterval = setInterval(() => {
                    if (deepgramClient.getReadyState() === 1) {
                        console.log("starting recorder")
                        recorder.start(100)
                        setReady(true)
                        props.setTranscriptionError(false)
                        clearInterval(recorderStartDelayInterval)
                    }
                }, [50])
            }


            const GetHarkInstance = async (stream) => {

                if (speech === null || speech === undefined) {

                    // Also pass the stream to hark to create speaking events
                    speech = hark(stream, { setInterval: 300, setThreshold: -20 });

                    console.log("setting Hark")

                    speech.on("stopped_speaking", () => {
                        console.log("stopped Speaking")
                        transcriptGateRef.current = true;
                        sendIntervalGateRef.current = true;
                    });

                    App.addListener('pause', pauseListening)
                }
            }
            const requestMobilePermission = async () => {


                //for WebApp Checking
                const isNative = Capacitor.isNativePlatform();

                var status;

                if (isNative) {
                    // Check if speech recognition is available
                    await SpeechRecognition.available();
                    // Request microphone permissions
                    const perm = await SpeechRecognition.requestPermissions();
                    status = perm.speechRecognition
                }

                if (status === 'granted') {
                    // Permission granted, you can start recording
                    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                        navigator.mediaDevices.getUserMedia({ audio: true })
                            .then(stream => {
                                if (isMounted.current) {
                                    setRecorder(stream);
                                    GetHarkInstance(stream);
                                }
                            })
                            .catch(err => console.log(err));
                    }
                } else {
                    setPermissionError(true);
                }
            };

            const GetPermission = () => {
                if (isMounted.current) {
                    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                        navigator.mediaDevices.getUserMedia({ audio: true })
                            .then(stream => {

                                if (isMounted.current) {
                                    setRecorder(stream);
                                    GetHarkInstance(stream);
                                }
                            })
                            .catch(err => {
                                console.log(err)
                                requestMobilePermission()
                            });
                    }
                }
            }

            GetPermission();

            checkInterval = setInterval(() => {
                if (!transcriptGateRef.current && sendIntervalGateRef.current) {
                    sendIntervalGateRef.current = false;
                    props.setListening(false);
                    console.log(transcriptRef.current)
                    props.sendMessage(transcriptRef.current, true);
                    transcriptRef.current = "";
                }
            }, 100)
        }

        return () => {
            try {

                App.removeAllListeners()
                clearInterval(checkInterval);
                clearInterval(recorderStartDelayInterval);
                props.setTranscriptionError(false);


                if (speech) {
                    props.setListening(false);
                    speech.off("stopped_speaking")
                    speech.stop();
                    speech = null;
                }

                if (recorder) {
                    recorder.stop();
                    recorder.stream.getTracks().forEach(track => track.stop());
                    recorder = null;
                }

                if (deepgramClient) {
                    deepgramClient.off(LiveTranscriptionEvents.Open, setUpTranscripts)
                    deepgramClient.off(LiveTranscriptionEvents.Transcript, processTranscript)
                    deepgramClient.off(LiveTranscriptionEvents.Error, errorRecording)
                    deepgramClient.finish();
                    deepgramClient = null;
                }
            }
            catch (e) {
                console.log(e)
            }
        }
    }, [deepgram]);

    return (
        <div style={{ marginBottom: "16px", height: "50px" }}>
            {!permissionError ? (ready ?
                <div className='u-flex'>
                    <div className="skye-alert skye-listening">
                        <div style={{ position: "absolute", top: "-4px", left: "-5px" }}>
                            <div className="ring-container">
                                <div className="ringring"></div>
                                <div className="circle"></div>
                            </div>
                        </div>
                        Skye is Listening
                    </div>
                </div> :
                <div className='u-flex'>
                    <div className="skye-alert skye-error">
                        Please wait one moment for Skye.
                    </div>
                </div>
            )
                :
                <div className='u-flex'>
                    <div className="skye-alert skye-error">
                        Error: Skye cannot detect your microphone.
                    </div>
                </div>
            }
        </div>
    )
}

export default AudioRecorder;