import React, { useState, useEffect, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useLocation } from 'react-router-dom';
import { withErrorBoundary } from "react-error-boundary";

function PDF(props) {
    const isMounted = useRef();

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [modalDiv, setModalDiv] = useState(null);
    const [docWidth, setDocWidth] = useState(0);

    const location = useLocation();
    const closeLink = location.pathname.toLowerCase().includes("/auth/") ? "#AuthForms" : "#!";


    function onDocumentLoadSuccess({ numPages }) {
        if (isMounted.current) {
            setNumPages(numPages);
        }
    }

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    }, []);

    useEffect(() => {
        if (modalDiv != null && isMounted.current) {
            setDocWidth(modalDiv.offsetWidth);
        }
    }, [modalDiv]);


    return (
        <div className="modal-content" ref={setModalDiv} role="document" style={{ width: "100%", height: "auto" }}>
            <a href={closeLink} aria-label="Close" style={{ position: "absolute", top: 0, right: 12, zIndex: 2500, overflow: "none" }}>
                <span className="icon u-position-relative u-pull-right">
                    <i className="fa-wrapper fa fa-times" />
                </span>
            </a>
            <div style={{ maxHeight: "85vh", overflowY: "scroll", overflowX: "clip" }}>
                {props.filePath ?
                    <Document file={{ url: props.filePath }} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber} width={docWidth} renderTextLayer={false} renderAnnotationLayer={false} />
                    </Document> : ""
                }
            </div>
            <div className="u-flex u-center">
                {pageNumber !== 1 ?
                    <button className="btn-small btn-transparent" onClick={() => setPageNumber(pageNumber - 1)}>
                        <span className="icon"><i className="fa fa-less-than"></i></span>
                    </button> : <button className="btn-small btn-transparent">
                        <span className="icon" style={{ color: "white" }}><i className="fa fa-less-than"></i></span>
                    </button>}
                <p style={{ diplay: "inline-block" }}>
                    Page {pageNumber} of {numPages}
                </p>
                {pageNumber !== numPages ?
                    <button className="btn-small btn-transparent" onClick={() => setPageNumber(pageNumber + 1)}>
                        <span className="icon"><i className="fa fa-greater-than"></i></span>
                    </button> : <button className="btn-small btn-transparent">
                        <span className="icon" style={{ color: "white" }}><i className="fa fa-greater-than"></i></span>
                    </button>}
            </div>
        </div>
    )
}

export default withErrorBoundary(PDF,
  {
    FallbackComponent: () => <div></div>,
    onError: (error) => { console.log(error) },
    resetKeys: [window.location.pathname]
  });
