import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const { pathname } = useLocation();

  const elementsWithOverflow = document.querySelectorAll('.content-column, .pc-column');

  //Loop through the selected elements
  for (let i = 0; i < elementsWithOverflow.length; i++){
    // Do something with each element, for example, log its ID
    elementsWithOverflow[i].scrollTo(0,0)
  }

  return null;
}

export default ScrollToTop;