import React, { useState, useEffect, useRef } from 'react';
import '../../Styles/UpcomingMilestones.css';
import { fetchAuthSession } from 'aws-amplify/auth';
import Axios from 'axios';
import { v4 } from 'uuid';


const UpcomingMilestones = () => {

    const isMounted = useRef();

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    const [milestoneList, setMilestoneList] = useState([])

    useEffect(() => {
        const getMilestones = async () => {
            try {
                const { idToken } = (await fetchAuthSession()).tokens ?? {};
                let config = { headers: { Authorization: idToken.toString() } };
                const response = await Axios.get(
                    process.env.REACT_APP_APIURL + "/GetUpcomingMilestones?v=" + v4(),
                    config)
                if (response.data && isMounted.current) {
                    setMilestoneList(response.data)
                }
            }
            catch (e) {
                console.log(e)
            }
        }

        getMilestones()
    }, [])

    const formatDate = (epochTime) => {
        const date = new Date(epochTime);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        let suffix = 'th';
        if (day === 1 || day === 21 || day === 31) suffix = 'st';
        else if (day === 2 || day === 22) suffix = 'nd';
        else if (day === 3 || day === 23) suffix = 'rd';
        return `${day}${suffix} ${month} ${year}`;
    };

    return (
        <div>
            <div className="small-screen-list">
                <div className="milestone-list-title-mobile">
                    <b className="milestone-list-title">Upcoming Milestones</b>
                </div>
                <div>
                    <div className="u-flex milestone-list-wrapper">
                        {milestoneList.map((x, i) => <div key={"milestone:" + i}>
                            <div className="col-12" style={{ float: "left", marginTop: "1%", marginBottom: "1%", padding: 0, marginRight: "32px" }}>
                                <div className="milestone-item-container">
                                    <div className="milestone-description">
                                        &nbsp; {x.MilestoneLabel}
                                    </div>
                                    <div className="milestone-date">{formatDate(x.TargetDate ? x.TargetDate : 4102401600000)}</div>
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>
            <div className='u-center large-screen-list'>
                <div className="milestone-list-container">
                    <div className='u-center '>
                        <b className='milestone-list-title'> Upcoming Milestones</b>
                    </div>
                    <div className="milestone-list-divider" />
                    <div style={{ margin: "2%" }}>
                        {milestoneList.map((x, i) =>
                            <div key={"milestone:" + i} className="milestone-list-item">
                                <div className="milestone-description">
                                    &nbsp; {x.MilestoneLabel}
                                </div>
                                <div className="milestone-date">{formatDate(x.TargetDate)}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpcomingMilestones;