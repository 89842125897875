import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import Axios from 'axios';
import  { fetchAuthSession } from"aws-amplify/auth";
import { Link } from 'react-router-dom';
import { v4 } from 'uuid';
import TouchDeviceHeader from '../TouchDeviceHeader';

function InviteList(props) {
    const isMounted = useRef();

    useEffect(() => {
        isMounted.current = true;
        return () => {
        isMounted.current = false;
        };
    }, []);

    const [invites, setInvites] = useState([]);
    const [invitesRetreived, setInvitesRetrieved] = useState(false);

    const isLoading = props.isLoading;

    useEffect(() => {

        const renderContent = () => {
            isLoading(true);

            const getInvites = async () => {
               const { idToken } = (await fetchAuthSession()).tokens ?? {};
                let config = {headers: {"Authorization": idToken.toString()}};
                const result = await Axios.get(process.env.REACT_APP_APIURL + '/GetInvites?v=' + v4(), config);

                if(isMounted.current){
                    setInvites(result.data);

                    
    
                    setInvitesRetrieved(true);
                }
               
                isLoading(false);
            };

            if (!invitesRetreived) { getInvites(); }
            else
                isLoading(false);
        }

        renderContent();
    }
        //including invites as recommended by eslint here will cause the function to be called twice.
        // eslint-disable-next-line
        , [invitesRetreived, isLoading]);

    const respondToInvite = async (accept, inviteID) => {
        props.isLoading(true);
       const { idToken } = (await fetchAuthSession()).tokens ?? {};
        const body =
        {
            accept: accept,
            inviteID: inviteID
        };
        let config = {headers: {"Authorization": idToken.toString()}};
        try {
            await Axios.post(process.env.REACT_APP_APIURL + '/JoinCommunity', body, config);
        }
        catch (e) {
            console.log(e);
        }
        finally {
            props.isLoading(false);
            window.location.reload(true);
        }
    }

    

    /* Old Community Invites
    {invites.map((x) =>
        <div key={x.InviteID}>
            <div className="u-center" style={{margin:"2%"}}>
                <p><Link to={`/profile/${x.InviterID}`}>{x.InviterDisplayName}</Link> has invited you to join the community: <Link to={`/communities?id=${x.CommunityID}`}>{x.CommunityDisplayName}</Link></p>
            </div>
            <div style={{display:"flex", width:"60%", marginLeft: "20%", justifyContent:"space-around"}}>
                <button className="btn-info btn-small" onClick={() => respondToInvite(true, x.InviteID) }>Accept</button><button className="btn-danger btn-small" onClick={() => respondToInvite(false, x.InviteID)}>Decline</button>
            </div>
            <hr/>
        </div>
        )}
    */

    const getTimeSince = (time) => {
            const now = new Date().getTime();
            const timeDifference = now - time;
            const secondsInMillisecond = 1000;
            const minutesInMillisecond = secondsInMillisecond * 60;
            const hoursInMillisecond = minutesInMillisecond * 60;
            const daysInMillisecond = hoursInMillisecond * 24;
            const weeksInMillisecond = daysInMillisecond * 7;
            const monthsInMillisecond = daysInMillisecond * 30.44; // Approximate days in a month
            const yearsInMillisecond = daysInMillisecond * 365.25; // Approximate days in a year
        
            if (timeDifference < minutesInMillisecond) {
              return "less than 1 minute ago";
            } else if (timeDifference < hoursInMillisecond) {
              const minutes = Math.floor(timeDifference / minutesInMillisecond);
              return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
            } else if (timeDifference < daysInMillisecond) {
              const hours = Math.floor(timeDifference / hoursInMillisecond);
              return `${hours} hour${hours > 1 ? 's' : ''} ago`;
            } else if (timeDifference < weeksInMillisecond) {
              const days = Math.floor(timeDifference / daysInMillisecond);
              return `${days} day${days > 1 ? 's' : ''} ago`;
            } else if (timeDifference < monthsInMillisecond) {
              const weeks = Math.floor(timeDifference / weeksInMillisecond);
              return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
            } else if (timeDifference < yearsInMillisecond) {
              const months = Math.floor(timeDifference / monthsInMillisecond);
              return `${months} month${months > 1 ? 's' : ''} ago`;
            } else {
              const years = Math.floor(timeDifference / yearsInMillisecond);
              return `${years} year${years > 1 ? 's' : ''} ago`;
            }
    }

    return (
        <div className="u-center">
            <TouchDeviceHeader title={`Notifications (${invites.length})`} setShow={props.setShow}/>
            <div className="card notif-card">
                <div className="card-internal-header" style={{ margin: "2%", textAlign: "center" }}>
                    <h2 className="u-center">Notifications ({invites.length})</h2>
                </div>
                {invites.map((x, i) =>
                    <div key={i} style={{position:"relative", borderTop:"1px solid #676767"}}>
                        <div className="u-center" style={{ margin: "30px" }}>
                            <p><Link to={`${x.link ? x.link : "/"}`}>{x.text}</Link></p>
                        </div>
                        <div style={{position:"absolute", bottom:"-20px", right:"20px"}}>
                            {getTimeSince(x.date)}
                        </div>
                    </div>)
                }
            </div>
        </div>)
}

function mapStateToProps() {
    return {}
}

export default connect(mapStateToProps, actions)(InviteList);
