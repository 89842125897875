export const defaultNodes = [
        {
          id: '0',
          data: {
            label: "The Beginning", id: '0', description:''
          },
          type: 'beginningNode',
          position: { x: 0, y: 100},
          sourcePosition: 'right',
        },
        {
          id: '1',
          data: {
            label: "Milestone 1", id: '1', description:''
          },
          position: { x: 250, y: 200 },
          type:  'milestoneNode',
          sourcePosition: 'right',
          targetPosition: 'left',
        },
        {
          id: '2',
          data: {
            label: "Milestone 2", id: '2', description:''
          },
          position: { x: 500, y: 100 },
          type:  'milestoneNode',
          sourcePosition: 'right',
          targetPosition: 'left',
         
        },
        {
          id: '3',
          data:{
          label: "Milestone 3", id: '3', description:''
          },
          position: { x: 750, y: 0 },
          type:  'milestoneNode',
        },
        {
          id: '00',
          type: 'goalNode',
          data: {
            label: "The Goal", id:'00', description:''
          },
          position: { x: 1000, y: 100 },
          targetPosition: 'left',
        },
    ];

export const defaultEdges = [
    { id: 'e0-1',
      source: '0',
      target: '1',
      type: "PebbleEdge"
    },
    { 
      id: 'e1-2', 
      source: '1', 
      target: '2' ,
      type: "PebbleEdge"
    },
    {
      id: 'e2-3',
      source: '2',
      target: '3',
      type: "PebbleEdge"
    },
    {
      id: 'e4-00',
      source: '3',
      target: '00',
      type: "PebbleEdge"
    },
  ];
