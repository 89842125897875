import React, { useEffect, useState, useRef } from 'react';
import { Route, useLocation, Routes, useNavigate, Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';
import '../Styles/App.css';
import { withErrorBoundary } from "react-error-boundary";
import NavBar from './NavBar';
import AuthScreen from './Auth/AuthScreen';
import Profile from './UserContent/Profile';
import LoadingSpinner from './LoadingSpinner';
import JourneyWrapper from './UserContent/Journeys/JourneyWrapper';
import NewJourney from './UserContent/Journeys/NewJourney';
import JourneysListWrapper from './UserContent/Journeys/JourneysListWrapper';
import NewsFeed from './NewsFeed';
import ErrorMessage from './ErrorMessage';
import logError from './LogError';
import InviteList from './UserContent/InviteList';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './Auth/TermsOfService';
import HowToGuide from './UserContent/HowToGuide';
import Home from './Home';
import { StatusBar, Style } from '@capacitor/status-bar';
import { PushNotifications } from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';
import { Capacitor } from '@capacitor/core';
import Axios from 'axios';
import Footer from './Footer';
import ScrollToTop from './ScrollToTop';
import SideBar from './Sidebar';
import Skye from './Skye/Skye';
import Settings from './Settings';
import WrappedSubscriptionPage from './ChooseSubscription';
import Contact from './Contact';
import CloudPopup from './CloudPopup';
import ConfirmEmail from './Auth/ConfirmEmail';
import EmailPending from './Auth/EmailPending';
import ForgotPassword from './Auth/ForgotPassword';
import ResetPassword from './Auth/ResetPassword';
import Login from './Auth/Login';
import SignUp from './Auth/SignUp';


function App(props) {

  const isMounted = useRef();

  let navigate = useNavigate();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const location = useLocation();

  //To avoid people getting stuck in loading loops if something goes wrong
  const isLoading = props.isLoading;

  //for WebApp Checking
  const isNative = Capacitor.isNativePlatform();

  const { pathname } = useLocation();



  useEffect(() => {
    if (isNative) {
      PushNotifications.checkPermissions().then((res) => {
        if (res.receive !== 'granted') {
          PushNotifications.requestPermissions().then((res) => {
            if (res.receive === 'denied') {
              ;
            }
            else {
              ;
              register();
            }
          });
        }
        else {
          register();
        }
      });
    }
  }, [isNative])

  const [isIOS, setIsIOS] = useState(false)

  useEffect(()  => {
      const adjustAppHeight = async() => {
        const deviceInfo = await Device.getInfo();
        if(deviceInfo.operatingSystem === "ios"){
          document.body.style.marginTop = "50px";
          setIsIOS(true)
        }
      }

      adjustAppHeight()
  }, [])

  useEffect(() => {
    if (isNative) {
      // iOS only
      window.addEventListener('statusTap', function () {
        ;
      });

      // Display content under transparent status bar (Android only)
      StatusBar.setOverlaysWebView({ overlay: false });

      StatusBar.setBackgroundColor({ color: "#FFFFFF" });

      StatusBar.setStyle({ style: Style.Light });
    }
  }, [isNative])

  const register = async () => {

    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register();

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      async (token) => {
        const id = await Device.getId();
        const body = {
          token: token,
          deviceID: id
        };
        try {

          await Axios.post(process.env.REACT_APP_APIURL + '/LinkDeviceToFCMToken', body);
        }
        catch (e) {
          ;
        }
      }

    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error) => {
        alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification) => {

      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification) => {

        if (notification.notification.data.targetPage) {
          window.location.href = notification.notification.data.targetPage
        }
      }
    );
  }

  const [contentHeight, setContentHeight] = useState(0);
  const [currentWidth, setCurrentWidth] = useState(0);
  const [currentHeight, setCurrentHeight] = useState(0);
  const [sidebarShowing, setSidebarShowing] = useState(false);






  useEffect(() => {



    const resizeFunction = () => {
      if (currentHeight !== window.innerHeight && currentWidth !== window.innerWidth) {
        setCurrentHeight(window.innerHeight)
        setCurrentWidth(window.innerWidth)

      }
    }

    addEventListener("resize", resizeFunction)

    return () => {
      removeEventListener("resize", resizeFunction)
    }
  }, [])

  useEffect(() => {

    if (isMounted.current) {
      setSidebarShowing(false)
    }
  }, [location.pathname])

  const [homeMenuHeight, setHomeMenuHeight] = useState("1000px")
  const [redirectComponent, setRedirectComponet] = useState("");




  useEffect(() => {
    if (isMounted.current) {
      if (props.user) {

        if (props.user.subscription === undefined && !location.pathname.includes("/auth/")) {

          navigate("/subscription")
          props.isLoading(false)
        }
        else if (props.user.BrandNewUser && !location.pathname.includes("/auth/")) {

          window.location.href = "?context=welcome#CloudPopup"
          props.isLoading(false)
        }
        else if (location.pathname.includes("/auth/")) {

          navigate("/")
          props.isLoading(false)
        }
        else {
          props.isLoading(false)
        }
      }
      else {
        if (!location.pathname.includes("/auth/") && !location.pathname.toLowerCase().includes("/contact")){
          navigate("/auth/login")
        }
      }
    }
  }, [props.user])

  useEffect(() => {
    const screenHeight = window.innerHeight;
    const screenWidth = window.innerWidth;
    setHomeMenuHeight(screenHeight - 64 + 16 - (screenWidth < 1024 ? 0 : 86) - (isIOS ? 50 : 0))

    setContentHeight()

  }, [currentWidth, currentHeight, pathname, isIOS])

  return (
    <div className="container">
      <div>
        {redirectComponent}
        <TermsOfService></TermsOfService>
        <PrivacyPolicy></PrivacyPolicy>
        <CloudPopup></CloudPopup>
        {props.Loading ? <LoadingSpinner></LoadingSpinner> : ''}
        <NavBar setSidebarShow={setSidebarShowing} />
        <SideBar show={sidebarShowing} setShow={setSidebarShowing} />

        <ScrollToTop />
        <Routes>
          <Route path="/auth/*" element={<AuthScreen />}>
            <Route path="SignUp" element={<SignUp />} />
            <Route path="Login" element={<Login />} />
            <Route path="EmailPending" element={<EmailPending source={"SignUp"} />} />
            <Route path="ConfirmEmail" element={<ConfirmEmail />} />
            <Route path="ForgotPassword" element={<ForgotPassword />} />
            <Route path="ResetPassword" element={<ResetPassword />} />
          </Route>
          <Route path="/subscription" element={<WrappedSubscriptionPage />} />
          <Route path="/" element={
            <div className="header-filler">
              <div className="container content-column" style={{ height: `${homeMenuHeight}px` }}>
                <Home setShow={setSidebarShowing} />
                <Footer heightMargin={contentHeight} hideDisplay={props.user === null || props.user === false}></Footer>
              </div>
            </div>
          } />
          <Route path="/feed" element={
            <div className="header-filler">
              <div className="container content-column" style={{ height: `${homeMenuHeight}px` }}>
                <NewsFeed setShow={setSidebarShowing} />
                <Footer heightMargin={contentHeight} hideDisplay={props.user === null || props.user === false}></Footer>
              </div>
            </div>
          } />
          <Route path="/profile/*" element={
            <div className="header-filler">
              <div className="container content-column" style={{ height: `${homeMenuHeight}px` }}>
                <Profile />
                <Footer heightMargin={contentHeight} hideDisplay={props.user === null || props.user === false}></Footer>
              </div>
            </div>
          } />
          <Route path="/JourneysList" element={
            <div className="header-filler">
              <div className="container content-column" style={{ height: `${homeMenuHeight}px` }}>
                <JourneysListWrapper />
                <Footer heightMargin={contentHeight} hideDisplay={props.user === null || props.user === false}></Footer>
              </div>
            </div>
          } />
          <Route path="/Journeys" element={
            <div className="header-filler">
              <div className="container content-column" style={{ height: `${homeMenuHeight}px` }}>
                <JourneyWrapper setShow={setSidebarShowing} />
                <Footer heightMargin={contentHeight} hideDisplay={props.user === null || props.user === false}></Footer>
              </div>
            </div>
          } />
          <Route path="/NewJourney" element={
            <div className="header-filler">
              <div className="container content-column" style={{ height: `${homeMenuHeight}px` }}>
                <NewJourney setShow={setSidebarShowing} />
                <Footer heightMargin={contentHeight} hideDisplay={props.user === null || props.user === false}></Footer>
              </div>
            </div>
          } />
          <Route path="/Invites" element={
            <div className="header-filler">
              <div className="container content-column" style={{ height: `${homeMenuHeight}px` }}>
                <InviteList setShow={setSidebarShowing} />
                <Footer heightMargin={contentHeight} hideDisplay={props.user === null || props.user === false}></Footer>
              </div>
            </div>
          } />
          <Route path="/HowToGuide" element={
            <div className="header-filler">
              <div className="container content-column">
                <HowToGuide />
              </div>
            </div>
          } />
          <Route path="/Settings" element={
            <div className="header-filler">
              <div className="container content-column" style={{ height: `${homeMenuHeight}px` }}>
                <Settings setShow={setSidebarShowing} />
                <Footer heightMargin={contentHeight} hideDisplay={props.user === null || props.user === false}></Footer>
              </div>
            </div>
          } />
          <Route path="/Skye" element={
            <div className="header-filler">
              <div className="container content-column" style={{ height: `${homeMenuHeight}px` }}>
                <Skye setShow={setSidebarShowing} />
                <Footer heightMargin={contentHeight} hideDisplay={props.user === null || props.user === false}></Footer>
              </div>
            </div>
          } />
          <Route path="/Contact" element={
            <div className="header-filler">
              <div className="container content-column" style={{ height: `${homeMenuHeight}px` }}>
                <Contact setShow={setSidebarShowing} />
                <Footer heightMargin={contentHeight} hideDisplay={props.user === null || props.user === false}></Footer>
              </div>
            </div>
          } />
          <Route path="*" element={<ErrorMessage pageNotFound={true} setShow={setSidebarShowing} />} />
        </Routes>

      </div>

    </div>
  );
};

function mapStateToProps(state) {
  return { prototypeAccess: state.prototypeAccess, Loading: state.isLoading, user: state.user }
}

export default withErrorBoundary(connect(mapStateToProps, actions)(App),
  {
    FallbackComponent: ErrorMessage,
    onError: (error) => { logError(error) },
    resetKeys: [window.location.pathname]
  });
