import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import Axios from 'axios';
import Tooltip from 'rc-tooltip';
import { fetchAuthSession } from "aws-amplify/auth";
import { v4 as uuid } from 'uuid';
import { useLocation, Link } from 'react-router-dom';
import '../../../Styles/Journeys/JourneyPanel.css';
import ErrorMessage from '../../ErrorMessage';
import '../../../Styles/Journeys/NewJourney.css';
import logError from '../../LogError';
import HowToGuide from '../HowToGuide';
import ViewPost from '../ViewPost';
import TouchDeviceHeader from '../../TouchDeviceHeader';
import JourneyDelete from './JourneyDelete';
import 'reactflow/dist/style.css';
import JourneyTab from './JourneyTab';



//es lint exhaustive dependencies are disabled throughout this file.
//trying to fix them resulted in infitine re-renders hence, I have elected to just trust the dependencies I intentionally added 
function Journey(props) {
  const isMounted = useRef();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  var timeoutID;

  const waitFor = delay => new Promise(resolve => timeoutID = setTimeout(resolve, delay));

  const location = useLocation();
  const [journeyID, setJourneyID] = useState(null);
  const [title, setTitle] = useState("");
  const [journeyLoaded, setjourneyLoaded] = useState(false);
  const [ownerID, setOwnerID] = useState('')
  const [ownerType, setOwnerType] = useState('')
  const [tempStorage, setTempStorage] = useState('');
  const [viewerIsEditor, setViewerIsEditor] = useState(null);
  const [journeyNotFound, setJourneyNotFound] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [targetPostRate, setTargetPostRate] = useState("");
  const [journeyDescription, setJourneyDescription] = useState("");
  const [journeyStatus, setJourneyStatus] = useState("");
  const [tags, setTags] = useState("");
  const [visibility, setVisibility] = useState("");
  const [journeyContext, setJourneyContext] = useState("");
  const [journeyFooter, setJourneyFooter] = useState(null);
  const [numFollowers, setNumFollowers] = useState(0);
  const [isFollowing, setIsFollowing] = useState(false);

  const [rfInstance, setRfInstance] = useState(null);
  const [tab, setTab] = useState(0);
  const isLoading = props.isLoading;
  const [allPosts, setAllPosts] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);  
  const [saveError, setSaveError] = useState(false);
  

  //retrieve a journey from the database whenever the journey page is reloaded such as from a new post.
  //Or use location parameter updated to open a new post.
  useEffect(() => {
    if (location.search.includes("postID") && location.hash !== "#ViewPost" && document.getElementById("ViewPost")) {

      document.getElementById("ViewPost").hidden = false;
      window.location.href = "#ViewPost";
    }
  }, [location])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setJourneyID(searchParams.get("id"))
  }, [location])

  const getExistingJourney = useCallback(async () => {

    if (journeyID != null) {
      try {
        isLoading(true);
        let config = {}
        if (props.user) {
          const { idToken } = (await fetchAuthSession()).tokens ?? {};
          config = { headers: { "Authorization": idToken.toString() } };
        }
        const response = await Axios.get(process.env.REACT_APP_APIURL + "/GetJourney?id=" + journeyID + "&v=" + uuid(), config);
        if (!isEmptyObject(response.data) && isMounted.current) {
          if (response.data !== false) {
            setViewerIsEditor(response.data.id[0] === (props.user ? props.user.id : "") || response.data.CommunityMember != null)
            setTempStorage(response.data.RFInstance);
            setTitle(response.data.Title);
            setOwnerID(response.data.id[0]);
            setOwnerType(response.data.label[0]);
            setTargetPostRate(response.data.TargetPostRate);
            setJourneyContext(response.data.Context);
            setJourneyDescription(response.data.Description[1] ? response.data.Description[1] : response.data.Description[0]);
            setJourneyStatus(response.data.Status);
            setTags(response.data.Tags[1] ? response.data.Tags[1] : response.data.Tags[0]);
            setVisibility(response.data.Visibility);
            setjourneyLoaded(true);
            setIsFollowing(response.data.Follows);
            setNumFollowers(response.data.NumFollowers);
          }
          else if (isMounted.current) {
            setJourneyNotFound(true);
          }
        }
      }
      catch (e) {
        console.log(e);
        if (isMounted.current) {
          logError(e);
          setServerError(true);
        }
      }
      finally {
        isLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [journeyID, props.user])

  // get a list of all posts in chronological order
  const getAllPosts = async () => {
    var result = false;
    try {
      result = await Axios.get(process.env.REACT_APP_APIURL + "/GetAllJourneyPosts?id=" + journeyID + "&v=" + uuid());
      if (isMounted.current) {
        setAllPosts(result.data)
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getExistingJourney();
  }, [getExistingJourney])

  //Set the editable variables as uncontrolled components after page renders. 
  useEffect(() => {
    if (journeyFooter != null && isMounted.current) {
      document.getElementById('JourneyTargetPostRate').value = targetPostRate;
      if (document.getElementById('JourneyGoal')) {
        document.getElementById('JourneyGoal').value = journeyDescription;
      }
      document.getElementById('JourneyContext').value = journeyContext;
      document.getElementById('JourneyTags').value = tags;
      document.getElementById('JourneyStatus').value = journeyStatus;
      //document.getElementById('JourneyPersonality').value = journeyPersonality;
      if (viewerIsEditor) {
        document.getElementById('JourneyVisibility').value = visibility;
      }
    }
  },
    // eslint-disable-next-line
    [journeyFooter, journeyDescription, tags, visibility, targetPostRate])

  const isEmptyObject = (object) => {
    return JSON.stringify(object) === '{}'
  }

  //journey should stop loading anyway from the onrestore function but this is just an extra check
  //the hope is the user can't edit the default journey whilst a large journey takes time to load
  useEffect(() => {
    if (journeyLoaded || location.hash !== "" || journeyNotFound) {
      props.isLoading(false);
    }
    else {
      props.isLoading(true);
    }
  }, [journeyLoaded, props, journeyNotFound, location.hash])


  useEffect(() => {
    return () => clearTimeout(timeoutID)
  }, [])

  const onSave = useCallback(async () => {

    isLoading(true);
    const journey = JSON.stringify(rfInstance.toObject());
    try {
      var tpr = document.getElementById('JourneyTargetPostRate').value;
      var desc = document.getElementById('JourneyGoal').value;
      var context = document.getElementById('JourneyContext').value;
      var tag = document.getElementById('JourneyTags').value;
      var title = document.getElementById('JourneyTitle').value;
      var status = document.getElementById('JourneyStatus').value;
      const visibility = document.getElementById("JourneyVisibility").value;
      var communityID;
      if (ownerType === "community") {
        communityID = ownerID;
      }
      if (rfInstance) {
        const body = {
          journeyID: journeyID,
          journey: journey,
          context: context,
          description: desc,
          targetPostRate: tpr,
          status: status,
          tags: tag,
          visibility: visibility,
          communityID: communityID,
          //personality: personality,
          title: title
        };
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        let config = { headers: { "Authorization": idToken.toString() } };
        await Axios.post(process.env.REACT_APP_APIURL + '/SaveJourney', body, config);
        if (isMounted.current) {
          setTempStorage(journey);
          setUnsavedChanges(false);
        }
      }
    }
    catch (err) {
      if (isMounted.current) {
        console.log(err);
        logError(err);
        setServerError(true);
        setSaveError(true);
      }
    }
    finally {
      isLoading(false);
    }
  }, [rfInstance, isLoading, journeyID, ownerID, ownerType]);


  const onDelete = () => {
    window.location.href = "#deleteJourney"
  }


  const getNewTemplateJourney = async (goal) => {
    var desc = document.getElementById('JourneyGoal').value;
    var context = document.getElementById('JourneyContext').value;
    var requestID = uuid();

    isLoading(true);
    try {
      const body = {
        Age: props.user ? props.user.DOB : "unknown",
        Tags: tags,
        Description: goal ? goal : desc,
        Gender: props.user ? props.user.Gender : "unknown",
        Context: context,
        RequestID: requestID,
        RequestTarget: "GenerateTemplateJourneyFromChatGPT"
      }

      var loop = 0
      while (loop < 12) {
        var response = await Axios.post(process.env.REACT_APP_APIURL + "/CheckLLMLambdaStatus", body)
        if (response.data.RequestStatus === "SUCCEEDED" || response.data.RequestStatus === "FAILED") {
          break;
        }
        await waitFor(5000)
        loop++;
      }
      if (loop < 12) {
        if ((props.user !== null || tempStorage === '') && response.data.RequestStatus === "SUCCEEDED" && isMounted.current) {
          setTempStorage(response.data.RequestOutput)
          setjourneyLoaded(true);
          setUnsavedChanges(true);
        }
        else {
          throw new Error(response.data.RequestOutput)
        }
      }
      else {

      }
    }
    catch (e) {
      console.log(e)
    }
    finally {
      isLoading(false)
    }
  }

  const changeTab = (i) => {
    setTab(i);
    if (i === 2 && allPosts == null) {
      getAllPosts()
    }
  }

  return (
    <div>
      <TouchDeviceHeader setShow={props.setShow}></TouchDeviceHeader>
      <JourneyDelete title={title} id={journeyID} />
      {journeyLoaded ? <div>
        <div className="journey-title">
          {title}
        </div>
        <div className="card journey-wrapper">
          <div className="journey-tab" style={{ display: "block" }}>

            {/* --------------- Tabs --------------- */}
            <div className="tab-container tabs-classic tabs-fill">
              <ul>
                <li key={0} className={tab === 0 ? "selected" : ""} onClick={() => changeTab(0)}><div className="tab-item-content">Journey</div></li>
                <li key={1} className={tab === 1 ? "selected" : ""} onClick={() => changeTab(1)}><div className="tab-item-content">Details</div></li>
                <li key={2} className={tab === 2 ? "selected" : ""} onClick={() => changeTab(2)}><div className="tab-item-content">Posts</div></li>
                {viewerIsEditor ?
                  <li key={3} className={tab === 3 ? "selected" : ""} onClick={() => changeTab(3)}><div className="tab-item-content">How to Edit Journeys</div></li> : ""}
              </ul>
            </div>
            {tab === 0 ? <div>
              <JourneyTab unsavedChanges={unsavedChanges}
                setUnsavedChanges={setUnsavedChanges}
                onSave={onSave}
                viewerIsEditor={viewerIsEditor}
                saveError={saveError}
                setSaveError={setSaveError}
                numFollowers={numFollowers}
                tempStorage={tempStorage}
                setTempStorage={setTempStorage}
                setRfInstance={setRfInstance}
                getNewTemplateJourney={getNewTemplateJourney}
                isFollowing={isFollowing}
                journeyID={journeyID}
                title={title} 
                journeyLoaded={journeyLoaded}
                journeyNotFound={journeyNotFound}/>
            </div>
              : ""
            }
          </div>

          {/* --------------- Journey Details --------------- */}
          <div className="journey-footer" ref={setJourneyFooter} style={{ display: tab === 1 ? "block" : "none" }}>
            <div>
              <div className="unsaved-changes-div" style={{ position: "relative" }}>
                <div className="toast toast--link" style={{ margin: "0%", width: "fit-content", borderRadius: "20px", visibility: unsavedChanges ? "visible" : "hidden" }}>
                  <p className="unsaved-changes-font">You have unsaved changes</p>
                </div></div>
              <div style={{ marginLeft: "2%", marginRight: "2%" }}>
                <div className="u-flex row">
                  <div className="col-2">
                    <div className="input-control">
                      <p><b>Title:</b>
                        {viewerIsEditor ?
                          <Tooltip placement="right" trigger={['hover']} id="TitleToolTip" overlayStyle={{ zIndex: 1000, width: "20%" }} overlay={<span>This will be the name of your journey and will appear at the top of posts.</span>}>
                            <span className="icon qc-icon"><i className="fa fa-wrapper fa-question-circle" id="TitleQIcon" aria-hidden="true"></i></span>
                          </Tooltip> : ""}</p>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="input-control">
                      <input className="journey-details" value={title} disabled={!viewerIsEditor} onChange={(e) => { setUnsavedChanges(true); setTitle(e.target.value) }} maxLength={25} id="JourneyTitle"></input>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="input-control">
                      <p><b>Status:</b>
                        {viewerIsEditor ?
                          <Tooltip placement="right" trigger={['hover']} id="RateTip" overlayStyle={{ zIndex: 1000, width: "20%" }} overlay={<span>Frozen or complete Journeys won't trigger any reminder notifications!</span>}>
                            <span className="icon qc-icon"><i className="fa fa-wrapper fa-question-circle" id="RateIcon" aria-hidden="true"></i></span>
                          </Tooltip> : ""}</p>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="input-control">
                      <select disabled={!viewerIsEditor} onChange={() => setUnsavedChanges(true)} className="u-pull-right journey-details" id="JourneyStatus" type="text" style={{ width: "100%" }}>
                        <option value="active">Active</option>
                        <option value="frozen">Frozen</option>
                        <option value="complete">Complete</option>
                      </select>

                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: "2%", marginRight: "2%" }}>
                <div className="u-flex row">
                  <div className="col-2">
                    <div className="input-control">
                      <p><b>Tags:</b>
                        {viewerIsEditor ?
                          <Tooltip placement="right" trigger={['hover']} id="TagsTip" overlayStyle={{ zIndex: 1000, width: "20%" }} overlay={<span>Tags will allow users to quickly search for your journeys based on categories in the future. Separate different tags with commas.</span>}>
                            <span className="icon qc-icon"><i className="fa fa-wrapper fa-question-circle" id="TagsIcon" aria-hidden="true"></i></span>
                          </Tooltip> : ""}</p>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="input-control">
                      <input className="journey-details" disabled={!viewerIsEditor} onChange={() => setUnsavedChanges(true)} multiple={true} id="JourneyTags"></input>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="input-control">
                      <p><b>Visibility:</b>
                        {viewerIsEditor ?
                          <Tooltip placement="right" trigger={['hover']} id="VisibilityTip" overlayStyle={{ zIndex: 1000, width: "20%" }} overlay={<span>Private journeys can only be seen by the user or community they are created by. Post from private journeys also won't appear in feeds.</span>}>
                            <span className="icon qc-icon"><i className="fa fa-wrapper fa-question-circle" id="TagsIcon" aria-hidden="true"></i></span>
                          </Tooltip> : ""}</p>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="input-control">
                      <select className="u-pull-right journey-details" disabled={!viewerIsEditor} onChange={() => setUnsavedChanges(true)} id="JourneyVisibility" type="text" style={{ width: "100%" }}>
                        <option value="Public">Public</option>
                        <option value="Private">Private</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: "2%", marginRight: "2%" }}>
                <div className="u-flex row">

                  <div className="col-2">
                    <div className="input-control">
                      <p><b>Update Rate:</b>
                        {viewerIsEditor ?
                          <Tooltip placement="right" trigger={['hover']} id="RateTip" overlayStyle={{ zIndex: 1000, width: "20%" }} overlay={<span>How often do you hope to add updates posts to this journey?</span>}>
                            <span className="icon qc-icon"><i className="fa fa-wrapper fa-question-circle" id="RateIcon" aria-hidden="true"></i></span>
                          </Tooltip> : ""}</p>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="input-control">
                      <select disabled={!viewerIsEditor} onChange={() => setUnsavedChanges(true)} className="u-pull-right journey-details" id="JourneyTargetPostRate" type="text" style={{ width: "100%" }}>
                        <option value={86400000}>Daily</option>
                        <option value={302400000}>Biweekly</option>
                        <option value={604800000}>Weekly</option>
                        <option value={1209600000}>Fortnightly</option>
                        <option value={2678400000}>Monthly</option>
                      </select>

                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: "2%", marginRight: "2%" }}>
                <div className="u-flex row">
                  <div className="col-3">
                    <div className="input-control">
                      <p><b>The story so far:</b></p>
                    </div>
                  </div>
                  <div className="col-9">
                    <div className="input-control">
                      <textarea className="journey-details" disabled={!viewerIsEditor} onChange={() => setUnsavedChanges(true)} style={{ margin: 0 }} placeholder="Tell us where you're starting your journey to improve AI generated suggestions and help other users understand your story!" id="JourneyContext"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: "2%", marginRight: "2%" }}>
                <div className="u-flex row">
                  <div className="col-3">
                    <div className="input-control">
                      <p><b>The Goal:</b></p>
                    </div>
                  </div>
                  <div className="col-9">
                    <div className="input-control" style={{ margin: 0 }}>
                      <textarea className="journey-details" disabled={!viewerIsEditor} onChange={() => setUnsavedChanges(true)} placeholder="What is your goal? Where will this journey lead?" id="JourneyGoal"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {viewerIsEditor ?
              <div style={{ bottom: 0, right: 0, zIndex: 1000, width: "inherit" }}>
                <Tooltip placement="bottom" animation="zoom" trigger={['hover']} id="saveButton" overlayStyle={{ zIndex: 1000, width: "auto" }} overlay={<span>Save</span>}>
                  <button className="btn-info btn-small" style={{ marginLeft: "0%", float: "right" }} onClick={onSave}><i className="far fa-save fa-lg"></i></button>
                </Tooltip>
              </div> : ""}
            {viewerIsEditor ?
              <div className="u-center" style={{ width: "100%" }}>
                <button className="btn-danger btn-small" style={{ marginLeft: "0%", textTransform: "none", backgroundColor: "red !important" }} onClick={onDelete}>
                  <i className="fa fa-trash fa-lg" style={{ marginRight: "4px" }}></i>
                  <b>Delete Journey</b>
                </button>
              </div> : ""}
          </div>

          {/* --------------- All Journey Posts --------------- */}
          <div className='allPosts' style={{ display: tab === 2 ? "block" : "none" }}>
            {allPosts ? allPosts.map((x) =>
              <div className="postWrapper" key={x.PostedDate}>
                <div className="u-flex row">
                  <div className="col-12">
                    <ViewPost
                      id={x.PosterID}
                      displayName={x.DisplayName}
                      colour={x.Colour}
                      path={x.PosterProfilePictureS3Path}
                      attachmentPath={x.ImagePath}
                      postText={x.PostText}
                      postDate={x.PostedDate}
                      topCorners={true}
                      isLoading={isLoading}
                    ></ViewPost>
                  </div>
                </div>
              </div>)
              : ""}
          </div>

          {/* --------------- How To Guide --------------- */}
          <div className='howToGuide' style={{ display: tab === 3 ? "block" : "none" }}>
            <HowToGuide />
          </div>


        </div>
      </div>
        : serverError ? <ErrorMessage serverError={true}></ErrorMessage>
          : <div></div>
      }
      {
        journeyNotFound ?
          <div className="card">
            <div className='not-found-card'>
              <span className="icon subtitle" style={{ fontSize: "X-large", transform: "scale(5)" }}><i className="fa fa-solid fa-sad-tear"></i></span>
            </div>
            <p style={{ margin: "2%", textAlign: "justify" }}>We're sorry but the requested journey was not found. This could be because the journey is set to private
              and you don't have permission to view it. It could also easily be because of a bug on our end.
              If you think you should be able to see a journey at this address, please contact SkyReachers support.
              <Link to="/"><button className="btn-info btn-small" style={{ float: "right" }}>Back to Feed</button></Link></p>
          </div>
          :
          <div></div>
      } </div >
  );
};

function mapStateToProps(state) {
  return { user: state.user };
}

export default connect(mapStateToProps, actions)(Journey);