import React, { useEffect, useState , useRef} from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import 'rc-tooltip/assets/bootstrap.css';
import Axios from 'axios';
import  { fetchAuthSession } from"aws-amplify/auth";
import '../Styles/Settings.css'
import logError from './LogError';
import GoogleCalenderPermission from './GoogleCalenderPermission'
import { Link } from 'react-router-dom';
import { v4 } from 'uuid';

function Settings(props) {
    const isMounted = useRef();

    useEffect(() => {
        isMounted.current = true;
        return () => {
        isMounted.current = false;
        };
    }, []);

    const [feedLength, setFeedLength] = useState(20);
    const [serverError, setServerError] = useState(false)
    const [dailyTasksPerJourney, setDailyTasksPerJourney] = useState(1)
    const [time, setTime] = useState({ hours: 0, minutes: 0 });
    const [calendarSynced, setCalendarSynced] = useState(false);
    const [checkInTime, setCheckInTime] = useState("21:00");
    const [refreshCalendarCheck, setRefreshCalenderCheck] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if(isMounted){
            setTime({ ...time, [name]: value });
        }
    }

    const isLoading = props.isLoading;

    useEffect(() => {
        const GetGoogleCalendarEvents = async () => {
            try {
               const { idToken } = (await fetchAuthSession()).tokens ?? {};
                let config = { headers: { Authorization: idToken.toString() } };
                const response = await Axios.get(process.env.REACT_APP_APIURL + "/GetGoogleCalendarEvents?v=" + v4(), config)
                if(isMounted.current){
                    if (response.data) {
                        setCalendarSynced(true)
                    }
                    else {
                        setCalendarSynced(false)
                    }
                }
            }
            catch (error) {
                console.log(error)
                if(isMounted.current){
                    setCalendarSynced(false)
                }
            }
        }

        GetGoogleCalendarEvents();
    }, [refreshCalendarCheck])

    const timeStringToMilliseconds = (timeString) => {
        const [hours, minutes] = timeString.split(':').map(Number);
        return (hours * 60 * 60 + minutes * 60) * 1000;
    }
    
    // Function to convert milliseconds to time string
    const millisecondsToTimeString = (milliseconds) => {
        let totalSeconds = Math.floor(milliseconds / 1000);
        let hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        let minutes = Math.floor(totalSeconds / 60);
    
        // Pad the hours and minutes with leading zeros if necessary
        let hoursString = hours.toString().padStart(2, '0');
        let minutesString = minutes.toString().padStart(2, '0');
    
        return `${hoursString}:${minutesString}`;
    }

    useEffect(() => {
        if(isMounted.current){
            setServerError(false);
            isLoading(true);
            setFeedLength(parseInt(props.user.FeedLength));
            setCheckInTime(millisecondsToTimeString(props.user.CheckInTime));
            setDailyTasksPerJourney(parseInt(props.user.DailyTasksPerJourney));
            const hours = Math.floor(props.user.TimeForGoals / 60)
            const minutes = props.user.TimeForGoals % 60;
            setTime({ "hours": hours, "minutes": minutes })
            isLoading(false);
        }
    }, [props.user])

    const saveSettings = async () => {
        props.isLoading(true);
       const { idToken } = (await fetchAuthSession()).tokens ?? {};
        const body = {
            feedLength: feedLength,
            TimeForGoals: parseInt(time.hours) * 60 + parseInt(time.minutes),
            dailyTasksPerJourney: dailyTasksPerJourney,
            checkInTime: timeStringToMilliseconds(checkInTime)
        }
        let config = {headers: {"Authorization": idToken.toString()}};
        try {
            await Axios.post(process.env.REACT_APP_APIURL + '/SaveSettings', body, config);
            await props.fetchUser();
        }
        catch (e) {
            console.log(e);
            logError(e);
            setServerError(true);
        }
        finally {
            props.isLoading(false);
        }
    }

    return (
        <div>
            {serverError ? <ErrorMessage serverError={true}></ErrorMessage>
                :
                <div>
                    <div className="card" style={{ margin: "16px" }}>
                        <div className="card__header u-center" style={{ marginTop: "16px" }}>
                            <h1>Settings</h1>
                        </div>
                        <div style={{ margin: "2%" }}>
                            <div style={{ marginTop: "16px" }}>
                                <h3> Skye </h3>
                            </div>
                            <div className="u-flex row" style={{ position: "relative" }}>
                                <div className="col-3">
                                    <div className="input-control">
                                        <p><b>Google Calendar Sync:</b></p>
                                    </div>
                                </div>
                                <div className="col-3" style={{ display: "flex", alignItems: "center" }}>
                                    {calendarSynced === null ? "Checking Calendar Sync..." : (
                                        calendarSynced ? "Calendar is Already Synced" :
                                            <GoogleCalenderPermission refreshCalendarCheck={refreshCalendarCheck} setRefreshCalenderCheck={setRefreshCalenderCheck} />)
                                    }
                                </div>
                                <div className="col-3">
                                    <div className="input-control">
                                        <p><b>Daily Check in Time</b></p>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="input-control">
                                        <input value={checkInTime} type="time" onChange={(e) => setCheckInTime(e.target.value)}></input>
                                    </div>
                                </div>
                             </div>
                            <div style={{ marginTop: "16px" }}>
                                <h3> Daily Tasks </h3>
                            </div>
                            <div className="u-flex row" style={{ position: "relative" }}>
                                <div className="col-3">
                                    <div className="input-control">
                                        <p><b>Automated Tasks Per Journey</b></p>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="input-control">
                                        <select className="u-pull-right" id="feedLength" type="text" value={dailyTasksPerJourney} onChange={(event) => setDailyTasksPerJourney(event.target.value)} style={{ width: "100%" }}>
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                            <option value={4}>4</option>
                                            <option value={5}>5</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="input-control">
                                        <p><b>Daily Time Spend Per Journey</b></p>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="input-control">
                                        <div className="time-settings">
                                            <label>
                                                Hours:
                                                <input
                                                    type="number"
                                                    name="hours"
                                                    value={time.hours}
                                                    onChange={handleInputChange}
                                                    min="0"
                                                    max="16"
                                                />
                                            </label>
                                            <label>
                                                Minutes:
                                                <input
                                                    type="number"
                                                    name="minutes"
                                                    value={time.minutes}
                                                    onChange={handleInputChange}
                                                    min="0"
                                                    max="59"
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*
                            <div style={{ marginTop: "16px" }}>
                                <h3> Feed </h3>
                            </div>
                            <div className="u-flex row" style={{ position: "relative" }}>
                                <div className="col-3">
                                    <div className="input-control">
                                        <p><b>Feed Length</b></p>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="input-control">
                                        <select className="u-pull-right" id="feedLength" type="text" value={feedLength} onChange={(event) => setFeedLength(event.target.value)} style={{ width: "100%" }}>
                                            <option value={20}>20</option>
                                            <option value={30}>30</option>
                                            <option value={40}>40</option>
                                            <option value={50}>50</option>
                                        </select>
                                    </div>
                                </div>
                            </div>*/}
                            <div className="u-center" style={{ margin: "32px" }}>
                                <button className="save-button" onClick={() => saveSettings()}>Save</button>
                            </div>
                            <div style={{ marginTop: "16px" }}>
                                <h3> Subscription</h3>
                            </div>
                            <div className="u-flex row" style={{ position: "relative" }}>
                                <div className="col-3">
                                    <div className="input-control" style={{marginTop:"60px"}}>
                                        <p><b>Current Path:</b></p>
                                    </div>
                                </div>
                                <div className="col-3" style={{ display: "flex", alignItems: "center"}}>
                                    {props.user.subscription}
                                </div>
                                <div className="col-3">
                                    <div className="input-control">
                                        <Link to={"/subscription?update=true"}>
                                        <button className="tier-button u-center"><b>Change Path</b></button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="input-control">
                                    <Link to={"/subscription?updatePayment=true&update=true"}>
                                        <button className="tier-button u-center"><b>Update Payment Method</b></button>
                                    </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div >)
}

function mapStateToProps(state) {
    return { user: state.user };
}

export default connect(mapStateToProps, actions)(Settings);
