import React from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import "rc-tooltip/assets/bootstrap.css";
import { useLocation } from "react-router-dom";
import Cloud from '../Images/Cloud.png';

function CloudPopup(props) {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search)
    const context = searchParams.get("context")

    const processNavigate = async (action) => {

        if (context === "welcome"){
            await props.fetchUser(true)
        }

        switch (action) {
            case "close":
                window.location.href = "#!";
                break;
            case "newJourney":
                window.location.href = "/newJourney";
                break;
            case "profile":
                window.location.href = "/profile/" + props.user.id;
                break;
            case "settings":
                    window.location.href = "/settings";
                    break;
            case "subscription":
                window.location.href = "/subscription";
                break;
            case "home":
                window.location.href = "/";
                break;
        }
    };

    if(!props.user){
        return null;
    }

    return (
        <div
            className="modal modal-animated--zoom-in"
            id="CloudPopup"
            style={{ backgroundColor: "rgb(0,0,0,0.5)", zIndex: 2000 }}
        >
            <div
                className="modal-content"
                role="document"
                style={{ width: "-webkit-fill-available", backgroundColor: "rgba(0,0,0,0)", boxShadow: "none" }}
            >
                <div className="card" style={{ borderRadius: "20px", padding: 0, backgroundColor: "white" }}>
                    <img src={Cloud} style={{ position: "absolute", zIndex: 1 }}></img>
                    <div aria-label="Close" onClick={() => processNavigate("close")} style={{ zIndex: 2, position: "relative", display: context === "welcome" ? "block" : "none"}}>
                        <span className="icon u-position-relative u-pull-right" style={{ margin: "3px" }}>
                            <i className="fa-wrapper fa fa-times" />
                        </span>
                    </div>
                    {context === "welcome" ?
                    <div className="u-center" style={{ height: "400px", zIndex: 2, position: "relative", paddingTop: "60px", textAlign:"center" }}>
                        <div style={{ width: "100%", textAlign:"center" }} className="u-center">
                            <b style={{ width: "100%", textAlign:"center" }} className="u-center">Welcome To SkyReachers, {props.user.DisplayName}</b>
                            Your account has been successfully created.
                        </div>
                        <div style={{ width: "100%" }} className="u-center">
                            <button className="log-in-button"  onClick={() => processNavigate("newJourney")} style={{ width: "unset", margin: 0 }}>Plan my First Journey</button>
                        </div>
                        <div style={{ width: "100%" }} className="u-center">
                            <button className="log-in-button log-in-button-2"  onClick={() => processNavigate("profile")} style={{ width: "unset", margin: 0 }}>Set up my Profile</button>
                        </div>
                        <div style={{ width: "100%" }} className="u-center">
                            <button className="log-in-button"  onClick={() => processNavigate("settings")} style={{ width: "unset", margin: 0 }}>Configure my Account Settings</button>
                        </div>
                    </div> : <div className="u-center" style={{ height: "400px", zIndex: 2, position: "relative", paddingTop: "60px" }}>
                        <div style={{ width: "100%" }} className="u-center">
                            <b style={{ width: "100%" }} className="u-center">We're sorry, {props.user.DisplayName}</b>
                            This feature is only available to users on the Dreamer Path
                        </div>
                        <div style={{ width: "100%" }} className="u-center">
                            <button className="log-in-button"  onClick={() => processNavigate("subscription")} style={{ width: "unset", margin: 0 }}>Upgrade Subscription</button>
                        </div>
                        <div style={{ width: "100%" }} className="u-center">
                            <button className="log-in-button log-in-button-2"  onClick={() => processNavigate("home")} style={{ width: "unset", margin: 0 }}>Return to Home</button>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return { user: state.user };
}

export default connect(mapStateToProps, actions)(CloudPopup);
