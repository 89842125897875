import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import Axios from 'axios';
import JourneyTag from './JourneyTag';
import  { fetchAuthSession } from"aws-amplify/auth";
import { Link } from 'react-router-dom';
import { v4 } from 'uuid';

function JourneysList(props) {
    const isMounted = useRef();

    useEffect(() => {
        isMounted.current = true;
        return () => {
        isMounted.current = false;
        };
    }, []);
    
    const [journeys, setJourneys] = useState([]);
    const [journeysRetreived, setJourneysRetrieved] = useState(false);
    const [displayedJourneys, setDisplayedJourneys] = useState([]);
    const [showJourneys, setShowJourneys] = useState(null);

    const ownerID = props.ownerID;
    const ownerType = props.ownerType;
    const isLoading = props.isLoading;
    const following = props.following;
    const setJourneyCount = props.setJourneyCount;
    const status = props.status;

    useEffect(() => {
        const renderContent = () => {
            isLoading(true);;

            const getAllJourneys = async () => {
                let config = {}
                //People can still view public journeys without logging in.
                if (props.user) {
                   const { idToken } = (await fetchAuthSession()).tokens ?? {};
                    config = { headers: { "Authorization": idToken.toString()} };
                }
                var result;
                if (following) {
                    result = await Axios.get(process.env.REACT_APP_APIURL + "/GetJourney?following=true&v=" + v4(), config);
                }
                else if (ownerID !== props.user.id) {
                    result = await Axios.get(process.env.REACT_APP_APIURL + "/GetJourney?owner=" + ownerID + "&status=" + status + "&v=" + v4(), config);
                }
                else {
                    result = await Axios.get(process.env.REACT_APP_APIURL + "/GetJourney?getAllMyJourneys=true&status=" + status + "&v=" + v4(), config);
                }
                if (setJourneyCount && isMounted.current) {
                    setJourneyCount(result.data.length);
                }
                if(isMounted.current){
                    setJourneys(result.data);
                    setJourneysRetrieved(true);
                    isLoading(false);
                }
            };

            if (!journeysRetreived) { getAllJourneys(); }
            else
                isLoading(false);
        }

        renderContent();
    }
        , [journeysRetreived, journeys, isLoading, props.user, ownerID, following, setJourneyCount, props.status]);

    useEffect(() => {
        if (journeys && !isEmptyObject(journeys)) {
            setDisplayedJourneys(journeys)
        }
    }, [journeys, showJourneys])

    const isEmptyObject = (object) => {
        return JSON.stringify(object) === '{}'
    }

    return <div>
        <div>
            <div className="u-flex journey-list-wrapper" style={{ padding: "10px" }}>
                {displayedJourneys.map((x) =>
                    <div key={x.JourneyID} className="col-12" style={{ float: "left", marginTop: "1%", marginBottom: "1%", padding: 0, marginRight: "32px" }}>
                        <JourneyTag ownerID={x.OwnerID} id={x.JourneyID} progress={100 * x.CompletedMileStoneCount / (x.JourneyNodeCount - 1)} ownerType={x.OwnerType} title={x.Title} owner={x.DisplayName} />
                    </div>)}
                <div className="col-12" style={{ float: "left", marginTop: "1%", marginBottom: "1%", padding: 0, marginRight: "32px" }}>
                    {props.showNewJourney && (props.user.subscription !== "Visionary" || journeys.length === 0) ?
                        <div className="journey-link-container">
                            <div className="u-center" style={{height:"100%", width:"100%"}}>
                                <div className="row" style={{ height: "100px", display: "flex" }}>
                                    <Link className="journey-link" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap" }} to={"/NewJourney"}>
                                        <div style={{ fontSize: "large", width: "100%" }}> <b>Start a New Journey</b></div>
                                        <span className="far fa-plus-square" style={{ fontSize: "xx-large", marginRight: "4px" }}></span>
                                    </Link>
                                </div>
                            </div>
                        </div> : ""}
                </div>
            </div>
        </div></div>
}

function mapStateToProps(state) {
    return { user: state.user };
}

export default connect(mapStateToProps, actions)(JourneysList);
