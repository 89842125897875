import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import ProfileTag from "./ProfileTag";
import NewPostModal from "./NewPostModal";

function AddPost(props) {
  const [nodeID, setNodeID] = useState(null);
  const [edgeID, setEdgeID] = useState(null);
  const [triggerPost, setTriggerPost] = useState(null);

  useEffect(() => {
    
    if (props.selectedNode){
      setNodeID(props.selectedNode.id)
    }
    else{
      setNodeID(null)
    }
    if (props.selectedEdge){
      
      setEdgeID(props.selectedEdge.id)
    }
    else{
      setEdgeID(null)
    }
    if((props.selectedNode || props.selectedEdge) && props.triggerPost){
      setTriggerPost(true)
      props.setTriggerPost(false)
    }
  }, [props.selectedEdge, props.selectedNode, props.triggerPost, props.setAddingPost])

  const addPost = () => {
    props.setSelectedEdge(null)
    props.setSelectedNode(null)
    window.location.href="#newPost";
  }

  return (
  <div className="u-center" style={{marginTop:"20px", marginBottom: "20px", height:"60px"}}>
    <div className="u-inline-flex u-items-center" style={{width:"100%", marginLeft:"4%"}}>
      <ProfileTag type="user"
        id={props.user.id}
        displayName={props.user.DisplayName}
        colour={props.user.Colour}
        path={props.user.ProfilePictureS3Path}
        hideName={true}
      ></ProfileTag>
      <div className="" style={{width:"100%", marginLeft:"3%", marginRight:"2.5%"}}>
        <input placeholder="Share your progress!" onClick={() => addPost()}></input>
      </div>
    </div>
    <div>
      <NewPostModal 
        showCongrats={props.showCongrats}
        setAddingPost={props.setAddingPost}
        triggerPost={triggerPost}
        refreshJourney={props.refreshJourney}
        setTriggerPost={setTriggerPost} 
        setAddingPostFromGraph={props.setAddingPostFromGraph}
        journeyID={props.journeyID} 
        nodeID={nodeID} 
        edgeID={edgeID} 
        journeyTitle={props.journeyTitle}>
        </NewPostModal>
    </div>
</div>)
}

function mapStateToProps(state) {
  return { user: state.user, feed: state.feed };
}

export default connect(mapStateToProps, actions)(React.memo(AddPost));

