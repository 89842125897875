import React from 'react';
import { connect } from 'react-redux';
import '../Styles/Sidebar.css';
import * as actions from '../actions';
import { Link } from 'react-router-dom';
import  { fetchAuthSession, signOut } from'aws-amplify/auth';
import { Device } from '@capacitor/device';
import Axios from 'axios';
import UserGreeting from './UserContent/UserGreeting';
import ProfileAvatar from './UserContent/ProfileAvatar';


function Sidebar(props)
{
    const signOutFunction = async () =>
    {
        try {
            props.isLoading(true);
           const { idToken } = (await fetchAuthSession()).tokens ?? {};
            const body = await Device.getId();
            let config = {headers: {"Authorization": idToken.toString()}};
            console.log("here")
            await Axios.post(process.env.REACT_APP_APIURL + '/RemoveDeviceFromUser', body, config);
            await signOut({global: false});
            await props.fetchUser();
            window.location.href = "/Auth/Login";
            } 
        catch (error) {
            console.log(error);
        }
        finally{
            props.isLoading(false);
        }
    }

    if(props.user === null || !props.user){ return null }

    return (
        <div className='sidebar-wrapper'>
            <div className="black-backing" style={{display: props.show ? "block" : "none"}}></div>
            <div className={`sidebar ${props.show ? "open-menu" : ""}`}>
                <div className="frame" style={{height:"100%",borderTopRightRadius:"0"}}>
                    <div className="frame__body" style={{padding:0, overflow:"clip"}}>
                        <div className="close-button" style={{display: props.show  ? "block" : "none"}} onClick={() => props.setShow(false)}>
                            X
                        </div>
                        <div className='profile-pic-wrapper'>
                      <ProfileAvatar colour={props.user.Colour} path={props.user.ProfilePictureS3Path} size={3.2}></ProfileAvatar>
                        </div>
                        <div className="u-center" style={{transform:"scale(1.2)", pointerEvents:"none"}}>
                            <UserGreeting/>
                        </div>
                        <hr style={{marginTop: "-32px"}}></hr>
                        <div className="sidebar-link">
                            <Link to={`/profile/${props.user.id}`} >
                                <span className="fa fa-user sidebar-icon"></span>
                                Profile
                            </Link>
                        </div>
                        <div className="sidebar-link">
                            <Link to={"/Settings"}>
                                <span className="fa fa-wrench sidebar-icon"></span>
                                Settings
                            </Link>
                        </div>
                        <div className="sidebar-link">
                            <Link to={"/Contact"}>
                                <span className="fa fa-envelope-open sidebar-icon"></span>
                                Contact
                            </Link>
                        </div>
                        {/*
                        <div className="sidebar-link">
                            <Link to={"/contact"}>
                                <span className="fa fa-bug sidebar-icon"></span>
                                Report a Bug
                            </Link>
                        </div>*/}
                        <div className="sidebar-link">
                            <Link to={"#!"} onClick={() => signOutFunction()}>
                                <span className="fas fa-door-open sidebar-icon"></span>
                                Log out
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
}

function mapStateToProps(state)
{
  return {user: state.user};
}

export default connect(mapStateToProps, actions)(Sidebar);
