import PDF from "../PDF";

function TermsOfService(props){
    return (
        <div className="modal modal-animated--zoom-in" id="ToS" style={{backgroundColor:"rgb(0,0,0,0.5)", zIndex:2000}}>
        <PDF filePath={"https://skyreachers-public.s3.ap-southeast-2.amazonaws.com/ToS.pdf"} closeLink={"#AuthForms"}></PDF>
        </div>
    )
}   

export default TermsOfService;