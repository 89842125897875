import React, { useEffect, useState, useRef } from 'react';
import Logo from "../Images/Logo.png";
import { connect } from 'react-redux';
import '../Styles/NavBar.css';
import * as actions from '../actions';
import { Link, useLocation } from 'react-router-dom';
import Axios from 'axios';
import { fetchAuthSession } from "aws-amplify/auth";
import ProfileAvatar from './UserContent/ProfileAvatar';
import { v4 } from 'uuid';

function Header(props) {
  const isMounted = useRef();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [inviteCount, setInviteCount] = useState(0);
  const fetchUser = props.fetchUser;

  const Location = useLocation();

  useEffect(() => {
    if (isMounted.current) {
      const fetchUserWrapper = async () => {
        await fetchUser();
      };
      fetchUserWrapper();
    }
  }, [fetchUser])

  useEffect(() => {
    if (props.user) {
      const getInvites = async () => {
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        let config = { headers: { "Authorization": idToken.toString() } };
        const result = await Axios.get(process.env.REACT_APP_APIURL + '/GetInvites?v=' + v4(), config);

        if (isMounted.current) {
          setInviteCount(result.data.length);
        }
      }

      const interval = setInterval(() => {
        getInvites()
      }, 10000)

      getInvites();


      return () => clearInterval(interval)
    }
  }
    , [])

  const renderContent = (props) => {
    var Content;

    //Added to avoid white page break on bugged id retrieval
    if (props.user.id == null) { props.user.id = " " }

    Content =
      <div className="nav-right" style={{ display: "flex", alignItems: "center" }}>
        <div className='nav-item pc-menu' style={{ marginRight: "32px", marginTop: "16px" }}>
          <Link to={"/Invites"} style={{ padding: "0" }}>
            <span className="far fa-bell" style={{ fontSize: "xx-large" }}>
              {inviteCount >= 0 ?
                <span className="notification-count"></span>
                : ""}
            </span>
          </Link>
        </div>
        <div className="nav-item no-hover tablet-menu tablet-link-div last-menu-element">
          <Link to={`/profile/${props.user.id}`} style={{ padding: "0" }} className="tablet-link">
            <span className="fas fa-user-circle menu-icon"></span>
            <div className="menu-text"> Profile </div>
          </Link>
        </div>
        <div className="nav-item pc-menu last-menu-element" onClick={() => props.setSidebarShow(true)}>
          <ProfileAvatar colour={props.user.Colour} path={props.user.ProfilePictureS3Path}></ProfileAvatar>
        </div>
      </div>

    return (Content);
  }

  if (Location.pathname.toLowerCase() === "/subscription" || Location.pathname.toLowerCase().includes("/auth/") || props.user === null || !props.user) { return null }


  return (
    <div className="header header-fixed u-unselectable header-animated customHeader">
      <div className="header-brand customHeaderBrand">
        <div className="nav-item no-hover pc-menu first-menu-element">
          <Link to="/">
            <figure className="avatar">
              <img src={Logo} alt="SkyReachers-Logo"></img>
            </figure>
          </Link>
        </div>
        <div className="nav-item no-hover tablet-menu tablet-link-div first-menu-element">
          <Link to="/" className="tablet-link">
            <span className="fas fa-home menu-icon"></span>
            <div className="menu-text"> Home </div>
          </Link>
        </div>
        <div className="nav-item no-hover tablet-link-div">
          <Link to="/feed" className="tablet-link">
            <span className="far fa-newspaper menu-icon"></span>
            <div className="menu-text"> Newsfeed </div>
          </Link>
        </div>
        <div className="nav-item no-hover tablet-link-div">
          <Link to="/Skye" className="tablet-link">
            <span className="far fa-comment menu-icon"></span>
            <div className="menu-text"> Skye </div>
          </Link>
        </div>
        {/* Add explore back in later when we have communities

            <div className='nav-item no-hover pc-menu' style={{flexGrow:"1", marginLeft: "48px", marginRight: "48px", marginTop:"32px", marginBottom:"32px"}}>
                    <input className="explore-bar" placeholder='Explore...' ></input>
                    <Link to="/"><span className="fas fa-search u-center" style={{fontSize:"large", paddingLeft:"6px", paddingRight: "6px", borderTopRightRadius: "8px", borderBottomRightRadius: "8px", background: "#fafafa", height:"32px", border:"#676767 solid 1px"}}></span></Link>
            </div>
            <div className="nav-item no-hover tablet-menu tablet-link-div">
                    <Link to="/" className="tablet-link"><span className="fas fa-search menu-icon"></span>
                    <div className="menu-text">Explore</div>
                    </Link>
          </div>*/}
        <div className='nav-item no-hover pc-menu' style={{ flexGrow: "1", marginLeft: "48px", marginRight: "48px", marginTop: "32px", marginBottom: "32px" }}>
          <h5>SKYREACHERS OPEN BETA</h5>
        </div>
        <div className="nav-item no-hover tablet-menu tablet-link-div">
          <Link to="/Contact" className="tablet-link"><span className="far fa-envelope-open wmenu-icon"></span>
            <div className="menu-text">Support</div>
          </Link>
        </div>
        <div className='nav-item no-hover'>
          {renderContent(props)}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return { user: state.user };
}

export default connect(mapStateToProps, actions)(Header);
