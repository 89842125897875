import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import 'rc-tooltip/assets/bootstrap.css';
import { v4 as uuid } from 'uuid';
import  { fetchAuthSession } from"aws-amplify/auth";
import Axios from 'axios';
import Tooltip from 'rc-tooltip';
import '../../../Styles/Journeys/NewJourney.css'
import { useLocation } from 'react-router-dom';
import ProgressBar from '../../ProgressBar';
import SMARTpopup from './SMARTpopup';

function NewJourney(props) {
    
    const isMounted = useRef();

    useEffect(() => {
          isMounted.current = true;
          return () => {
          isMounted.current = false;
          };
      }, []);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const communityID = searchParams.get("communityID");

    const [expandedForm, setExpandedForm] = useState(false);
    const [SScore, setSScore] = useState(false);
    const [MScore, setMScore] = useState(false);
    const [AScore, setAScore] = useState(false);
    const [RScore, setRScore] = useState(false);
    const [TScore, setTScore] = useState(false);
    const [SExplanation, setSExplanation] = useState(false);
    const [MExplanation, setMExplanation] = useState(false);
    const [AExplanation, setAExplanation] = useState(false);
    const [RExplanation, setRExplanation] = useState(false);
    const [TExplanation, setTExplanation] = useState(false);
    const [goal1, setGoal1] = useState("");
    const [goal2, setGoal2] = useState("");
    const [goal3, setGoal3] = useState("");

    const waitFor = delay => new Promise(resolve => setTimeout(resolve, delay));


    const onSave = async () => {
        props.isLoading(true);
        const title = document.getElementById('JourneyTitle').value;
        const targetPostRate = document.getElementById('JourneyTargetPostRate').value;
        const goal = document.getElementById('JourneyGoal').value;
        const context = document.getElementById('JourneyContext').value;
        const tags = document.getElementById('JourneyTags').value;
        const visibility = document.getElementById("JourneyVisibility").value;
        const journeyID = uuid()
        const requestID = uuid();

        var journey;


        try {
            const body = {
                Age: props.user ? props.user.DOB : "unknown",
                Tags: tags,
                Description: goal,
                Gender: props.user ? props.user.Gender : "unknown",
                Context: context,
                RequestID: requestID,
                RequestTarget: "GenerateTemplateJourneyFromChatGPT"
            }

            var loop = 0
            while (loop < 12) {
                var response = await Axios.post(process.env.REACT_APP_APIURL + "/CheckLLMLambdaStatus", body)
                if (response.data.RequestStatus === "SUCCEEDED" || response.data.RequestStatus === "FAILED") {
                    break;
                }
                await waitFor(5000)
                loop++;
            }
            if (loop < 12) {
                if (props.user !== null && response.data.RequestStatus === "SUCCEEDED") {
                    journey = response.data.RequestOutput;
                }
                else {
                    throw new Error(response.data.RequestOutput)
                }
            }
            else {
                
            }

            const body2 =
            {
                journeyID: journeyID,
                title: title,
                targetPostRate: targetPostRate,
                description: goal,
                context: context,
                tags: tags,
                visibility: visibility,
                communityID: communityID,
                status: "active",
                personality: "none",
                journey: journey
            };

           const { idToken } = (await fetchAuthSession()).tokens ?? {};
            let config = {headers: {"Authorization": idToken.toString()}};

            await Axios.post(process.env.REACT_APP_APIURL + '/SaveJourney', body2, config);

            window.location.href = "/journeys?id=" + journeyID;
        }
        catch (err) {
            console.log(err)
        }
        finally {
            props.isLoading(false);
        }
    };

    const expandForm = async () => {
        try {
            props.isLoading(true)
            const goal = document.getElementById('JourneyGoal').value;
            const context = document.getElementById('JourneyContext').value;
            const body = {
                goal: goal,
                context: context
            }
            const result = await Axios.post(process.env.REACT_APP_APIURL + '/GetImprovedGoalSuggestions', body);
            var g1 = false;
            var g2 = false;
            result.data.forEach((x) => {
                //Segments for the score sections
                const segments = x.split("|");

                

                if (segments.length > 1 && isMounted.current) {

                    switch (segments[0]) {
                        case "[S]":
                            setSScore(segments[1].includes("Yes"));
                            setSExplanation(segments[2]);
                            break;
                        case "[M]":
                            setMScore(segments[1].includes("Yes"));
                            setMExplanation(segments[2]);
                            break;
                        case "[A]":
                            setAScore(segments[1].includes("Yes"));
                            setAExplanation(segments[2]);
                            break;
                        case "[R]":
                            setRScore(segments[1].includes("Yes"));
                            setRExplanation(segments[2]);
                            break;
                        case "[T]":
                            setTScore(segments[1].includes("Yes"));
                            setTExplanation(segments[2]);
                            break;
                        default:
                            break;
                    }
                }

                const goals = x.split(":")
                if (goals.length > 1 && segments.length < 2 && isMounted.current) {
                    if (!g1) {
                        setGoal1(goals[1])
                        g1 = true;
                    }
                    else if (!g2) {
                        setGoal2(goals[1])
                        g2 = true;
                    }
                    else {
                        setGoal3(goals[1])
                    }
                }
            })

        }
        catch (e) {
            console.log(e)
        }
        finally {
            props.isLoading(false)
            if(isMounted.current)
            {
                setExpandedForm(true)
            }
        }
    }

    const copyGoal = (goal) => {
        document.getElementById('JourneyGoal').value = goal;
    }

    return (
        <div className="u-center" style={{ paddingBottom: "24px" }}>
            <div style={{ width: "95%" }}>
                <div className="card" style={{ marginTop: "24px" }}>
                    <SMARTpopup scores={[SScore, MScore, AScore, RScore, TScore]} explanations={[SExplanation, MExplanation, AExplanation, RExplanation, TExplanation]}></SMARTpopup>
                    <div className="card__header">
                        <h2 className="u-center" style={{ marginTop: "1%", marginBottom: "1%", textAlign: "center" }}>Create New {communityID ? "Community" : ""} Journey</h2>
                    </div>
                    <div style={{ marginLeft: "2%", marginRight: "2%" }}>
                        <div className="u-flex row">
                            <div className="col-2">
                                <div className="input-control">
                                    <p><b>Title:</b>
                                        <Tooltip placement="right" trigger={['hover']} id="TitleToolTip" overlayStyle={{ zIndex: 1000, width: "20%" }} overlay={<span>This will be the name of your journey and will appear at the top of posts.</span>}>
                                            <span className="icon"><i className="fa fa-wrapper fa-question-circle" id="TitleQIcon" aria-hidden="true"></i></span>
                                        </Tooltip></p>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="input-control">
                                    <input maxLength={25} id="JourneyTitle"></input>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="input-control">
                                    <p><b>Target Post Rate:</b>
                                        <Tooltip placement="right" trigger={['hover']} id="RateTip" overlayStyle={{ zIndex: 1000, width: "20%" }} overlay={<span>How often do you hope to update this journey?</span>}>
                                            <span className="icon"><i className="fa fa-wrapper fa-question-circle" id="RateIcon" aria-hidden="true"></i></span>
                                        </Tooltip></p>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="input-control">
                                    <select className="u-pull-right" id="JourneyTargetPostRate" type="text" style={{ width: "100%" }}>
                                        <option value="Daily">Daily</option>
                                        <option value="Biweekly">Biweekly</option>
                                        <option value="Weekly">Weekly</option>
                                        <option value="Fortnightly">Fortnightly</option>
                                        <option value="Monthly">Monthly</option>
                                    </select>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginLeft: "2%", marginRight: "2%" }}>
                        <div className="u-flex row">
                            <div className="col-2">
                                <div className="input-control">
                                    <p><b>Tags:</b>
                                        <Tooltip placement="right" trigger={['hover']} id="TagsTip" overlayStyle={{ zIndex: 1000, width: "20%" }} overlay={<span>Tags will allow users to quickly search for your journeys based on categories in the future. Separate different tags with commas.</span>}>
                                            <span className="icon"><i className="fa fa-wrapper fa-question-circle" id="TagsIcon" aria-hidden="true"></i></span>
                                        </Tooltip></p>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="input-control">
                                    <input multiple={true} id="JourneyTags"></input>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="input-control">
                                    <p><b>Visibility:</b>
                                        <Tooltip placement="right" trigger={['hover']} id="VisibilityTip" overlayStyle={{ zIndex: 1000, width: "20%" }} overlay={<span>Private journeys can only be seen by the user or community they are created by. Post from private journeys also won't appear in feeds.</span>}>
                                            <span className="icon"><i className="fa fa-wrapper fa-question-circle" id="TagsIcon" aria-hidden="true"></i></span>
                                        </Tooltip></p>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="input-control">
                                    <select className="u-pull-right" id="JourneyVisibility" type="text" style={{ width: "100%" }}>
                                        <option value="Public">Public</option>
                                        <option value="Private">Private</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginLeft: "2%", marginRight: "2%" }}>
                        <div className="u-flex row">
                            <div className="col-3">
                                <div className="input-control">
                                    <p><b>The story so far:</b></p>
                                </div>
                            </div>
                            <div className="col-9">
                                <div className="input-control">
                                    <textarea style={{ margin: 0 }} placeholder="Tell us where you're starting your journey to improve AI generated suggestions and help other users understand your story!" id="JourneyContext"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginLeft: "2%", marginRight: "2%" }}>
                        <div className="u-flex row">
                            <div className="col-3">
                                <div className="input-control">
                                    <p><b>The Goal:</b></p>
                                </div>
                            </div>
                            <div className="col-9">
                                <div className="input-control" style={{ margin: 0 }}>
                                    <textarea placeholder="What is your goal? Where will this journey lead?" id="JourneyGoal"></textarea>
                                </div>
                                <div className="u-center" style={{ justifyContent: "space-between", margin: 0, display: expandedForm ? "flex" : "none" }}>
                                    <div className="col-6" style={{ display: "flex" }}>
                                        <div className="input-control" style={{ margin: 0 }}>
                                            <p style={{ margin: 0 }}><b>Goal Strength:</b></p>
                                        </div>
                                        <div className="input-control" style={{ margin: 0 }}>
                                            <button style={{ fontSize: "xx-small", padding: -0, marginLeft: "10px" }} onClick={() => expandForm()}><i className="fas fa-sync-alt fa-lg"></i></button>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <ProgressBar height={20} bgColor="#F18b81" progress={((SScore + MScore + AScore + RScore + TScore) / 5) * 100}></ProgressBar>
                                    </div>
                                </div>
                                <p style={{ margin: 0, marginLeft: "0.5rem", fontSize: "0.8rem", color: "#676767", display: expandedForm ? "block" : "none" }}>Read more about SMART goal setting and how we calculated this strength
                                    <a href="#SMART" style={{ color: "#676767", textDecoration: "underline" }}><b>here.</b></a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginLeft: "2%", marginRight: "2%", display: expandedForm ? "flex" : "none" }}>
                        <div className="u-flex row">
                            <div className="col-4">
                                <div className="input-control" style={{ margin: "0" }}>
                                    <p style={{ marginBottom: "0" }}><b>Alternative Goal Suggestions:</b></p>
                                </div>
                                <div>
                                    <p style={{ margin: 0, fontSize: "0.8rem", color: "#676767", display: expandedForm ? "block" : "none" }}>Click on any of these to copy above</p>
                                </div>
                            </div>
                            <div className="col-8">
                                <div className="input-control suggested-goal-btn" onClick={() => copyGoal(goal1)}>
                                    {goal1}
                                </div>
                                <div className="input-control suggested-goal-btn" onClick={() => copyGoal(goal2)}>
                                    {goal2}
                                </div>
                                <div className="input-control suggested-goal-btn" onClick={() => copyGoal(goal3)}>
                                    {goal3}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: !expandedForm ? "block" : "none" }}>
                        <div style={{ marginLeft: "2%", marginRight: "2%", display: "flex", width: "100%", justifyContent: "center" }}>
                            <button className="log-in-button" style={{ marginLeft: "1%", float: "right", width: "unset" }} onClick={expandForm}>Next<span className="fa fa-chevron-down" style={{ marginLeft: "10px" }}></span></button>
                        </div>
                    </div>
                    <div style={{ display: expandedForm ? "block" : "none" }} >
                        <div style={{ marginLeft: "2%", marginRight: "2%", display: "flex", width: "100%", justifyContent: "center" }}>
                            <button className="log-in-button" style={{ marginLeft: "1%", float: "right", width: "unset" }} onClick={onSave}>Create Journey</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return { user: state.user };
}

export default connect(mapStateToProps, actions)(NewJourney);
