import React, { memo } from 'react';
import '../../../Styles/Journeys/JourneyNodes.css';
import { Handle } from 'reactflow';

export default memo(({ data, isConnectable }) => {

  return (
    <>
      <div className='nodeWrapper'>
        <span id='nodeLabel'>{data.label}</span>
      </div>
      <Handle
        type="source"
        position="right"
        style={{ background: '#555'}}
        isConnectable={isConnectable}
      />
      <Handle
        type="target"
        position="right"
        style={{ background: '#555'}}
        isConnectable={isConnectable}
      />
    </>
  );
});
