import React, { useState,  } from "react";
import ReactFlow from 'reactflow';
import milestoneNode from './Journeys/MileStoneNode';
import milestoneNodeWithPost from './Journeys/MileStoneNodeWithPost';
import beginningNode from './Journeys/BeginningNode';
import goalNode from './Journeys/GoalNode';
import PebbleEdge from './Journeys/PebbleEdges';
import { connect } from "react-redux";
import * as actions from "../../actions";
import "rc-tooltip/assets/bootstrap.css";


const nodeTypes = {
  milestoneNode: milestoneNode,
  milestoneNodeWithPost: milestoneNodeWithPost,
  beginningNode: beginningNode,
  goalNode: goalNode,
};

const edgeTypes = {
  PebbleEdge: PebbleEdge
}

function JourneyPopUp(props) {
  const [rfInstance, setRfInstance] = useState(null);

  return (
    <div className="card wrapper journey-card" style={{postion:"relative"}}>
      {props.badSelection ? 
      <div className="new-post-error" style={{margin: "0%"}}>
         <p> Cannot add a post to a Milestone that has already been updated.</p>
         <button
                  className="btn-close"
                  onClick={() => props.setBadSelection(false)}
                ></button>
      </div> :  <div className="new-post-info" style={{margin: "0%"}}>
        <p> Select a Path or Milestone to add your Post</p>
        <button style={{background: "none", border:"none", margin: 0, padding:0}} onClick={() => props.setAddingToJourney(false)}><span className="fas fa-arrow-left"></span></button>
      </div>
      }
      <ReactFlow 
              nodes={props.nodes}
              edges={props.edges}
              onNodeClick={props.onNodeClick}
              onEdgeClick={props.onEdgeClick}
              onInit={setRfInstance}
              nodeTypes={nodeTypes}
              edgeTypes = {edgeTypes}
              fitView
              attributionPosition="top-right"
              zoomOnDoubleClick={true}
              style={{postion: "sticky"}}
            />
    </div>
  );
};

function mapStateToProps(state) {
  return { user: state.user };
};

export default connect(mapStateToProps, actions)(JourneyPopUp);
