import 'rc-tooltip/assets/bootstrap.css';
import  { fetchAuthSession } from"aws-amplify/auth";
import Axios from "axios";

const logError = async(err) =>{
    try{
       const { idToken } = (await fetchAuthSession()).tokens ?? {};
        const body = {message:err.message, name:err.name, cause:err.cause, stack:err.stack};
        
        let config = {headers: {"Authorization": idToken ? idToken.toString() : ""}};
        await Axios.post(process.env.REACT_APP_APIURL + '/LogError', body, config);
    }
    catch(e){
        ;
        console.log(e);
    }
  }

export default logError