import Axios from "axios";
import { FETCH_USER, PROTO_ACCESS, IS_LOADING, GET_FEED } from "./types";
import { fetchAuthSession } from "aws-amplify/auth";
import { v4 } from "uuid";

export const fetchUser = (firstLogIn = false) => {
  return async function(dispatch) {
    var payload = false;
    try {
      const now = new Date();
      const timezoneOffsetInMiliseconds = now.getTimezoneOffset() * 60 * 1000;
     const { idToken } = (await fetchAuthSession()).tokens ?? {};
      let config = {headers: {"Content-Type" : "application/json", "Authorization": idToken.toString()}};
      var response = await Axios.get(process.env.REACT_APP_APIURL + "/GetCurrentUser?v=" + v4() + "&firstlogin=" + firstLogIn.toString() + "&timezoneoffset=" + timezoneOffsetInMiliseconds.toString(), config);
      payload = response.data;
    } catch (e) {
      
      console.log(e.message)
    }
    finally{
      dispatch({type: FETCH_USER, payload: payload});
    }
  }
}

export const protoAccess = (passcode) => {
  return async function (dispatch)
  {
    const body = {"passcode" : passcode};
    let config = {headers: {"Content-Type" : "application/json"}};
    const response = await Axios.post(process.env.REACT_APP_APIURL + "/BetaScreen", body, config);
    dispatch({type: PROTO_ACCESS, payload: response.data.permission})
  }
}

export const isLoading = (setLoading) => {
  return async function (dispatch)
  {
    dispatch({type: IS_LOADING, payload: setLoading})
  }
}

export const getFeed = () => {
  return async function (dispatch)
  {
    var result = false;
    var feedData;
    try {
     const { idToken } = (await fetchAuthSession()).tokens ?? {};
      let config = {headers: {"Content-Type" : "application/json", "Authorization": idToken.toString()}};
      result = await Axios.get(process.env.REACT_APP_APIURL + "/GetFeedPosts?v=" + v4() , config);
      if (result){
        feedData = {
          content: result.data,
          retrievalTime: Date.now()
        }
      }
      
    } catch (e) { // catch no user error
      if (e === "No current user"){
        try{
          result = await Axios.get(process.env.REACT_APP_APIURL + "/GetFeedPosts?v=" + v4());
          if (result){
            feedData = {
              content: result.data,
              retrievalTime: Date.now()
            }
          }
        } catch (e){
          console.log(e);
        }
      } else console.log(e)
    }
    dispatch({type: GET_FEED, payload: feedData})
  }
}