import React, { useEffect, useState, useRef, useCallback } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { s3FileUpload } from "../IOFunctions/S3Files";
import "rc-tooltip/assets/bootstrap.css";
import { v4 as uuid } from "uuid";
import  { fetchAuthSession } from"aws-amplify/auth";
import Axios from "axios";
import { useLocation } from "react-router-dom";
import ReactPlayer from "react-player";
import JourneyDropdown from "./JourneyDropdown";
import useAutosizeTextArea from './useAutosizeTextArea';
import JourneyPopUp from "./JourneyPopUp";
import { isLoading } from "../../actions";
import logError from "../LogError";
import {defaultEdges, defaultNodes} from './Journeys/DefaultNodesAndEdges';
import ReactFlow, {
  useNodesState,
  useEdgesState,
} from 'reactflow';
import JSConfetti from 'js-confetti'
import '../../Styles/NewPost.css'

const NewPost = (props) => {
  const isMounted = useRef();
  
  useEffect(() => {
      isMounted.current = true;
      return () => {
      isMounted.current = false;
      };
  }, []);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileURL, setSelectedFileURL] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [postText, setPostText] = useState(props.postText ? props.postText : "");
  const [error, setError] = useState(false);
  const location = useLocation();
  const [addingToJourney, setAddingToJourney] = useState(false);
  const postTextAreaRef = useRef()
  const [selectedJourney, setSelectedJourney] = useState(null); // {JourneyID: string containing journey id, Title: string containing journey title}
  const [rfInstance, setRfInstance] = useState(null);
  const [tempStorage, setTempStorage] = useState('');
  const [nodes, setNodes, onNodesChange] = useNodesState(defaultNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(defaultEdges);
  const [savingPost, setSavingPost] = useState(false);
  const [milestonePost, setMilestonePost] = useState(false)
  const postedPopup = document.getElementById("postedPopup");
  const jsConfetti = new JSConfetti({postedPopup});
  const [badSelection, setBadSelection ] = useState(false)

  useAutosizeTextArea(postTextAreaRef.current, postText)

  useEffect(() => {
    if (props.triggerPost){
      
      props.setTriggerPost(false)
      addPost()
    }
  }, [props.triggerPost])  
  

  useEffect(() => {
    if (props.triggerPost){
      props.setTriggerPost(false)
      addPost()
    }
  }, [props.triggerPost])    

  useEffect(() => {
    setPostText(props.postText ? props.postText : "");
    setSelectedFile(null);
    setSelectedFileURL(null);
    setSelectedFileType(null);
    setAddingToJourney(false);
    setError(false);
    setSelectedJourney(props.journeyID && props.journeyTitle? {JourneyID: props.journeyID, Title: props.journeyTitle} : null)
    setSavingPost(false);
  }, [props.journeyID, props.journeyTitle, props.postText]);

  const removePicture = () => setSelectedFile(null);

  const handleFileInput = (e) => {
    if (
      e.target.files[0].type.includes("image") ||
      e.target.files[0].type.includes("video")
    ) {
      setSelectedFileURL(URL.createObjectURL(e.target.files[0]));
      setSelectedFile(e.target.files[0]);
      setSelectedFileType(
        e.target.files[0].type.substring(0, e.target.files[0].type.indexOf("/"))
      );
    } else {
      setSelectedFile(false);
    }
  };

  const addPost = () => {
    if (props.edgeID){
    // adding from EditEdge pop-up, meaning the user has already selected an edge
      post(props.edgeID);
    } 
    else if (props.nodeID){
      
      post(props.nodeID)
    }
    else if (props.setAddingPost){
    // adding from textbox under journey on the main Journey page
      props.setAddingPost(true)
      window.location.href="#!";
    }
    else if(selectedJourney !== null){
      setAddingToJourney(true);
      getExistingJourney();
    }
    else{
      setError("Please Select a Journey")
    }
  }

  const isEmptyObject = (object) =>{
    return JSON.stringify(object) === '{}'
  }

  const getExistingJourney = useCallback(async () => 
  {
    if (selectedJourney !== null && selectedJourney.JourneyID) {
      try {
        isLoading(true);
        if (props.user){
         const { idToken } = (await fetchAuthSession()).tokens ?? {};
          let config = {headers: {"Authorization": idToken.toString()}};
          const response = await Axios.get(process.env.REACT_APP_APIURL + "/GetJourney?id=" + selectedJourney.JourneyID + "&v=" + uuid(), config);
          console.log(response)
          if (response.data && !isEmptyObject(response.data) && isMounted.current){
              setTempStorage(response.data.RFInstance);
              setRfInstance(JSON.parse(response.data.RFInstance));
          }
          else{
          }
        }
      } catch(e) {
        console.log(e);
        logError(e);
      } finally {
        isLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [selectedJourney, props.user])

  useEffect(()=>{
    if (rfInstance){
        setEdges(rfInstance.edges.map((edge) => {
          return {
            ...edge,
            data: {
              ...edge.data,
              clickable: false,
              },
          };
      }))
      setNodes(rfInstance.nodes);
  }},[rfInstance, setNodes, setEdges])

  
  const post = async (id) => {
    const postID = uuid();
    var path = "";

    if(selectedFile){
    path =
    `uncompressed/${selectedFileType}s/postAttachment/post/` +
    postID +
    "/" +
    selectedFile.name.replaceAll(/[^a-zA-Z0-9 ]/g, '').replaceAll(' ','');
    }

    try {
      props.isLoading(true)
      var body = {
          postText: postText,
          journeyID: selectedJourney.JourneyID,
          linkedID: id,
          imagePath: path,
          postID: postID,
        };
      if(isMounted.current){
        setSavingPost(true);
        props.isLoading(false)
        jsConfetti.addConfetti()
          .then(() => { props.isLoading(true)
              if (props.refreshJourney){
              props.refreshJourney(id, postID);}
              window.location.href = "#!";
              setSavingPost(false);
              props.isLoading(false)
            });
      }
     const { idToken } = (await fetchAuthSession()).tokens ?? {};
      let config = { headers: { Authorization: idToken.toString() } };
      await Axios.post(
        process.env.REACT_APP_APIURL + "/CreateNewPost",
        body,
        config
      )
      if (selectedFile) {
        await s3FileUpload(selectedFile, path);
      }
    } catch (error) {
      console.log(error);
      if(isMounted.current){
        setError(true);
      }
    }
    finally{
      refresh()
    }
   };

   const refresh = () => {
      setPostText("");
      setSelectedFile(null);
      setSelectedFileURL(null);
      setSelectedFileType(null);
      setAddingToJourney(false);
      setError(false);
      setSelectedJourney(props.journeyID && props.journeyTitle? {JourneyID: props.journeyID, Title: props.journeyTitle} : null)
      setSavingPost(false);
   }

   useEffect(() => {
      if (props.refreshModal){
        refresh()
        props.setRefreshModal(false)
      }
   }, [props.refreshModal])


    // adding Milestone Post
    const onNodeClick = useCallback(
      (event,node) =>{
        if (node.type !== "milestoneNode"){
          setBadSelection(true);
        }
        else
        {
          setBadSelection(false);
          setMilestonePost(true)
          post(node.id)
        }
      },[post, selectedJourney]);
    
  
    // adding regular Post
    const onEdgeClick = useCallback(
      (event, edge) =>{
        setMilestonePost(false)
        post(edge.id);
      }
    ,[post, selectedJourney]);
  

  return (

    <div>
    {savingPost || props.showCongrats? 
      <div id="postedPopup" style={{backgroundColor: "rgba(0,0,0,0)", boxShadow:"none"}}>
        <div className="card">
          <div className='u-center' style={{padding:"1.5rem"}}>
            <h6 style={{marginBottom:"0"}}>{props.nodeID || milestonePost ? "Congratulations on reaching your Milestone!" : "Posted! Way to go! Keep up the progress!"}</h6>
          </div>
        </div>
      </div>
      : <div>
       <div>
          <div className="new-post" style={{minHeight: addingToJourney ? "unset" : "295px"}}>
            <div className="postbox-title">
              <h6>
                <i className="fas fa-edit fa-lg"/> &nbsp; Post an update!
              </h6>
            </div>
            {error ? (
              <div className="new-post-error">
                {
                  error
                }
                <button
                  className="btn-close"
                  onClick={() => setError(false)}
                ></button>
              </div>
            ) : (
              ""
            )}
            {addingToJourney? 
            <div>
              <JourneyPopUp
                setBadSelection={setBadSelection}
                setAddingToJourney={setAddingToJourney}
                journeyID={selectedJourney? selectedJourney.JourneyID:""}
                journeyTitle={selectedJourney? selectedJourney.Title:""}
                nodes={nodes}
                edges={edges}
                badSelection={badSelection}
                onNodeClick={onNodeClick}
                onEdgeClick={onEdgeClick}
              />
            </div>
            : <div><div className="post-container">
              <div className="card__action-bar">
                <div className="tile__container u-center">
                </div>
              </div>

            
            {selectedFile ? (
              <div style={{height: "auto", position:"relative"}}>
                <span className="icon" style={{position:"absolute", top:"0", right:"0", zIndex:"1000", cursor:"pointer"}}>
                  <i
                    className="fa-wrapper fa fa-times"
                    onClick={() => removePicture()}
                  />
                </span>
                {selectedFileType === "image" && (
                  <img
                    src={selectedFileURL}
                    style={{
                      display: "block",
                      margin: "auto",
                      maxHeight: "200px",
                    }}
                    alt=""
                  />
                )}
                {selectedFileType === "video" && (
                  <div className="player-wrapper" style={{position:"relative"}}>
                    <ReactPlayer config={{
                      file: {
                        attributes: { type: "video/mp4", playsInline: true}
                      },
                    }}
                    className = "react-player"
                    width="100%"
                    style={{position:"aboslute", top:"0", left:"0"}}
                    controls
                    url={selectedFileURL}/>
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
            {selectedFile === false ? (
              <div>
                <div className="new-post-error">
                  <p>
                    Error: Cannot recognise file. Please only attach images or videos
                  </p>
                  <button
                    className="btn-close"
                    onClick={() => removePicture()}
                  ></button>
                </div>
              </div>
            ) : (
              ""
            )}
            <div
              style={{ marginLeft: "0", marginRight: "0" }}
            >
              <div className="input-control col-12">
                <div className="u-center" style={{ width: "100%", zIndex:"1"}}>
                  <textarea
                    className="post-content"
                    placeholder="Share your progress!"
                    ref={postTextAreaRef}
                    rows={1}
                    value={postText}
                    id="postText"
                    onChange={(event) => {setPostText(event.target.value)}}>
                  </textarea>
                </div>
              </div>
            </div>
            </div>
            <div>
            <div className="card__footer level" style={{width:"100%"}}>
            <div style={{display: "flex", flexWrap:"wrap"}}>
            <div className="journey-dropdown-div">
                <JourneyDropdown journeyTitle={props.journeyTitle} selectedJourney={selectedJourney} setSelectedJourney={setSelectedJourney} location={location}/>
              </div>
              <div className="media-div">
                      <button className="button postInput"> 
                        <label htmlFor="media-upload" style={{cursor:"pointer", margin: 0}}>
                            <b>Add Media &nbsp;<i className="fa fa-solid fa-image fa-lg"/> </b>
                        </label>
                      </button>
                      <input
                      id="media-upload"
                      type="file"
                      style={{ border: "none", display: "none" }}
                      accept="image/*,video/*"
                      onChange={(event) => handleFileInput(event)}
                      />
                </div>
                <div className="publish-div">
                  <button
                  className="button postInput publish"
                  onClick={() => addPost()}>
                  <b>Publish</b>
                </button>
              </div>
              </div>
            </div>
            </div>
          </div>}
        </div>
      </div>
    </div>} </div>
    );
};

function mapStateToProps(state) {
  return { user: state.user };
}

export default connect(mapStateToProps, actions)(NewPost);
