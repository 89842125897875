import React, { useState, useEffect, useRef } from 'react';
import Axios from 'axios';
import "../../Styles/SignUp.css";
import { signUp } from 'aws-amplify/auth';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import 'rc-tooltip/assets/bootstrap.css';
import LogInToggle from './LogInToggle';

function SignUp(props) {
  const isMounted = useRef();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [emailValid, setEmailValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordCheckValid, setPasswordCheckValid] = useState(false);
  const [usernameValid, setUsernameValid] = useState(false);
  const [DOBValid, setDOBValid] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [usernameToolTipVisible, setUsernameToolTipVisible] = useState(false);
  const [usernameToolTipText, setUsernameToolTipText] = useState("");
  const [emailToolTipVisible, setEmailToolTipVisible] = useState(false);
  const [passwordToolTipVisible, setPasswordToolTipVisible] = useState(false);
  const [passwordCheckToolTipVisible, setPasswordCheckToolTipVisible] = useState(false);
  const [DOBToolTipVisible, setDOBToolTipVisible] = useState(false);
  const [documentsValid, setDocumentsValid] = useState(false);
  const [errorBanner, setErrorBanner] = useState(false);
  const [genderSelected, setGenderSelected] = useState(false);

  const SignUpSubmit = async () => {
    props.isLoading(true);
    var username = document.getElementById('SignUpUsername').value;
    var password = document.getElementById('SignUpPassword').value;
    var email = document.getElementById('SignUpEmail').value;
    var firstName = document.getElementById('SignUpFirstName').value;
    var lastName = document.getElementById('SignUpLastName').value;
    var dob = document.getElementById('SignUpDob').value;
    var gender = document.getElementById('SignUpGender').value;
    var documentsAccepted = document.getElementById('documentCheckbox').checked.toString();

    try {
      //The user attributes passed here are intercepted by a lamba function
      //Only the email gets stored in cognito, the rest is rerouted to db storage
      const res = await signUp({
        username: username,
        password: password,
        options: {
          userAttributes:
          {
            email: email,
            given_name: firstName,
            family_name: lastName,
            birthdate: dob,
            gender: gender,
            zoneinfo: documentsAccepted
          }
        }
      });
      console.log(res);
      const body = { "username": username };
      let config = { headers: { "Content-Type": "application/json" } };
      await Axios.post(process.env.REACT_APP_APIURL + '/SetProfileDisplayName', body, config);
      window.location.href = '/auth/EmailPending'
    } catch (error) {
      if (isMounted.current) {
        setErrorBanner(error.message);
      }
    }
    finally {
      props.isLoading(false);
    }
  }

  useEffect(() => {
    setFormValid(usernameValid && emailValid && passwordValid && DOBValid && documentsValid && passwordCheckValid && genderSelected)
  }, [usernameValid, emailValid, passwordValid, DOBValid, documentsValid, passwordCheckValid, genderSelected])

  const CheckUsernameValid = async () => {
    const username = document.getElementById('SignUpUsername').value;
    if (username !== "") {
      const regex = /^[A-Za-z0-9]+$/;

      var validUsername = regex.test(username);
      if (!validUsername && isMounted.current) {
        setUsernameToolTipText("Please enter a valid username that contains no special characters.")
      }
      else if (isMounted.current) {
        const body = { "username": username.toLowerCase() };
        let config = { headers: { "Content-Type": "application/json" } };
        const response = await Axios.post(process.env.REACT_APP_APIURL + '/CheckUsernameAvailability', body, config);
        validUsername = response.data.validUsername;
        if (!validUsername) {
          setUsernameToolTipText("That username is already taken.")
        }
      }
      if (!validUsername && isMounted.current) {
        document.getElementById('SignUpUsername').style.borderColor = 'red';
        setUsernameToolTipVisible(true);
        setUsernameValid(false);
      }
      else if (isMounted.current) {
        setUsernameValid(true);
      }
    }
    else if (isMounted.current) {
      setUsernameValid(false);
    }
  }

  const ClearUsernameFieldError = () => {
    document.getElementById('SignUpUsername').style.borderColor = '#dee2e6';
    setUsernameToolTipVisible(false);
  }

  const ClearEmailFieldError = () => {
    document.getElementById('SignUpEmail').style.borderColor = '#dee2e6';
    setEmailToolTipVisible(false);
  }

  const ClearPasswordFieldError = () => {
    document.getElementById('SignUpPassword').style.borderColor = '#dee2e6';
    document.getElementById('SignUpEyeIcon').style.marginRight = '0';
    setPasswordToolTipVisible(false);
    ClearPasswordCheckFieldError();
  }

  const ClearPasswordCheckFieldError = () => {
    document.getElementById('SignUpPasswordCheck').style.borderColor = '#dee2e6';
    document.getElementById('SignUpEyeCheckIcon').style.marginRight = '0';
    setPasswordCheckToolTipVisible(false);
  }

  const ClearDOBFieldError = () => {
    document.getElementById('SignUpDob').style.borderColor = '#dee2e6';
    document.getElementById('SignUpDob').style.paddingRight = '4%';
    setDOBToolTipVisible(false);
  }


  const CheckEmailValid = (e) => {
    const regex = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;
    var currentValue = document.getElementById('SignUpEmail').value;
    const validEmail = regex.test(currentValue);
    if (!validEmail) {
      document.getElementById('SignUpEmail').style.borderColor = 'red';
      setEmailToolTipVisible(true);
      setEmailValid(false);
    }
    else {
      setEmailValid(true);
    }
  }

  const CheckPasswordValid = () => {
    const password = document.getElementById('SignUpPassword').value;
    const passwordCheck = document.getElementById('SignUpPasswordCheck').value;


    if (password.length >= 10) {
      setPasswordValid(true)
    }
    else {
      document.getElementById('SignUpPassword').style.borderColor = 'red';
      setPasswordToolTipVisible(true);
      setPasswordValid(false);
    }

    if (passwordCheck !== '') {
      CheckPasswordCheckValid()
    }
  }


  const CheckPasswordCheckValid = () => {
    const password = document.getElementById('SignUpPassword').value;
    const passwordCheck = document.getElementById('SignUpPasswordCheck').value;

    if (password === passwordCheck) {
      setPasswordCheckValid(true)
    }
    else {
      document.getElementById('SignUpPasswordCheck').style.borderColor = 'red';
      setPasswordCheckToolTipVisible(true);
      setPasswordCheckValid(false);
    }
  }

  const CheckDOBValid = async () => {
    const dateCutoff = new Date();
    dateCutoff.setFullYear(dateCutoff.getFullYear() - 13);

    var dobString = document.getElementById('SignUpDob').value;

    if (dateCutoff < new Date(dobString)) {
      document.getElementById('SignUpDob').style.borderColor = 'red';
      setDOBValid(false);
      setDOBToolTipVisible(true);
    }

    else {
      setDOBValid(true);
    }
  }

  const TogglePasswordVisibility = () => {
    var password = document.getElementById('SignUpPassword');
    var eyeIcon = document.getElementById('SignUpEyeIcon');

    if (password.type === "password") {
      password.type = "text";
      eyeIcon.classList.remove('fa-eye-slash');
      eyeIcon.classList.add('fa-eye');
    }

    else {
      password.type = "password";
      eyeIcon.classList.remove('fa-eye');
      eyeIcon.classList.add('fa-eye-slash');
    }
  }

  const TogglePasswordCheckVisibility = () => {
    var password = document.getElementById('SignUpPasswordCheck');
    var eyeIcon = document.getElementById('SignUpEyeCheckIcon');

    if (password.type === "password") {
      password.type = "text";
      eyeIcon.classList.remove('fa-eye-slash');
      eyeIcon.classList.add('fa-eye');
    }

    else {
      password.type = "password";
      eyeIcon.classList.remove('fa-eye');
      eyeIcon.classList.add('fa-eye-slash');
    }
  }

  if (props.hidden !== "hidden") {
    return (
      <div className="sign-up-wrapper">
        <LogInToggle></LogInToggle>
        <div className="login-label" style={{ position: "relative" }}>
          <b>First name</b>
          <b style={{ position: "absolute", left: "51%" }}>Last name</b>
        </div>
        <div className="input-control">
          <input className="u-pull-left login-input" id="SignUpFirstName" type="text" placeholder="First name" style={{ width: "49%" }} />
          <input className="u-pull-right login-input" id="SignUpLastName" type="text" placeholder="Last name" style={{ width: "49%" }} />
        </div>
        <br />
        <br />
        <div className="login-label" style={{ position: "relative" }}>
          <b>Date of Birth</b>
          <b style={{ position: "absolute", left: "51%" }}>Gender</b>
        </div>
        <div className="input-control" style={{ width: "49%" }}>
          <input className="u-pull-left login-input" id="SignUpDob" style={{ paddingRight: "4%" }} type="date" onBlur={() => CheckDOBValid()} onChange={() => ClearDOBFieldError()} />
          <div className="field-error" style={{ display: DOBToolTipVisible ? 'flex' : 'none', position: "absolute", top: "54px", width: "200%" }}>
            <div style={{ color: "red", display: DOBToolTipVisible ? 'block' : 'none' }} className="fa fa-wrapper fa-exclamation-triangle" id="PasswordIcon" aria-hidden="true"></div>
            <div style={{ zIndex: 1000 }}>{DOBToolTipVisible && "You must be at least 13 years old to join SkyReachers."}</div>
          </div>
        </div>
        <div className="input-control">
          <select className="u-pull-right login-input" defaultValue="" id="SignUpGender" type="text" onChange={() => setGenderSelected(true)} style={{ width: "49%", color: genderSelected ? "black" : "#a9a9a9" }}>
            <option value="" disabled>Gender</option>
            <option value="Male" style={{ color: "black" }}>Male</option>
            <option value="Female" style={{ color: "black" }}>Female</option>
            <option value="Non-Binary" style={{ color: "black" }}>Non-Binary</option>
            <option value="Other" style={{ color: "black" }}>Other</option>
            <option value="Private" style={{ color: "black" }}>Prefer not to say</option>
          </select>
        </div>
        <br />
        <br />
        <div className="login-label" style={{ marginTop: DOBToolTipVisible ? "70px" : "16px" }}>
          <b>Email</b>
        </div>
        <div className="input-control">
          <form autoComplete='off'>
            <input role="presentation" id="SignUpEmail" type="email" className="input-contains-icon-right login-input" placeholder="Email" onChange={() => ClearEmailFieldError()} onBlur={() => CheckEmailValid()} required />
          </form>
          <div className="field-error" style={{ display: emailToolTipVisible ? 'flex' : 'none' }}>
            <div style={{ color: "red" }} className="fa fa-wrapper fa-exclamation-triangle" id="EmailIcon" aria-hidden="true"></div>
            <div style={{ zIndex: 1000 }}>{emailToolTipVisible && "Please enter a valid email address."}</div>
          </div>
        </div>
        <div className="login-label">
          <b>Choose a username</b>
        </div>
        <div className="input-control">
          <input id="SignUpUsername" type="text" className="input-contains-icon-right login-input" placeholder="Username" style={{ borderColor: "#dee2e6" }} onChange={() => ClearUsernameFieldError()} onBlur={() => CheckUsernameValid()} />
          <div className="field-error" style={{ display: usernameToolTipVisible ? 'flex' : 'none' }}>
            <div style={{ color: "red", display: usernameToolTipVisible ? 'block' : 'none' }} className="fa fa-wrapper fa-exclamation-triangle" id="UsernameIcon" aria-hidden="true"></div>
            <div style={{ zIndex: 1000 }}>{usernameToolTipVisible && usernameToolTipText}</div>
          </div>
        </div>
        <div className="login-label">
          <b>Create a password</b>
        </div>
        <div className="input-control">
          <input id="SignUpPassword" className="input-contains-icon-right login-input" type="password" placeholder="Password" onBlur={() => CheckPasswordValid()} onChange={() => ClearPasswordFieldError()} />
          <span className="icon icon-right"><i className="fa fa-wrapper fa-eye-slash" id="SignUpEyeIcon" aria-hidden="true" style={{ pointerEvents: "auto" }} onClick={() => TogglePasswordVisibility()}></i></span>
        </div>
        <div className="field-error" style={{ display: passwordToolTipVisible ? 'flex' : 'none' }}>
          <div style={{ color: "red", display: passwordToolTipVisible ? 'block' : 'none' }} className="fa fa-wrapper fa-exclamation-triangle" id="PasswordIcon" aria-hidden="true"></div>
          <div style={{ zIndex: 1000 }}>{passwordToolTipVisible && "Your password must be at least 10 characters long."}</div>
        </div>
        <div className="login-label">
          <b>Confirm password</b>
        </div>
        <div className="input-control">
          <input id="SignUpPasswordCheck" className="input-contains-icon-right login-input" type="password" placeholder="Confirm password" onBlur={() => CheckPasswordCheckValid()} onChange={() => ClearPasswordCheckFieldError()} />
          <span className="icon icon-right"><i className="fa fa-wrapper fa-eye-slash" id="SignUpEyeCheckIcon" aria-hidden="true" style={{ pointerEvents: "auto" }} onClick={() => TogglePasswordCheckVisibility()}></i></span>
        </div>
        <div className="field-error" style={{ display: passwordCheckToolTipVisible ? 'flex' : 'none', marginBottom: "16px" }}>
          <div style={{ color: "red", display: passwordCheckToolTipVisible ? 'block' : 'none' }} className="fa fa-wrapper fa-exclamation-triangle" id="PasswordIcon" aria-hidden="true"></div>
          <div style={{ zIndex: 1000 }}>{passwordCheckToolTipVisible && "Passwords don't match"}</div>
        </div>
        <input type="checkbox" id="documentCheckbox" onClick={(e) => setDocumentsValid(e.target.checked)} /> I have read and accepted the <a href="#ToS" style={{ textDecoration: "underline" }}>Terms of Service</a> and <a href="#privacyPolicy" style={{ textDecoration: "underline" }}>Privacy Policy</a>.
        {errorBanner ?
          <input className="u-center text-danger input-error" enabled="false" value={errorBanner} readOnly="readonly" id="LoginError" /> : ""}
        <div className="row" style={{ display: "block" }}>
          <button className="log-in-button" disabled={!formValid} onClick={() => SignUpSubmit()}>Sign Up</button>
        </div>
      </div>
    );
  }
  else {
    return null;
  }
}

export default connect(() => { return {} }, actions)(SignUp);
