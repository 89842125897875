import React from "react";
import "../Styles/LoadingSpinner.css";

export default function LoadingSpinner(props) {
  return (
     <div className="spinner-container">
      <div className="loading-spinner">
      </div>
      </div>
  );

}