import {ReactFlowProvider} from 'reactflow';
import Journey from './Journey';

function JourneyWrapper(props)
{
    return <div className="u-center">
            <div style={{width:"95%", marginTop: "12px", marginBottom:"12px"}}>
            <ReactFlowProvider><Journey setShow={props.setShow}/></ReactFlowProvider>
            </div>
        </div>
}

export default JourneyWrapper;