import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { BrowserRouter } from 'react-router-dom';
import { thunk } from 'redux-thunk';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { createRoot } from 'react-dom/client';

import 'cirrus-ui';


import App from './components/App';
//Default returns value from index.js within the reducers folder
import Reducers from './reducers';

Amplify.configure(awsconfig);

const saveState = (state) => {
    try {
        // Convert the state to a JSON string
        const serialisedState = JSON.stringify(state);

        // Save the serialised state to localStorage against the key 'app_state'
        window.localStorage.setItem('app_state', serialisedState);
    } catch (err) {
        console.log(err);
    }
};

const loadState = () => {
    try {
        // Load the data saved in localStorage, against the key 'app_state'
        const serialisedState = window.localStorage.getItem('app_state');

        // Passing undefined to createStore will result in our app getting the default state
        // If no data is saved, return undefined
        if (!serialisedState) return undefined;

        // De-serialise the saved state, and return it.
        return JSON.parse(serialisedState);
    } catch (err) {
        // Return undefined if localStorage is not available,
        // or data could not be de-serialised,
        // or there was some other error
        console.log(err);
        return undefined;
    }
};

//Reducers are for state changes, next input is default state, applyMiddleware is for extra processing of the request
const store = createStore(Reducers, loadState(), applyMiddleware(thunk));

const stripePromise = loadStripe('pk_test_51OG9RYH70xJDa8JBdZp0P31APo0EmnoFbsM3jhqekRFWN5NKBqW77IrSvpE6wIfZ8Khr4CiPsGX8YnXzp7O7jgaF00tctDF7xC');

store.subscribe(() => {
    saveState(store.getState());
});

const root = createRoot(document.querySelector('#root'))

root.render(<Provider store={store}><BrowserRouter><Elements stripe={stripePromise}><App /></Elements></BrowserRouter></Provider>, );
