import React, { useState, useEffect, useRef } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import Axios from 'axios';
import '../../Styles/DailyToDo.css';
import { connect } from 'react-redux';
import * as actions from '../../actions';

const DailyToDo = (props) => {

    const isMounted = useRef();

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    const [completed, setCompleted] = useState([false, false, false, false, false])
    const [taskList, setTaskList] = useState(["Daily Task 1", "Daily Task 2", "Daily Task 3", "Daily Task 4", "Daily Task 5"])

    const fetchDailyToDos = async (body, config) => {

        const response = await Axios.post(
            process.env.REACT_APP_APIURL + "/GetDailyToDo",
            body,
            config)
        if (response.data) {
            response.data.forEach((x) => {

            })
            if (isMounted.current) {
                setTaskList(response.data)
            }

        }

        return response.data;
    }

    useEffect(() => {
        var timeoutID;

        const getDailyToDo = async () => {
            try {
                const { idToken } = (await fetchAuthSession()).tokens ?? {};
                let config = { headers: { Authorization: idToken.toString() } };
                const now = new Date();
                const currentDate = `${String(now.getMonth() + 1).padStart(2, '0')}/${String(now.getDate()).padStart(2, '0')}/${now.getFullYear()}`;
                const body = { date: currentDate }

                const res = await fetchDailyToDos(body, config)

                if (res?.length === 0) {

                    const generationBody = {
                        timePerGoal: props.user.TimeForGoals,
                        tasksPerJourney: props.user.DailyTasksPerJourney
                    }
                    const generationResponse = await Axios.post(
                        process.env.REACT_APP_APIURL + "/GenerateDailyTasks",
                        generationBody,
                        config)

                    if (generationResponse) {
                        timeoutID = setTimeout(() => {
                            fetchDailyToDos(body, config)
                        }, 3000)
                    }
                }
            }
            catch (e) {
                console.log(e)
            }
        }

        getDailyToDo()

        return () => clearTimeout(timeoutID)
    }, [])


    const toggleCompleted = (i) => {
        var newcompleted = [...completed];
        newcompleted[i] = !completed[i];
        setCompleted(newcompleted);
    }

    return (
        <div>
            <div className="small-screen-list">
                <div className="home-mobile-title-wrapper">
                    <b className="to-do-list-title">Daily To-Do</b>
                </div>
                <div>
                    <div className="u-flex to-do-list-wrapper">
                        {taskList.map((x, i) =>
                            <div key={`to do ${i}`} className="col-12" style={{ float: "left", marginTop: "16px", marginBottom: "16px", padding: 0, marginRight: "32px" }}>
                                <div className="to-do-item-container">
                                    <div className='u-center' >
                                        <div style={{ cursor: "pointer" }} onClick={() => toggleCompleted(i)}>
                                            <span className="fas fa-check-square" style={{ color: completed[i] ? "#F18B81" : "#676767" }}></span>&nbsp; {x.description}
                                        </div>
                                        <div>
                                            &nbsp; -{x.expectedTime}
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                    </div>
                </div>
            </div>
            <div className='u-center large-screen-list'>
                <div className="to-do-list-container">
                    <div className='u-center '>
                        <b className='to-do-list-title'> Daily To-Do</b>
                    </div>
                    <div className="to-do-list-divider" />
                    <div style={{ margin: "2%" }}>
                        {taskList.map((x, i) =>
                            <div key={"to-do:" + i} className='u-center' >
                                <div style={{ cursor: "pointer" }} onClick={() => toggleCompleted(i)}>
                                    <span className="fas fa-check-square" style={{ color: completed[i] ? "#F18B81" : "#676767" }}></span>&nbsp; {x.description}
                                </div>
                                <div>
                                    &nbsp; -{x.expectedTime}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return { user: state.user };
}

export default connect(mapStateToProps, actions)(DailyToDo);