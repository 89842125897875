import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';


const SkyeTextChat = (props) => {

    const isMounted = useRef()

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    const [upcomingText, setUpcomingText] = useState("");
    const [profileURL, setProfileURL] = useState("");
    const [skyeChatHeight, setSkyeChatHeight] = useState(0);
    const [skyeChatWidth, setSkyeChatWidth] = useState(0);

    useEffect(() => {
        const screenHeight = window.innerHeight;
        const screenWidth = window.innerWidth;
        if (isMounted.current) {
            setSkyeChatHeight(screenWidth > 1023 ? screenHeight - 355 : screenHeight - 190);
            setSkyeChatWidth(screenWidth > 1024 ? 1000 : screenWidth * 0.9);
        }
    }, [window.innerHeight, window.innerWidth])


    useEffect(() => {
        if (isMounted.current) {
            var element = document.getElementById("message-box");
            element.scrollTop = element.scrollHeight;
        }
    }, [props.textLoading]);


    useEffect(() => {
        const getImage = async () => {
            props.isLoading(true)
            if (props.user.ProfilePictureS3Path && props.user.ProfilePictureS3Path !== "") {
                if (isMounted.current) {
                    setProfileURL(process.env.REACT_APP_CLOUDFRONT + "/" + props.user.ProfilePictureS3Path);
                }
            }
            else {
                if (isMounted.current) {
                    setProfileURL(null)
                }
            }
            props.isLoading(false)
        };
        getImage();
    }, [props.user.ProfilePictureS3Path]);


    return (
        <div className="u-center" style={{ height: skyeChatHeight }}>
            <div className="skye-chat" style={{ height: skyeChatHeight - 20, width: skyeChatWidth }}>
                <div className="message-box" id="message-box" style={{ height: skyeChatHeight - 140, width: skyeChatWidth - 10 }} >
                    {props.messages.map((x) => <div key={x.message} className={`message-container ${x.author !== "Skye" ? "skye-message" : ""}`} style={{ width: skyeChatWidth - 10 }}>
                        <div><img className={`message-profile-icon ${x.author !== "Skye" ? "skye-left" : ""}`} src={props.skyeProfile}></img></div>
                        <div className={`u-flex text-bubble ${x.author !== "Skye" ? "user-message" : ""}`}>{x.author === "Skye" ? JSON.parse(x.message.replace(/```json\n/g, "").replace(/\n```/g, "").trim()).reply : x.message}</div>
                        <div><img className={`message-profile-icon ${x.author !== "Skye" ? "" : "skye-right"}`} src={profileURL}></img></div></div>)}
                    <div className="message-container" style={{ display: props.textLoading ? "flex" : "none", width: skyeChatWidth - 10 }}>
                        <div><img className="message-profile-icon" src={props.skyeProfile}></img></div>
                        <div className='text-bubble' style={{ display: "block" }}>
                            {props.partialText && props.partialText !== "" ? props.partialText : ""}
                            <div style={{ display: "inline-block" }}>
                                <div className="text-loading">
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="message-container u-center" style={{ display: props.endChat ? "flex" : "none" }}>
                        <div className='u-flex text-bubble u-center' style={{ width: "300px" }}>
                            Chat Ended
                        </div>
                    </div>

                </div>
                <div style={{ position: "relative", visibility: props.endChat ? "hidden" : "unset" }}>
                    <textarea onChange={(e) => setUpcomingText(e.target.value)} style={{ margin: 0, borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }} value={upcomingText} placeholder='Type a message to send to Skye'>
                    </textarea>
                    <button className='textInputButton' onClick={() => { props.sendMessage(upcomingText), setUpcomingText("") }}>Submit</button>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return { user: state.user }
}

export default connect(mapStateToProps, actions)(SkyeTextChat);