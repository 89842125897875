import Axios from 'axios';
import  { fetchAuthSession } from"aws-amplify/auth";

export const s3FileUpload = async (file, path = '', isProfile = false, ownerID=null) => 
{
        try {
       const { idToken } = (await fetchAuthSession()).tokens ?? {};
        let config = {headers: {"Authorization": idToken.toString()}};
        var body = 
            {
                "filePath" : path,
                "ownerID": ownerID,
            };
        if(isProfile){
            await Axios.post(process.env.REACT_APP_APIURL + '/UpdateProfilePicture', body, config);
        }
        
        const SignedUrl = await Axios.put(process.env.REACT_APP_APIURL + '/GetS3SignedURLForUpload', body, config);
        
        await Axios.put(SignedUrl.data, file);
        } catch (e) {
        console.log(e.message)
        }
} 

export const s3FileRetrival = async (path = '') =>
{
        
        var response = false;
        try {
        const body = 
            {
                "filePath" : path,
            };
        response = await Axios.post(process.env.REACT_APP_APIURL + '/GetS3SignedURLForDownload', body);
        return response;
        } catch (e) {
        console.log(e.message)
        }
}