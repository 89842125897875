import { IS_LOADING } from '../actions/types'

export default function isLoading(state = null, action)
{
  switch(action.type)
  {
    case IS_LOADING:
      return action.payload || false;

    default:
      return state;
  }
}