import React, { useState, useEffect, useRef }  from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { s3FileUpload } from '../IOFunctions/S3Files';
import 'rc-tooltip/assets/bootstrap.css';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'


function ProfilePhotoUpload(props)
{
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileURL, setSelectedFileURL] = useState(null);
    const [crop, setCrop] = useState({
        unit: 'px',
        height: "200",
        width: "200"
    });   

    const imageRef = useRef();

    var timeoutID;
    
    const handleFileInput = (e) => {
        if(e.target.files[0].type.includes("image")){
        setSelectedFileURL(URL.createObjectURL(e.target.files[0]));
        setSelectedFile(e.target.files[0]);
        }
        else{
        setSelectedFile(false);
        }                                                                                    
    }

    const removePicture = () => setSelectedFile(null); 
    

    const uploadProfilePic = (image, crop) => {
        props.isLoading(true)
        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
    
        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;
    
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width * scaleX,
          crop.height * scaleY
        );

        canvas.toBlob(
            (blob) => {
            if (!blob) {
                console.error('Canvas is empty');
                return;
            }
            const newImage = new File([blob], selectedFile.name, { type: blob.type });
            uploadFile(newImage);
            },
            'image/jpeg',
            1
        );
    }

    const uploadFile = async (file) => {
        props.isLoading(true);
        const path = 'uncompressed/images/profilePicture/' + props.profileType + '/' + props.profileID + "/" + file.name.replaceAll(/[^a-zA-Z0-9 ]/g, '').replaceAll(' ','');
        await s3FileUpload(file, path, true, props.profileID);
        timeoutID = setTimeout(async() => {await props.fetchUser();
        props.isLoading(false); 
        window.location.href = '#!'
        window.location.reload(true);
        } , 1000);
    }        
    
    useEffect(() => {
        return () => clearTimeout(timeoutID)
    }, [])

    return (
        <div>
             <div className="modal modal-animated--zoom-in" id="uploadProfilePic" style={{backgroundColor:"rgb(0,0,0,0.5)", zIndex: 1001}}>
             <div className="modal-content" role="document" style={{width:"-webkit-fill-available"}}>
             <a href="#!" aria-label="Close">
              <span className="icon u-position-relative u-pull-right">
                  <i className="fa-wrapper fa fa-times"/>
              </span>
              </a>
              {selectedFile === false ? 
                    <div>
                    <div className="toast toast--danger" style={{width: "95%"}}>
                        <p>Error: Cannot recognise file. Please only attach images.</p>
                        <button className="btn-close" onClick={() => removePicture()}></button>
                    </div>
                    </div> : ""}
              {selectedFile ? 
                    <div className="u-center">
                        <ReactCrop crop={crop} onChange={c => setCrop(c)} locked={false} circularCrop={true} aspect={1}>                        
                        <i className="fa-wrapper fa fa-times" style={{color:"darkblue", position:"absolute", right:0}} onClick={() => removePicture()}/>
                        <img ref={imageRef} src={selectedFileURL} style={{display:"block", margin:"auto", maxHeight: "80vh", maxWidth: "80vw"}} alt=""/>
                        </ReactCrop>
                    </div> : 
                    <label className="fileSelection text-gray-600 btn--xl btn"> Choose Picture
                <input type="file" style={{border:"none", display: "none"}} onChange={(event) => handleFileInput(event)}></input>
                </label>}
                <button className="btn-transparent u-center" onClick={() => uploadProfilePic(imageRef.current, crop)}>Upload Picture</button>
                </div>
             </div>
        </div>                
    )
}

function mapStateToProps(state) {
    return { user: state.user };
}

export default connect(mapStateToProps, actions)(ProfilePhotoUpload);
