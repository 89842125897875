import React, {useState} from 'react';
import Feed from './UserContent/Feed.js';
import NewPost from './UserContent/NewPost.js';
import TouchDeviceHeader from './TouchDeviceHeader.js';
import SpotLightPost from './UserContent/SpotLightPost.js';
import NewPostModal from './UserContent/NewPostModal.js';

function NewsFeed(props) {

    const [feedCategory, setFeedCategory] = useState("discover")

    return(
        <div>
            <NewPostModal></NewPostModal>
            <TouchDeviceHeader title={"Newsfeed"} setShow={props.setShow} showEditButton={true}></TouchDeviceHeader>
            <div className="u-flex row pc-only"  style={{fontSize:"x-large", marginTop: "16px", marginLeft: "32px"}}>
                <b> NewsFeed </b>
            </div>
            <div className="u-flex row" style={{marginLeft: "16px", marginRight:"16px"}}>
                <div className="col-6"  style={{padding: 0}}>
                    <div className="page-content-top-padding">
                        <div className="horizontal-scroll">
                            <div style={{marginTop:"12px", width:"200%"}}>
                                <button onClick={() => setFeedCategory("discover")} className={`newsfeed-category-button ${feedCategory === "discover" ? "newsfeed-category-button-active" : ""}`}>
                                    <span className="fa fa-star" style={{marginRight:"4px"}}></span>
                                    Discover
                                </button>
                                {/*<button onClick={() => setFeedCategory("following")} className={`newsfeed-category-button ${feedCategory === "following" ? "newsfeed-category-button-active" : ""}`}>
                                    <span className="fa fa-user-plus" style={{marginRight:"4px"}} ></span>
                                    Following
                                </button>
                                /* Add these buttons in once commmunities are released.
                                
                                <button onClick={() => setFeedCategory("community")} className={`newsfeed-category-button ${feedCategory === "community" ? "newsfeed-category-button-active" : ""}`}>
                                    <span className="fa fa-users" style={{marginRight:"4px"}}></span>
                                    Community
                                </button>
                                <button onClick={() => setFeedCategory("trending")} className={`newsfeed-category-button ${feedCategory === "trending" ? "newsfeed-category-button-active" : ""}`}>
                                    <span className="fa fa-chart-line" style={{marginRight:"4px"}}></span>
                                    Trending
                                </button>*/}
                            </div>
                        </div>
                        <div className="pc-only" style={{marginRight: "16px", display:"block !important"}}>
                            <NewPost/>
                        </div>
                    </div>
                </div>
                <div className="col-6" style={{padding: 0}}>
                    <SpotLightPost></SpotLightPost>
                </div>
            </div>
            <div> 
                <div className="u-flex row page-content-top-padding" style={{margin: "16px"}}>
                    <Feed/>
                </div>
            </div>
        </div>
    );
}

export default NewsFeed;