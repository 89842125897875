import React, { useState, useEffect, useRef } from 'react';
import 'rc-tooltip/assets/bootstrap.css';
import Axios from 'axios';
import  { fetchAuthSession } from'aws-amplify/auth';
import { connect } from "react-redux";
import * as actions from "../actions";
import TouchDeviceHeader from './TouchDeviceHeader';


function Contact(props) {
    const isMounted = useRef()

    useEffect(() => {
        isMounted.current = true;
        return () => {
        isMounted.current = false;
        };
    }, []);

    const [message, setMessage] = useState("");
    const [sendState, setSendState] = useState(null)

    const send = async () => {
        props.isLoading(true);
       const { idToken } = (await fetchAuthSession()).tokens ?? {};
        const body = {
            message: message
        }
        let config = {headers: {"Authorization": idToken.toString()}};
        try {
            await Axios.post(process.env.REACT_APP_APIURL + '/SendAppFeedbackEmail', body, config);
            if(isMounted.current){
                setSendState(true)
            }
        }
        catch (e) {
            console.log(e);
            if(isMounted.current){
                setSendState(false)
            }
        }
        finally {
            props.isLoading(false);
        }
    }

    return (
        <div style={{height:"100%"}}>
            <TouchDeviceHeader title={"Contact Us"} setShow={props.setShow}/>
            <div className='centralDiv' style={{ marginTop: "10vh", position:"relative" }}>
                <div className="card u-center">
                    <div className="card-internal-header" style={{ marginTop: "24px"}}>
                        <h5>Contact the SkyReachers Team</h5>
                    </div>
                    <div className="u-text-center pt-3 contact-font" style={{ width: "100%" }}>
                        The easiest way to contact the SkyReachers Team is through our discord server.
                        <br />
                        There we will endeavour to quickly respond to any feedback or bug reports.
                        <br />
                        <div className="u-center" style={{ margin: "12px" }}>
                            <a href="https://discord.gg/MCxrJEp5Av" target="_blank" rel="noopener noreferrer">
                                <button className="outline" style={{ backgroundColor: "#404eed", color: "white" }}>Join Our Discord<span><i className="fab fa-discord fa-lg" /></span></button>
                            </a>
                        </div>
                        We really want to build something that helps people improve their lives but we can't do that without your help and feedback.
                        <br />
                        Otherwise, feel free to use the textbox below and we'll recieve the message!
                    </div>
                    <div style={{ width: "100%" }} className="u-center">
                        <textarea value={message} style={{ width: "95%" }} onChange={(e) => setMessage(e.target.value)}></textarea>
                    </div>
                    <div className='u-center' style={{ marginBottom: "24px", width: "100%" }}>
                        <div>
                            <button className="log-in-button" onClick={() => send()}>Send</button>
                        </div>
                    </div>
                    <div style={{marginTop:"-24px", marginBottom:"24px"}}>
                        {sendState !== null ? (sendState ?
                            <div className="field-error field-success">
                                Message sent successfully
                            </div>
                            :
                            <div className="field-error">
                                Something went wrong and the message was not delivered.
                            </div>
                        ) : ""
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return { user: state.user };
}

export default connect(() => mapStateToProps, actions)(Contact);
