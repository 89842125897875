import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";

function SMARTPopup(props) {

  const Letters = ["S", "M", "A", "R", "T"] 
  
  return (
    <div
      className="modal modal-animated--zoom-in"
      id="SMART"
      style={{ backgroundColor: "rgb(0,0,0,0.5)", zIndex: 2000, padding:"0px" }}
    >
      <div
        className="modal-content"
        role="document"
        style={{ width: "-webkit-fill-available" }}
      >
        <div>
          <div className="card" style={{width:"100%", maxHeight: "90vh", boxShadow:"none"}}>
            <div className="card__header">
              <h3
                className="u-center"
                style={{ marginTop: "1%", marginBottom: "1%" }}
              >
                SMART Goals
              </h3>
              <a href="#!" aria-label="Close">
                <span className="icon" style={{position:"absolute", right:"0px"}}>
                  <i className="fa-wrapper fa fa-times" />
                </span>
              </a>
            </div>
            { Letters.map((x, i) => 
            <div key={i} style={{display:"flex"}}>
                <div className="col-3">
                    <div className="SMART" style={{backgroundColor: props.scores[i] ? "#DFF4DF":  "#ffbebe"}}> 
                        {x}
                    </div>
                </div>
                <div className="col-9">
                    <div className="u-center" style={{fontSize:"small", marginTop:"10px", lineHeight:"normal"}}>
                    {props.explanations[i]}
                    </div>
                </div>
            </div>
                )
            }
                </div>
          </div>
        </div>
      </div>
  );
}

function mapStateToProps(state) {
  return { user: state.user };
}

export default connect(mapStateToProps, actions)(SMARTPopup);
