import React, { useState, useEffect, useRef } from 'react';
import "../Styles/ChooseSubscription.css";
import { connect } from 'react-redux';
import * as actions from '../actions';
import Axios from 'axios';
import { fetchAuthSession } from "aws-amplify/auth";
import CheckoutForm from './Stripe';
import { useLocation } from 'react-router-dom';
import { v4 } from 'uuid';


const SubscriptionPage = (props) => {
  const isMounted =  useRef()

  useEffect(() => {
      isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);


  const [promoCode, setPromoCode] = useState('');
  const [error, setError] = useState(null);
  const [stripeProps, setStripeProps] = useState(null);
  const [stripeCustomerID, setStripeCustomerID] = useState(null);
  const [currentSubscription, setCurrentSubscription] = useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const updatePayment = searchParams.get("updatePayment");
  const paymentIntentClientSecret = searchParams.get("payment_intent_client_secret")
  const isUpdate = searchParams.get("update")


  const tiers = [
    {
        id: 'Visionary',
        symbol: "/images/Moon.webp",
        price: 'FREE',
        priceValue: 0,
        priceID: process.env.REACT_APP_STRIPEVISIONARY,
        perks: [
            { text: 'SMART Goal Setting Aide', ready: true },
            { text: '1 Active Journey', ready: true},
            { text: 'Choice of 5 App Personalities for Reminders', ready: false },
            { text: 'Can Join Journey Communities', ready: false }
        ]
    },
    {
        id: 'Stargazer',
        symbol: "/images/MoonStars.webp",
        price: '$4.99 USD/month',
        priceValue: 499,
        priceID: process.env.REACT_APP_STRIPESTARGAZER,
        perks: [
            { text: 'Unlimited Active Journeys', ready: true },
            { text: 'Automated To-do Lists', ready: true},
            { text: 'Custom Reminder Personalities', ready: false },
            { text: 'Can Create and Join Collaborative Journeys', ready: false },
        ]
    },
    {
        id: 'Dreamer',
        symbol: "/images/MoonStarsPlanets.webp",
        price: '$9.99 USD/month',
        priceValue: 999,
        priceID: process.env.REACT_APP_STRIPEDREAMER,
        perks: [
            { text: 'Everything in Stargazer', ready: true },
            { text: 'AI Companion Skye Daily Check-ins', ready: true },
            { text: 'Can Create and Manage Communities', ready: false },
        ]
    }
];

  const handlePromoCode = async () => {
    props.isLoading(true)
    try {
     const { idToken } = (await fetchAuthSession()).tokens ?? {};
      let config = {headers: {"Authorization": idToken.toString()}};
      let body = {
        StripeCustomerID: stripeCustomerID,
        keys: {
          Visionary: process.env.REACT_APP_STRIPEVISIONARY,
          Stargazer: process.env.REACT_APP_STRIPESTARGAZER,
          Dreamer: process.env.REACT_APP_STRIPEDREAMER
        },
        promoCode: promoCode
      }
      const response = await Axios.post(process.env.REACT_APP_APIURL + '/CreateStripeSubscription', body, config);
      
      if (response.data) {
        if (response.data.error) {
          throw new Error(response.data.details)
        } 
        await props.fetchUser();
        window.location.href = "/";
      }
    }
    catch (e) {
      if(isMounted.current)
      {
        setError(e.message)
      }
    }
    finally {
      props.isLoading(false)
    }

  }

  var timeoutID;

  const handleTierSelect = async (tier, priceID, priceValue) => {
    props.isLoading(true)

    try {
      if ((currentSubscription !== null && props.user.hasDefaultPaymentMethod) || tier === "Visionary") {
       const { idToken } = (await fetchAuthSession()).tokens ?? {};
        let config = {headers: {"Authorization": idToken.toString()}};
        let body = {
          StripeCustomerID: stripeCustomerID,
          PriceID: priceID
        }
        const response = await Axios.post(process.env.REACT_APP_APIURL + '/CreateStripeSubscription', body, config);
        if (response.data) {
          console.log("here")
          timeoutID = setTimeout(async () => {await props.fetchUser(); window.location.href = "/"}, 1000);
        }
      }
      else {
        var stripeProps = {
          value: priceValue,
          stripeCustomerID: stripeCustomerID,
          priceID: priceID
        }

        if(isMounted.current)
        {
          setStripeProps(stripeProps)
        }
      }
    }
    catch (e) {
      setError(e.message)
    }
    finally {
      props.isLoading(false)
    }

  };

  const handlePromoCodeChange = (e) => {
    if(isMounted.current){
      setPromoCode(e.target.value);
    }
  };

  useEffect(() => {
    if (isMounted.current){
      if(props.user.subscription && isUpdate === null){
        window.location.href="/";
      }

      if (props.user.StripeCustomerID) {
        
        setStripeCustomerID(props.user.StripeCustomerID)
      }
      else {
        const getStripeCustomerID = async () => {
         const { idToken } = (await fetchAuthSession()).tokens ?? {};
          let config = {headers: {"Authorization": idToken.toString()}};
          const result = await Axios.get(process.env.REACT_APP_APIURL + '/CreateStripeCustomer?v=' + v4(), config);
          if (result.data) {
            
            if(isMounted.current){
              setStripeCustomerID(result.data.customerID)
            }
          }
        }

        getStripeCustomerID()
      }
    }
    return () => clearTimeout(timeoutID)
  }, [props.user])

  useEffect(() => {
    if(isMounted.current){
      if (updatePayment) {
        var priceID;
  
        for (let i = 0; i < tiers.length; i++) {
          if (tiers[i].id === props.user.subscription) {
            priceID = tiers[i].priceID
          }
        }
  
        var stripeProps = {
          updatePayment,
          value: 1,
          stripeCustomerID: props.user.StripeCustomerID,
          priceID: priceID
        }
        setStripeProps(stripeProps)
      }
  
      if (paymentIntentClientSecret) {
        var stripeProps = {
          paymentIntentClientSecret,
          stripeCustomerID: props.user.StripeCustomerID,
        }
  
        setStripeProps(stripeProps)
      }
    }
  }, [location])

  return (
    <div>
      {stripeProps !== null ? <CheckoutForm setSelectedTier={setStripeProps} props={stripeProps} isLoading={props.isLoading} /> :
        <div className="tier-wrapper">
          <h2 style={{ marginTop: "16px" }}>{currentSubscription ? "Change" : "Choose"} Your Path</h2>
          <div className="tier-container">
            {tiers.map((tier) => (
              <div key={tier.id} className="tier">
                <h3>{tier.id}</h3>
                <img style={{ width: "150px", marginTop: "-24px", marginBottom: "-20px" }} src={process.env.REACT_APP_CLOUDFRONT + tier.symbol}></img>
                <div><b>{tier.price}</b></div>
                <ul className="tier-list">
                  {tier.perks.map((perk, index) => (
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "25px" }}>
                        <span className="fa fa-star" style={{ color: "#FFBB8C" }} />
                      </div>
                      <li className={`tier-li ${perk.ready ? "" : "tier-unready"}`} key={index}>{perk.text}{perk.ready ? "" : "*"}</li>
                    </div>
                  ))}
                </ul>
                <div style={{fontSize:"x-small", marginTop:"-10px", paddingBottom:"10px"}}>
                *Coming soon 
                </div>
                <button className="tier-button" disabled={currentSubscription === tier.id} onClick={() => handleTierSelect(tier.id, tier.priceID, tier.priceValue)}>
                  {currentSubscription === tier.id ?
                    <b>Current Path</b>
                    :
                    <b>Select {tier.id}</b>
                  }
                </button>
              </div>
            ))}
          </div>

          <div className="promo-code">
            <input
              type="text"
              className="promo-code-input"
              placeholder="Use a PROMO CODE"
              value={promoCode}
              onChange={handlePromoCodeChange}
            />
            <button type="submit" className="tier-button promo-code-button" onClick={() => handlePromoCode()} >
              <span className="fa fa-arrow-right"></span>
            </button>
          </div>
          {error && <div className="field-error u-center"><b>{error}</b></div>}
        </div>} </div>
  );
};


function mapStateToProps(state) {
  return { user: state.user };
}

export default connect(mapStateToProps, actions)(SubscriptionPage);
