import React, { useEffect, useState, useRef }  from 'react';
import UserGreeting from './UserContent/UserGreeting';
import DailyToDo from './UserContent/DailyToDo';
import UpcomingMilestones from './UserContent/UpcomingMilestones';
import JourneysList from './UserContent/Journeys/JourneysList';
import { connect } from 'react-redux';
import * as actions from '../actions';
import NewPost from './UserContent/NewPost';
import Feed from './UserContent/Feed';
import { Link } from 'react-router-dom';
import '../Styles/Home.css';
import TouchDeviceHeader from './TouchDeviceHeader';
import NewPostModal from './UserContent/NewPostModal';



function Home(props)
{
    const isMounted = useRef();

    useEffect(() => {
        isMounted.current = true;
        return () => {
        isMounted.current = false;
        };
    }), [];

    const [homeMenuHeight, setHomeMenuheight] = useState("1000px")
    const [journeyContentColumnTopMargin, setJourneyContentColumnTopMargin] = useState("32px")
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        const screenHeight = window.innerHeight;
        const screenWidth = window.innerWidth;
        if(isMounted.current){
            setHomeMenuheight(screenHeight - 64 + (screenWidth < 1024 ? 16 : -86))
            setJourneyContentColumnTopMargin(screenWidth < 1024 ? "96px" : "32px")
            setIsMobile(screenWidth < 768)
        }
    }, [window.innerHeight, window.innerWidth])

    if(props.user === null || !props.user){return null}

    return <div className='row u-flex' style={{marginTop: isMobile ? "-16px": 0}}>
        <NewPostModal></NewPostModal>
        <TouchDeviceHeader setShow={props.setShow} showEditButton={true}></TouchDeviceHeader>
        <div className="col-3 pc-column" style={{marginTop: "-32px", height: isMobile ? "unset" : `${homeMenuHeight + 32}px`, paddingBottom: isMobile ? 0 : "16px"}}>
            <UserGreeting/>
            <DailyToDo></DailyToDo>
            <UpcomingMilestones></UpcomingMilestones>
        </div>
    <div className='col-9 pc-column' style={{marginLeft: isMobile ? "16px" : 0}}>
        <div className="row" style={{fontSize:"large", marginTop: isMobile ? "16px" : journeyContentColumnTopMargin}}>
            <b>My Active Journeys</b>
        </div>
        <div>
            <JourneysList ownerID={props.user.id} ownerType={"user"} status={"active"} showNewJourney={true}></JourneysList>
        </div>
    <div style={{display: isMobile ? "none" : "block", width:"95%"}}>
        <NewPost></NewPost>
    </div>
    <div style={{width:"95%"}}>
        <div style={{fontSize:"large", marginTop: isMobile ? "16px" : "32px", display:"flex", justifyContent:"space-between", width:"100%" }}>
            <b>Latest Updates</b>
            <Link to="/feed" style={{fontSize:"medium", textDecoration:"underline"}}><b>view all</b></Link>
        </div>
        <Feed isMobile={isMobile} preview={true}></Feed>
    </div>
    </div>
</div>
    
}    

function mapStateToProps(state)
{
  return {user: state.user};
}

export default connect(mapStateToProps, actions)(Home);
