import { combineReducers } from 'redux';
import authReducer from './authReducer';
import prototypeReducer from './protoAccessReducer';
import isLoadingReducer from './isLoadingReducer';
import getFeedReducer from './getFeedReducer';

//Assigns properties to the redux state object based on reducer return values
export default combineReducers(
  {
    user: authReducer,
    prototypeAccess: prototypeReducer,
    isLoading: isLoadingReducer,
    feed: getFeedReducer
  }
);
