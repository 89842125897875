import React, { useEffect, useState, useRef }  from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import 'rc-tooltip/assets/bootstrap.css';
import Tooltip from 'rc-tooltip';

function EditEdge(props)
{    
    const [hasPost, setHasPost] = useState(false);
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const modalRef = useRef(null);

    useEffect(() =>{
        if (props.edge){
            if (props.edge.data && props.edge.data.posts.length !== 0){
                setHasPost(true);
            } else{
                setHasPost(false);
            }
            if (window.innerWidth - (parseInt(props.edgePosition.x)) < modalRef.current.clientWidth){
                setX(parseInt(props.edgePosition.x)-modalRef.current.clientWidth);
            } else{
                setX(parseInt(props.edgePosition.x));
            }
            setY(parseInt(props.edgePosition.y)-(modalRef.current.clientHeight/2));            
        }
    },[props.edge, props.edgePosition])

    return (
        <div className="modal modal-animated--zoom-in" id="editEdge" style={{backgroundColor:"rgb(0,0,0,0.5)", zIndex:2000}} >
            <div ref={modalRef} className="modal-content" role="document" style={{position: "absolute", left:x+"px", top:y+"px", scale:"0.75"}}>
             <a href="#!" aria-label="Close">
              <span className="icon u-position-relative u-pull-right">
                  <i className="fa-wrapper fa fa-times"/>
              </span>
              </a>
            <div className="row row--no-wrap" style={{marginLeft:"0.5rem", marginRight:"0.5rem", padding:"0", alignItems:"center", textAlign:"center"}}>
                <div style={{float:"left"}}>
                    <Tooltip placement="bottom" animation="zoom" trigger={['click','hover']} id="addPostButton" overlayStyle={{ zIndex: 100000000, maxWidth:"9rem"}} overlay={<span>Add Post auto saves your Journey!</span>}>
                        <button className="btn-info btn--circle" onClick={() => props.addPost()}><i className="fas fa-plus fa-lg"></i></button>
                    </Tooltip>
                </div>
                <div style={{position:"relative", top:"0.2rem", float:"left", marginLeft:"0.5rem"}}>
                    <Tooltip placement="bottom" animation="zoom" trigger={['click','hover']} id="editEdgeButton" overlayStyle={{ zIndex: 100000000}} overlay={<span>Edit</span>}>
                        <button className="btn-transparent btn-animated outline" onClick={()=>props.editEdge()}><i className="fas fa-edit fa-lg"></i></button>
                    </Tooltip>
                </div>
                <div style={{position:"relative", top:"0.2rem", float:"left", marginLeft:"0.25rem"}}>
                    {hasPost? 
                        <Tooltip placement="right" animation="zoom" trigger={['click']} id="deleteEdge" 
                                overlayStyle={{zIndex: 100000000}} overlay={<span>Cannot delete edge with posts</span>}>
                                <span>
                                <button className="btn-transparent btn-animated" disabled={true} onClick={()=>props.deleteEdge()}><i className="fas fa-trash-alt fa-lg"></i></button>
                                </span>
                        </Tooltip>
                        :<Tooltip placement="bottom" animation="zoom" trigger={['click','hover']} id="deleteEdgeButton" overlayStyle={{ zIndex: 100000000}} overlay={<span>Delete</span>}>
                            <button className="btn-transparent btn-animated" onClick={()=>props.deleteEdge()}><i className="fas fa-trash-alt fa-lg"></i></button>
                        </Tooltip>
                    }
                </div>
            </div>
      </div>
      </div>
                        
    )
}

function mapStateToProps(state)
{
  return {user: state.user};
}

export default connect(mapStateToProps, actions)(React.memo(EditEdge));
