import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from "react-player";
import AudioRecorder from './AudioRecorder';


const SkyeVideoChat = (props) => {

    const isMounted = useRef()

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    const [listening, setListening] = useState(false);
    const [showVideo, setShowVideo] = useState(null);
    const [skyeVideoHeight, setSkyeVideoHeight] = useState(0);
    const [skyeVideoWidth, setSkyeVideoWidth] = useState(0);
    const [transcriptionError, setTranscriptionError] = useState(false)
 
    useEffect(() => {
        const screenHeight = window.innerHeight;
        const screenWidth = window.innerWidth;
        if (isMounted.current) {
            setSkyeVideoHeight(screenWidth > 1023 ? screenHeight - 355 : screenHeight - 190);
            setSkyeVideoWidth(screenWidth > 767 ? 500 : screenWidth * 0.9);
        }
    }, [window.innerHeight, window.innerWidth])

    const resetVideoPlayer = async () => {
        if (!props.endChat && isMounted.current) {

            setShowVideo(false)

            var nextVideo = props.videoQueue.shift()

            while(nextVideo === undefined){
                props.setVideoSourceUrl(null);
                nextVideo = props.videoQueue.shift()
                await new Promise(resolve => setTimeout(resolve, 200))
            }

            if(nextVideo !== "BREAK"){
                props.setVideoSourceUrl(process.env.REACT_APP_CLOUDFRONT + "/" + nextVideo)
            }
            else
            {
                props.setVideoSourceUrl(null)
                props.setTextLoading(false)
                setListening(true)
            }
        }
    }

    useEffect(() => {
        props.videoQueue.push("BREAK");
        resetVideoPlayer();
    }, [])


    return (
        <div className='u-center'>
            <div style={{ height: skyeVideoHeight, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                <div style={{ display: props.textLoading ? "flex" : "none", marginBottom: "16px", height: "50px" }}>
                    <div className='u-flex'>
                        <div className="text-loading skye-alert skye-thinking">
                            Skye is Thinking
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                        </div>
                    </div>
                </div>
                <div style={{ display: !props.textLoading && !listening && !props.endChat && !transcriptionError && props.videoQueue.length == 0 ? "flex" : "none", marginBottom: "16px", height: "50px" }}>
                    <div className='u-flex'>
                        <div className="skye-alert skye-error">
                            Error: If you are seeing this error, something really went wrong.
                        </div>
                    </div>
                </div>
                <div style={{ display: transcriptionError && listening ? "flex" : "none", marginBottom: "16px", height: "50px" }}>
                    <div className='u-flex'>
                        <div className="skye-alert skye-error">
                            Error: Skye can't hear you. Please use the text chat or contact support@skyreachers.com.
                        </div>
                    </div>
                </div>
                <div style={{ display: props.endChat ? "flex" : "none", marginBottom: "16px", height: "50px" }}>
                    <div className='u-flex'>
                        <div className="skye-alert skye-error">
                            Call Ended
                        </div>
                    </div>
                </div>
                {listening ?
                    <AudioRecorder setListening={setListening}
                                   sendMessage={props.sendMessage}  
                                   setTranscriptionError={setTranscriptionError}
                                   isLoading={props.isLoading}/> : ""
                }
                <div style={{ width: `${skyeVideoWidth}px`, height: `${skyeVideoWidth}px`, borderRadius: "20px", overflow: "clip" }}>
                    <div style={{ position: "absolute", zIndex: "-1" }}>
                        <img src={props.skyeProfile} style={{ height: `${skyeVideoWidth}px`, width: `${skyeVideoWidth}px`, borderRadius: "20px" }}></img>
                    </div>
                    {!props.endChat && props.preloaded ? <div>
                        <div style={{ width: `${skyeVideoWidth}px`, display: showVideo ? "flex" : "none", alignItems: "flex-start", position: "absolute", borderRadius: "20px", overflow: "clip" }}>
                            <ReactPlayer
                                config={{
                                    file: {
                                        attributes: { type: "video/mp4", playsInline: true }
                                    },
                                }}
                                className="react-player"
                                width={`${skyeVideoWidth}px`}
                                height="unset"
                                url={props.videoSourceUrl}
                                key={props.videoSourceUrl}
                                onReady={() => { if (props.videoSourceUrl !== null) setShowVideo(true) }}
                                playing={true}
                                loop={false}
                                onError={(e) => {
                                    console.log(e.target.error)
                                    const src = props.videoSourceUrl;
                                    props.setVideoSourceUrl(null)
                                    setTimeout(() => props.setVideoSourceUrl(src), 1000)
                                }}
                                onEnded={() => resetVideoPlayer()}
                            />
                        </div>
                        <div style={{ width: `${skyeVideoWidth}px`, display: "flex", alignItems: "flex-start" }}>
                            <ReactPlayer
                                config={{
                                    file: {
                                        attributes: { type: "video/mp4", playsInline: true }
                                    },
                                }}
                                className="react-player"
                                width={`${skyeVideoWidth}px`}
                                height="unset"
                                url={props.preloadedVideoRef.src}
                                playing={true}
                                loop={true}
                                onEnded={() => resetVideoPlayer()}
                            />
                        </div>
                        <div className="blur-block" style={{ width: `${skyeVideoWidth}px` }}></div></div> : ""}
                </div>
            </div>
        </div>
    )
}

export default SkyeVideoChat;